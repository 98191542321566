import { AppConsts } from '@shared/AppConsts';
import { XmlHttpRequestHelper } from '@shared/helpers/XmlHttpRequestHelper';
var AppAuthService = /** @class */ (function () {
    function AppAuthService() {
    }
    AppAuthService.prototype.logout = function (reload, returnUrl) {
        var _a;
        var customHeaders = (_a = {},
            _a[abp.multiTenancy.tenantIdCookieName] = abp.multiTenancy.getTenantIdCookie(),
            _a['Authorization'] = 'Bearer ' + abp.auth.getToken(),
            _a);
        XmlHttpRequestHelper.ajax('GET', AppConsts.remoteServiceBaseUrl + '/api/TokenAuth/LogOut', customHeaders, null, function () {
            abp.auth.clearToken();
            abp.auth.clearRefreshToken();
            abp.utils.setCookieValue(AppConsts.authorization.encrptedAuthTokenName, undefined, undefined, abp.appPath);
            if (reload !== false) {
                if (returnUrl) {
                    location.href = returnUrl;
                }
                else {
                    location.href = '';
                }
            }
        });
    };
    return AppAuthService;
}());
export { AppAuthService };
