var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModalDirective } from 'ngx-bootstrap/modal';
var AppBsModalDirective = /** @class */ (function (_super) {
    __extends(AppBsModalDirective, _super);
    function AppBsModalDirective() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppBsModalDirective.prototype.showElement = function () {
        _super.prototype.showElement.call(this);
        this.setZIndexes();
    };
    AppBsModalDirective.prototype.setZIndexes = function () {
        var newZIndex = this.setAndGetModalZIndex();
        this.setBackDropZIndex(newZIndex - 1);
    };
    AppBsModalDirective.prototype.setAndGetModalZIndex = function () {
        var modalBaseZIndex = 1050;
        var modalsLength = document.querySelectorAll('.modal.fade.show').length;
        var newZIndex = modalBaseZIndex + modalsLength * 2;
        this._element.nativeElement.style.zIndex = newZIndex.toString();
        return newZIndex;
    };
    AppBsModalDirective.prototype.setBackDropZIndex = function (zindex) {
        var modalBackdrops = document.querySelectorAll('.modal-backdrop.fade.show');
        modalBackdrops[modalBackdrops.length - 1].style.zIndex = zindex.toString();
    };
    return AppBsModalDirective;
}(ModalDirective));
export { AppBsModalDirective };
