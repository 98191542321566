var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector } from '@angular/core';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
var ProfitSharePieChart = /** @class */ (function (_super) {
    __extends(ProfitSharePieChart, _super);
    function ProfitSharePieChart(_dashboardService) {
        var _this = _super.call(this) || this;
        _this._dashboardService = _dashboardService;
        _this.chartData = [];
        _this.scheme = {
            name: 'custom',
            selectable: true,
            group: 'Ordinal',
            domain: [
                '#00c5dc', '#ffb822', '#716aca'
            ]
        };
        return _this;
    }
    ProfitSharePieChart.prototype.init = function (data) {
        var formattedData = [];
        for (var i = 0; i < data.length; i++) {
            formattedData.push({
                'name': this.getChartItemName(i),
                'value': data[i]
            });
        }
        this.chartData = formattedData;
    };
    ProfitSharePieChart.prototype.getChartItemName = function (index) {
        if (index === 0) {
            return 'Product Sales';
        }
        if (index === 1) {
            return 'Online Courses';
        }
        if (index === 2) {
            return 'Custom Development';
        }
        return 'Other';
    };
    ProfitSharePieChart.prototype.reload = function () {
        var _this = this;
        this.showLoading();
        this._dashboardService.getProfitShare().subscribe(function (data) {
            _this.init(data.profitShares);
            _this.hideLoading();
        });
    };
    return ProfitSharePieChart;
}(DashboardChartBase));
var WidgetProfitShareComponent = /** @class */ (function (_super) {
    __extends(WidgetProfitShareComponent, _super);
    function WidgetProfitShareComponent(injector, _dashboardService) {
        var _this = _super.call(this, injector) || this;
        _this._dashboardService = _dashboardService;
        _this.profitSharePieChart = new ProfitSharePieChart(_this._dashboardService);
        return _this;
    }
    WidgetProfitShareComponent.prototype.ngOnInit = function () {
        this.profitSharePieChart.reload();
    };
    return WidgetProfitShareComponent;
}(WidgetComponentBase));
export { WidgetProfitShareComponent };
