import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation } from '@angular/core';
import { AppConfigurationLookupServiceProxy, NameValueOfString } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'clientCodesMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './clientCode-multiselect.component.html'
})

export class ClientCodesMultiSelectComponent extends AppComponentBase implements OnInit {

    @Input()
    set selectedItems(items: NameValueOfString[]) {
        this._selectedItems = items || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfString[] {
        return this._selectedItems;
    }
    @Input() name = 'ClientFilter';
    @Output() selectedItemsChange: EventEmitter<NameValueOfString[]> = new EventEmitter<NameValueOfString[]>();

    placeholder = this.l('Client');
    options: NameValueOfString[] = new Array<NameValueOfString>();

    private _selectedItems: NameValueOfString[] = [];

    constructor(injector: Injector,private _appConfigurationLookupServiceProxy: AppConfigurationLookupServiceProxy) {
       super(injector)
    }

    ngOnInit() {
        if (this.selectedItems.length) {
            this.getClientCodes();
        }
    }

    getClientCodes() {
        this._appConfigurationLookupServiceProxy.getClientsWithCode('')
            .subscribe(clientCodes => {
                this.options = clientCodes;
            });
    }

    onPanelShow() {
        if (!this.options.length) {
            this.getClientCodes();
        }
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}