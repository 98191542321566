var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, EventEmitter, Injector } from '@angular/core';
import { NameValueOfString, RegionValue } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
var RegionValuesMultiSelectComponent = /** @class */ (function (_super) {
    __extends(RegionValuesMultiSelectComponent, _super);
    function RegionValuesMultiSelectComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this._exclude = [];
        _this.name = 'RegionValueFilter';
        _this.selectedItemsChange = new EventEmitter();
        _this.regionValueValues = RegionValue;
        _this.options = new Array();
        _this._selectedItems = [];
        _this.placeholder = _this.l('Region');
        return _this;
    }
    Object.defineProperty(RegionValuesMultiSelectComponent.prototype, "selectedItems", {
        get: function () {
            return this._selectedItems;
        },
        set: function (items) {
            this._selectedItems = items || [];
            this.selectedItemsChange.emit(this._selectedItems);
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(RegionValuesMultiSelectComponent.prototype, "exclude", {
        set: function (exclude) {
            this._exclude = exclude;
            this.setRegionValues();
        },
        enumerable: true,
        configurable: true
    });
    RegionValuesMultiSelectComponent.prototype.ngOnInit = function () {
        this.setRegionValues();
    };
    RegionValuesMultiSelectComponent.prototype.regionValueKeys = function () {
        var keys = Object.keys(this.regionValueValues);
        return keys.slice(keys.length / 2);
    };
    RegionValuesMultiSelectComponent.prototype.setRegionValues = function () {
        var _this = this;
        this.options = [];
        this.regionValueKeys().forEach(function (e) {
            if (!_this._exclude.includes(e)) {
                _this.options.push(new NameValueOfString({ name: _this.l(e), value: _this.regionValueValues[e] }));
            }
        });
    };
    RegionValuesMultiSelectComponent.prototype.onPanelHide = function () {
        document.getElementById(this.name).focus();
    };
    return RegionValuesMultiSelectComponent;
}(AppComponentBase));
export { RegionValuesMultiSelectComponent };
