import { AbstractControl, Validator } from '@angular/forms';
//Got from: https://scotch.io/tutorials/how-to-implement-a-custom-validator-directive-confirm-password-in-angular-2
var EqualValidator = /** @class */ (function () {
    function EqualValidator(validateEqual, reverse) {
        this.validateEqual = validateEqual;
        this.reverse = reverse;
    }
    Object.defineProperty(EqualValidator.prototype, "isReverse", {
        get: function () {
            if (!this.reverse) {
                return false;
            }
            return this.reverse === 'true';
        },
        enumerable: true,
        configurable: true
    });
    EqualValidator.prototype.validate = function (control) {
        var pairControl = control.root.get(this.validateEqual);
        if (!pairControl) {
            return null;
        }
        var value = control.value;
        var pairValue = pairControl.value;
        if (!value && !pairValue) {
            this.deleteErrors(pairControl);
            return null;
        }
        if (this.isReverse) {
            if (value === pairValue) {
                this.deleteErrors(pairControl);
            }
            else {
                pairControl.setErrors({
                    validateEqual: true
                });
            }
            return null;
        }
        else {
            if (value !== pairValue) {
                return {
                    validateEqual: true
                };
            }
        }
    };
    EqualValidator.prototype.deleteErrors = function (control) {
        if (control.errors) {
            delete control.errors['validateEqual'];
        }
        if (control.errors && !Object.keys(control.errors).length) {
            control.setErrors(null);
        }
    };
    return EqualValidator;
}());
export { EqualValidator };
