var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
var FilterDateRangePickerComponent = /** @class */ (function (_super) {
    __extends(FilterDateRangePickerComponent, _super);
    function FilterDateRangePickerComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.selectedDateRange = [moment().add(-7, 'days').startOf('day'), moment().endOf('day')];
        return _this;
    }
    FilterDateRangePickerComponent.prototype.onChange = function () {
        abp.event.trigger('app.dashboardFilters.dateRangePicker.onDateChange', this.selectedDateRange);
    };
    return FilterDateRangePickerComponent;
}(AppComponentBase));
export { FilterDateRangePickerComponent };
