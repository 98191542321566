<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{ordersSummaryBarChart?.title}}
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <a href="javascript:;" class="btn btn-primary" (click)="ordersSummaryBarChart.reload()">
                <i class="fa fa-redo"></i> Refresh
            </a>
        </div>
    </div>
    <div class="kt-portlet__body chart-main-container" [busyIf]="ordersSummaryBarChart.loading">
        <div class="row">
            <div class="chart-container">
                <ngx-charts-bar-horizontal-2d  [results]="ordersSummaryBarChart.data"
                    [yAxis]="true" [xAxis]="true" [legend]="true" legendPosition="bottom" [showDataLabel]="true"
                    [customColors]="ordersSummaryBarChart?.customColors" (select)="onSelect($event)">
                </ngx-charts-bar-horizontal-2d>
            </div>
        </div>
    </div>
</div>