var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
var FooterComponent = /** @class */ (function (_super) {
    __extends(FooterComponent, _super);
    function FooterComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.useBottomDiv = true;
        return _this;
    }
    FooterComponent.prototype.ngOnInit = function () {
        this.releaseDate = this.appSession.application.releaseDate.format('YYYYMMDD');
    };
    return FooterComponent;
}(AppComponentBase));
export { FooterComponent };
