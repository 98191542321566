<!-- BEGIN: Topbar -->
<div class="kt-header__topbar">
    <div class="kt-header__topbar-item dropdown d-none d-lg-block subscription-warning"
        *ngIf="subscriptionStatusBarVisible()">
        <a href="javascript:;" *ngIf="appSession.tenant.isInTrialPeriod && !subscriptionIsExpiringSoon()"
            class="kt-nav__link m-dropdown__toggle">
            <span class="kt-nav__link-text glowing">
                <i class="fa fa-exclamation-triangle"></i>
                <span class="kt-menu__link-text ml-1" [innerHTML]="getTrialSubscriptionNotification()"></span>
            </span>
        </a>
        <a *ngIf="subscriptionIsExpiringSoon()" routerLink="/app/admin/subscription-management" class="kt-nav__link">
            <span *ngIf="subscriptionIsExpiringSoon() && appSession.tenant.isInTrialPeriod"
                class="kt-nav__link-text glowing">
                <i class="fa fa-exclamation-triangle"></i>
                <span class="kt-menu__link-text ml-1">{{getExpireNotification("TrialExpireNotification")}}</span>
            </span>
            <span *ngIf="subscriptionIsExpiringSoon() && !appSession.tenant.isInTrialPeriod"
                class="kt-nav__link-text glowing">
                <i class="fa fa-exclamation-triangle"></i>
                <span class="kt-menu__link-text ml-1">{{getExpireNotification("SubscriptionExpireNotification")}}</span>
            </span>
        </a>
    </div>
    <div *ngIf="isQuickThemeSelectEnabled" id="kt_theme_selection_panel_toggle" class="kt-header__topbar-item dropdown">
        <div class="kt-header__topbar-wrapper">
            <span class="kt-header__topbar-icon kt-header__topbar-icon--brand">
                <i class="flaticon-interface-7"></i>
            </span>
        </div>
    </div>
    <div dropdown class="kt-header__topbar-item dropdown dropdown-language" *ngIf="languages.length > 1">
        <div dropdownToggle class="kt-header__topbar-wrapper dropdown-toggle" data-toggle="dropdown"
            data-offset="30px,0px" aria-expanded="true" aria-haspopup="true">
            <span class="kt-header__topbar-icon kt-header__topbar-icon--brand">
                <i [class]="currentLanguage.icon"></i>
            </span>
        </div>
        <div *dropdownMenu
            class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg"
            [attr.aria-label]="l('Languages')">
            <ul class="kt-nav kt-margin-t-10 kt-margin-b-10">
                <li class="kt-nav__item" *ngFor="let language of languages">
                    <a class="kt-nav__link" (click)="changeLanguage(language.name)">
                        <span class="kt-nav__link-icon">
                            <i [class]="language.icon"></i>
                        </span>
                        <span class="kt-nav__link-text">
                            {{language.displayName}}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div headerNotifications class="kt-header__topbar-item dropdown" dropdown id="header_notification_bar"></div>
    <div class="kt-header__topbar-item kt-header__topbar-item--user ng-star-inserted  dropdown" dropdown
        placement="bottom-right">
        <div class="kt-header__topbar-wrapper dropdown-toggle" data-toggle="dropdown"
            aria-expanded="false" aria-haspopup="true" dropdownToggle>
            <div class="kt-header__topbar-user">
                <i *ngIf="isImpersonatedLogin" class="fa fa-reply kt-font-danger mr-2"></i>
                <span class="kt-header__topbar-username kt-hidden-mobile">
                    <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{tenancyName}}\</span>{{userName}}
                </span>
                <img *ngIf="profilePicture" class="header-profile-picture m--img-rounded m--marginless m--img-centered"
                    alt="Pic" [src]="profilePicture">
            </div>
        </div>
        <div *dropdownMenu
            class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
            x-placement="bottom-right"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(1253px, 64px, 0px);">
            <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
                [ngStyle]="{ 'background': 'url(' + appRootUrl() + 'assets/metronic/themes/' + currentTheme.baseSettings.theme + '/images/bg/bg-1.jpg)', 'background-size': 'cover'}">
                <div class="kt-user-card__avatar">
                    <img class="" alt="Pic" [src]="profilePicture">
                </div>
                <div class="kt-user-card__name">
                    <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{tenancyName}}\</span>{{userName}}
                </div>
            </div>
            <div class="kt-notification user-profile-menu">
                <a *ngIf="isImpersonatedLogin" href="javascript:;" class="kt-notification__item"
                    (click)="backToMyAccount()">
                    <div class="kt-notification__item-icon">
                        <i class="fa fa-reply kt-font-danger"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"BackToMyAccount" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <a href="javascript:;" class="kt-notification__item linked-accounts" (click)="showLinkedAccounts()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-user-settings"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"ManageLinkedAccounts" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <div class="kt-notification__custom py-0 linked-accounts">
                    <ul class="kt-nav linked-accounts" *ngIf="recentlyLinkedUsers && recentlyLinkedUsers.length"
                        [attr.aria-label]="l('LinkedAccounts')">
                        <li *ngFor="let linkedUser of recentlyLinkedUsers" class="kt-nav__item py-2">
                            <a href="javascript:;" (click)="switchToLinkedUser(linkedUser)"
                                class="recently-linked-user">
                                <span class="kt-nav__link-bullet">
                                    <span></span>
                                </span>
                                <span class="kt-nav__link-text">
                                    {{getShownUserName(linkedUser)}}
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
                <a href="javascript:;" class="kt-notification__item" (click)="changePassword()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-more-v6"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"ChangePassword" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <a href="javascript:;" class="kt-notification__item" (click)="changeDepot()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-buildings"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"ChangeDepot" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <a href="javascript:;" class="kt-notification__item" (click)="showLoginAttempts()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-list"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"LoginAttempts" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <a href="javascript:;" class="kt-notification__item" (click)="changeProfilePicture()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-profile-1"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"ChangeProfilePicture" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <a href="javascript:;" class="kt-notification__item" (click)="changeMySettings()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-cogwheel"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"MySettings" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <a href="javascript:;" routerLink="admin/ui-customization"
                    class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-medical"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"VisualSettings" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <a href="javascript:;" class="kt-notification__item" (click)="downloadCollectedData()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-download"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"DownloadCollectedData" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <div class="kt-notification__custom">
                    <a (click)="logout()" class="btn btn-label-brand btn-sm btn-bold">{{"Logout" | localize}}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="(isHost || ('App.ChatFeature' | checkFeature)) && !chatConnected"
        class="kt-header__topbar-item dropdown">
        <div class="kt-header__topbar-wrapper">
            <span class="kt-header__topbar-icon kt-header__topbar-icon--brand">
                <img *ngIf="!chatConnected" src="./assets/common/images/loading.gif" style="width: 23px;"
                    tooltip="{{'ChatIsConnecting' | localize}}" placement="left" />
            </span>
        </div>
    </div>
    <div id="kt_quick_sidebar_toggle" [hidden]="!(isHost || ('App.ChatFeature' | checkFeature)) || !chatConnected"
        class="kt-header__topbar-item dropdown">
        <div class="kt-header__topbar-wrapper">
            <span class="kt-header__topbar-icon kt-header__topbar-icon--brand">
                <div *ngIf="unreadChatMessageCount">
                    <i class="flaticon-chat-2 unread-notification"></i>
                    <span class="kt-badge kt-badge--primary unread-notification-count">
                        {{unreadChatMessageCount}}
                    </span>
                </div>
                <div *ngIf="!unreadChatMessageCount">
                    <i class="flaticon-chat-2"></i>
                </div>
            </span>
        </div>
    </div> -->
</div>
<!-- END: Topbar -->
