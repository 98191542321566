import {
    ConsignmentStatusDashboardServiceProxy,
    ConsignmentsSummaryDataAllCarriers
} from '@shared/service-proxies/service-proxies';

import { DashboardTableField } from './dashboard-table-field';
import { DashboardChartBase } from '../widgets/dashboard-chart-base';

export class AllCarriesSummaryTable extends DashboardChartBase {
    datas: ConsignmentsSummaryDataAllCarriers[] = [];
    fields: DashboardTableField[];
    title: string;
    tileTitle = 'All Carriers Summary';

    constructor(private dataService: ConsignmentStatusDashboardServiceProxy) {
        super();
        this.title = this.tileTitle;
    }

    setChartData(items: ConsignmentsSummaryDataAllCarriers[]): void {
        this.datas = items;
        if (items.length > 0) {
            this.fields = Object.keys(items[0])
                .filter(k => k !== 'courierCode')
                .map(k => {
                    return {
                        key: k,
                        localizeKey: k.charAt(0).toUpperCase() + k.slice(1),
                        type: Array.isArray(items[0][k]) ? 'Array' : typeof items[0][k]
                    };
                });
        }
    }

    reload() {
        this.showLoading();
        this.dataService.getConsignmentsForDashboardAllCarriers().subscribe(result => {
            this.title = this.tileTitle + ' (' + (result.mainTitle || 'BAU') + ')';
            this.setChartData(result.consignmentsSummaryDataAllCarriers || []);
            this.hideLoading();
        });
    }
}
