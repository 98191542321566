var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector } from '@angular/core';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
var DashboardTopStats = /** @class */ (function (_super) {
    __extends(DashboardTopStats, _super);
    function DashboardTopStats() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.totalProfit = 0;
        _this.totalProfitCounter = 0;
        _this.newFeedbacks = 0;
        _this.newFeedbacksCounter = 0;
        _this.newOrders = 0;
        _this.newOrdersCounter = 0;
        _this.newUsers = 0;
        _this.newUsersCounter = 0;
        _this.totalProfitChange = 76;
        _this.totalProfitChangeCounter = 0;
        _this.newFeedbacksChange = 85;
        _this.newFeedbacksChangeCounter = 0;
        _this.newOrdersChange = 45;
        _this.newOrdersChangeCounter = 0;
        _this.newUsersChange = 57;
        _this.newUsersChangeCounter = 0;
        return _this;
    }
    DashboardTopStats.prototype.init = function (totalProfit, newFeedbacks, newOrders, newUsers) {
        this.totalProfit = totalProfit;
        this.newFeedbacks = newFeedbacks;
        this.newOrders = newOrders;
        this.newUsers = newUsers;
        this.hideLoading();
    };
    return DashboardTopStats;
}(DashboardChartBase));
var WidgetTopStatsComponent = /** @class */ (function (_super) {
    __extends(WidgetTopStatsComponent, _super);
    function WidgetTopStatsComponent(injector, _tenantDashboardServiceProxy) {
        var _this = _super.call(this, injector) || this;
        _this._tenantDashboardServiceProxy = _tenantDashboardServiceProxy;
        _this.dashboardTopStats = new DashboardTopStats();
        return _this;
    }
    WidgetTopStatsComponent.prototype.ngOnInit = function () {
        this.loadTopStatsData();
    };
    WidgetTopStatsComponent.prototype.loadTopStatsData = function () {
        var _this = this;
        this._tenantDashboardServiceProxy.getTopStats().subscribe(function (data) {
            _this.dashboardTopStats.init(data.totalProfit, data.newFeedbacks, data.newOrders, data.newUsers);
        });
    };
    return WidgetTopStatsComponent;
}(WidgetComponentBase));
export { WidgetTopStatsComponent };
