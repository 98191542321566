import { Component } from '@angular/core';

import { ArrayWidget } from 'ngx-schema-form';

@Component({
  selector: 'sf-array-widget',
  template: `<div class="widget form-group">
	<fieldset><legend>{{ schema.title }} <span *ngIf="schema.isRequired" class="m--font-danger">*</span></legend>
	<span *ngIf="schema.description" class="formHelp">{{schema.description}}</span>
	<div *ngFor="let itemProperty of formProperty.properties">
		<sf-form-element [formProperty]="itemProperty"></sf-form-element>
	</div>
	</fieldset>
</div>`
})
export class MetronicsArrayComponent extends ArrayWidget {

}
