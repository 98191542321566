import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable()
export class LastRouteService {

    constructor(private _location: Location) {
    }

    public navBack() {
        if (this.canNavBack()) {
            this._location.back();
        }
    }

    public canNavBack() {
        return true;
    }
}
