import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IDashboardFilters } from '@app/shared/common/customizable-dashboard/models/IDashboardFilters';

@Injectable()
export class DashboardConsignmentService {
    constructor(private router: Router) {
    }

    public navigate(filter: IDashboardFilters) {
        let queryParams = {
            dashboardViewFilter: true,
            xFilter: filter.xFilter,
            yFilter: filter.yFilter,
            zFilter: filter.zFilter
        };

        this.router.navigate(['app/main/shipments/consignments'], {
            queryParams: queryParams
        });
    }

    public navigateConsignments(filter: IDashboardFilters) {
        let queryParams = {
            dashboardViewFilter: true,
            xFilter: filter.xFilter,
            yFilter: filter.yFilter,
            zFilter: filter.zFilter
        };

        this.router.navigate(['app/main/shipments/consignments'], {
            queryParams: queryParams
        });
    }
}
