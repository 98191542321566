var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { Table } from 'primeng/table';
import { HostDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBase } from '../widget-component-base';
var WidgetRecentTenantsComponent = /** @class */ (function (_super) {
    __extends(WidgetRecentTenantsComponent, _super);
    function WidgetRecentTenantsComponent(injector, _hostDashboardServiceProxy) {
        var _this = _super.call(this, injector) || this;
        _this._hostDashboardServiceProxy = _hostDashboardServiceProxy;
        _this.loading = true;
        _this.loadRecentTenantsData();
        return _this;
    }
    WidgetRecentTenantsComponent.prototype.loadRecentTenantsData = function () {
        var _this = this;
        this._hostDashboardServiceProxy.getRecentTenantsData().subscribe(function (data) {
            _this.recentTenantsData = data;
            _this.loading = false;
        });
    };
    WidgetRecentTenantsComponent.prototype.gotoAllRecentTenants = function () {
        window.open(abp.appPath + 'app/admin/tenants?' +
            'creationDateStart=' + encodeURIComponent(this.recentTenantsData.tenantCreationStartDate.format()));
    };
    return WidgetRecentTenantsComponent;
}(WidgetComponentBase));
export { WidgetRecentTenantsComponent };
