var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnDestroy } from '@angular/core';
import { EventEmitter, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import compare from 'just-compare';
import { filter } from 'rxjs/operators';
var DatePickerComponent = /** @class */ (function (_super) {
    __extends(DatePickerComponent, _super);
    function DatePickerComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.dateChange = new EventEmitter();
        _this.selectedTimeRange = '00:00-24:00';
        _this._selectedRangeLabel = null;
        _this.customRangeEnabled = false;
        _this.daterangepickerConfig = NgxBootstrapDatePickerConfigService.getDaterangepickerConfig();
        _this.date = new EventEmitter();
        _this.lastDates = null;
        return _this;
    }
    Object.defineProperty(DatePickerComponent.prototype, "selectedRangeLabel", {
        get: function () {
            return this._selectedRangeLabel;
        },
        set: function (selectedRangeLabel) {
            this._selectedRangeLabel = selectedRangeLabel;
            var selected = this.daterangepickerConfig.ranges.filter(function (range) { return compare(range.label, selectedRangeLabel); });
            if (!!selected.length && selected[0].value) {
                this._selectedDateRange = [selected[0].value[0], selected[0].value[1]];
                if (!!this.bsDateRangepicker) {
                    this.bsDateRangepicker.bsValue = this._selectedDateRange;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "bsDaterangepicker", {
        set: function (directive) {
            this.bsDateRangepicker = directive;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePickerComponent.prototype, "selectedDateRange", {
        get: function () {
            if (!this._selectedDateRange) {
                return null;
            }
            else {
                return NgxBootstrapDatePickerConfigService.getModifiedRangeBasedOnTime([moment(this._selectedDateRange[0]), moment(this._selectedDateRange[1])], this.selectedTimeRange);
            }
        },
        set: function (args) {
            if (!!args && args.length !== 0) {
                this._selectedDateRange = [new Date(args[0].toDate()), new Date(args[1].toDate())];
            }
            else {
                this._selectedDateRange = null;
            }
            if (!!this.bsDateRangepicker) {
                this.bsDateRangepicker.bsValue = this._selectedDateRange;
            }
        },
        enumerable: true,
        configurable: true
    });
    DatePickerComponent.prototype.setSelectedTimeRange = function (range) {
        this.selectedTimeRange = range;
        this.toggleCustomRange(false);
        this.sendBackDates();
    };
    DatePickerComponent.prototype.ngOnDestroy = function () {
        this.subscribe.unsubscribe();
    };
    DatePickerComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.bsDateRangepicker.bsValue = this._selectedDateRange;
        this.subscribe = this.bsDateRangepicker.bsValueChange
            .pipe(filter(function (dates) { return !!(dates && dates[0] instanceof Date && dates[1] instanceof Date
            && !compare(_this.lastDates, dates) && dates[0].toString() !== 'Invalid Date' && dates[1].toString() !== 'Invalid Date'); }))
            .subscribe(function (dates) {
            _this.lastDates = dates;
            var selected = _this.bsDateRangepicker.bsConfig.ranges.filter(function (range) { return compare(range.value, dates); });
            if (!!selected.length) {
                _this._selectedRangeLabel = selected[0].label;
            }
            else {
                _this._selectedRangeLabel = null;
            }
            _this._selectedDateRange = dates;
            _this.sendBackDates();
        });
    };
    DatePickerComponent.prototype.toggleCustomRange = function (value) {
        this.customRangeEnabled = value;
    };
    DatePickerComponent.prototype.sendBackDates = function () {
        var modifiedTimeRange = null;
        if (!!this.selectedTimeRange) {
            modifiedTimeRange = NgxBootstrapDatePickerConfigService.getModifiedRangeBasedOnTime([moment(this._selectedDateRange[0]), moment(this._selectedDateRange[1])], this.selectedTimeRange);
        }
        this.dateChange.emit({
            selectedDateRange: modifiedTimeRange,
            selectedRangeLabel: this._selectedRangeLabel,
            selectedTimeRange: this.selectedTimeRange
        });
    };
    return DatePickerComponent;
}(AppComponentBase));
export { DatePickerComponent };
