var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit, NgZone } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { NotificationServiceProxy } from '@shared/service-proxies/service-proxies';
import { UserNotificationHelper } from './UserNotificationHelper';
import * as _ from 'lodash';
import { UrlHelper } from '@shared/helpers/UrlHelper';
var HeaderNotificationsComponent = /** @class */ (function (_super) {
    __extends(HeaderNotificationsComponent, _super);
    function HeaderNotificationsComponent(injector, _notificationService, _userNotificationHelper, _zone) {
        var _this = _super.call(this, injector) || this;
        _this._notificationService = _notificationService;
        _this._userNotificationHelper = _userNotificationHelper;
        _this._zone = _zone;
        _this.notifications = [];
        _this.unreadNotificationCount = 0;
        return _this;
    }
    HeaderNotificationsComponent.prototype.ngOnInit = function () {
        this.loadNotifications();
        this.registerToEvents();
    };
    HeaderNotificationsComponent.prototype.loadNotifications = function () {
        var _this = this;
        if (UrlHelper.isInstallUrl(location.href)) {
            return;
        }
        this._notificationService.getUserNotifications(undefined, undefined, undefined, 3, 0).subscribe(function (result) {
            _this.unreadNotificationCount = result.unreadCount;
            _this.notifications = [];
            _.forEach(result.items, function (item) {
                _this.notifications.push(_this._userNotificationHelper.format(item));
            });
        });
    };
    HeaderNotificationsComponent.prototype.registerToEvents = function () {
        var self = this;
        function onNotificationReceived(userNotification) {
            self._userNotificationHelper.show(userNotification);
            self.loadNotifications();
        }
        abp.event.on('abp.notifications.received', function (userNotification) {
            self._zone.run(function () {
                onNotificationReceived(userNotification);
            });
        });
        function onNotificationsRefresh() {
            self.loadNotifications();
        }
        abp.event.on('app.notifications.refresh', function () {
            self._zone.run(function () {
                onNotificationsRefresh();
            });
        });
        function onNotificationsRead(userNotificationId) {
            for (var i = 0; i < self.notifications.length; i++) {
                if (self.notifications[i].userNotificationId === userNotificationId) {
                    self.notifications[i].state = 'READ';
                }
            }
            self.unreadNotificationCount -= 1;
        }
        abp.event.on('app.notifications.read', function (userNotificationId) {
            self._zone.run(function () {
                onNotificationsRead(userNotificationId);
            });
        });
    };
    HeaderNotificationsComponent.prototype.setAllNotificationsAsRead = function () {
        this._userNotificationHelper.setAllAsRead();
    };
    HeaderNotificationsComponent.prototype.openNotificationSettingsModal = function () {
        this._userNotificationHelper.openSettingsModal();
    };
    HeaderNotificationsComponent.prototype.setNotificationAsRead = function (userNotification) {
        this._userNotificationHelper.setAsRead(userNotification.userNotificationId);
    };
    HeaderNotificationsComponent.prototype.gotoUrl = function (url) {
        if (url) {
            location.href = url;
        }
    };
    return HeaderNotificationsComponent;
}(AppComponentBase));
export { HeaderNotificationsComponent };
