import { AbpModule } from '@abp/abp.module';
import * as ngCommon from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { CommonModule } from '@shared/common/common.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { SchemaFormModule } from 'ngx-schema-form';


import { MetronicsArrayComponent } from './schemaComponents/array/array.component';
import { MetronicsButtonComponent } from './schemaComponents/button/button.component';
import { MetronicsObjectComponent } from './schemaComponents/object/object.component';
import { MetronicsStringComponent } from './schemaComponents/string/string.component';
import { MetronicsDateComponent } from './schemaComponents/date/date.component';
import { MetronicsIntegerComponent } from './schemaComponents/integer/integer.component';
import { MetronicsSelectComponent } from './schemaComponents/select/select.component';

import { FileUploadModule } from 'primeng/primeng';
import { BigInputComponent } from './bigInput/big-input.component';
import { TimelineComponent } from './timeline/timeline.component';
import { AutosizeModule } from 'ngx-autosize';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { PrintShippingLabelsModalComponent } from './print/print-shipping-labels-modal.component';
import { PrintShippingLabelsComponent } from './print/print-shipping-labels/print-shipping-labels.component';
import { CustomizableDashboardComponent } from './customizable-dashboard/customizable-dashboard.component';
import { WidgetGeneralStatsComponent } from './customizable-dashboard/widgets/widget-general-stats/widget-general-stats.component';
import { DashboardViewConfigurationService } from './customizable-dashboard/dashboard-view-configuration.service';
import { GridsterModule } from 'angular-gridster2';
import { WidgetDailySalesComponent } from './customizable-dashboard/widgets/widget-daily-sales/widget-daily-sales.component';
import { WidgetEditionStatisticsComponent } from './customizable-dashboard/widgets/widget-edition-statistics/widget-edition-statistics.component';
import { WidgetHostTopStatsComponent } from './customizable-dashboard/widgets/widget-host-top-stats/widget-host-top-stats.component';
import { WidgetIncomeStatisticsComponent } from './customizable-dashboard/widgets/widget-income-statistics/widget-income-statistics.component';
import { WidgetMemberActivityComponent } from './customizable-dashboard/widgets/widget-member-activity/widget-member-activity.component';
import { WidgetProfitShareComponent } from './customizable-dashboard/widgets/widget-profit-share/widget-profit-share.component';
import { WidgetRecentTenantsComponent } from './customizable-dashboard/widgets/widget-recent-tenants/widget-recent-tenants.component';
import { WidgetRegionalStatsComponent } from './customizable-dashboard/widgets/widget-regional-stats/widget-regional-stats.component';
import { WidgetSalesSummaryComponent } from './customizable-dashboard/widgets/widget-sales-summary/widget-sales-summary.component';
import { WidgetSubscriptionExpiringTenantsComponent } from './customizable-dashboard/widgets/widget-subscription-expiring-tenants/widget-subscription-expiring-tenants.component';
import { WidgetTopStatsComponent } from './customizable-dashboard/widgets/widget-top-stats/widget-top-stats.component';
import { FilterDateRangePickerComponent } from './customizable-dashboard/filters/filter-date-range-picker/filter-date-range-picker.component';
import { AddWidgetModalComponent } from './customizable-dashboard/add-widget-modal/add-widget-modal.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { CountoModule } from 'angular2-counto';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { WidgetOrdersSummaryComponent } from './customizable-dashboard/widgets/widget-orders-summary/widget-orders-summary.component';
import { WidgetEdByStateSummaryComponent } from './customizable-dashboard/widgets/widget-ed-by-state-summary/widget-ed-by-state-summary.component';
import { WidgetAllCarriesSummaryComponent } from './customizable-dashboard/widgets/widget-all-carriers-summary/widget-all-carriers-summary.component';
import { SLAStatusLegendComponent } from './customizable-dashboard/widgets/sla-status-legend/sla-status-legend.component';
import { SLAStatusUiComponent } from './customizable-dashboard/widgets/sla-status-ui/sla-status-ui.component';
import { ConsignmentStatusDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { AngularDraggableModule } from 'angular2-draggable';
import { WidgetConsignmentStatsComponent, ModalContentComponent } from './customizable-dashboard/widgets/widget-consignment-stats/widget-consignment-stats.component';
import { MainSharedModule } from '../../main/main-shared/main-shared.module';
import { ConsignmentSearchComponent } from './consignment-search/consignment-search.component';
import { DynamicModule  } from 'ng-dynamic-component';
import { ProgressbarModelComponent } from './progress-bar/progress-bar.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule, BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TimepickerModule, TimepickerConfig } from 'ngx-bootstrap/timepicker';
import { TabsModule, TabsetConfig } from 'ngx-bootstrap/tabs';
import { NgxBootstrapDropdownConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-dropdown-config.service';
import { NgxBootstrapTabsetConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-tabset-config.service';
import { NgxBootstrapAccordionConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-accordion-config.service';
import { AccordionConfig } from 'ngx-bootstrap/accordion';
import { NgxBootstrapTimepickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-timepicker-config.service';
import { PopoverConfig } from 'ngx-bootstrap/popover';
import { NgxBootstrapPopoverConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-popover-config.service';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';
import { NgxBootstrapTooltipConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-tooltip-config.service';
import { TagInputModule } from 'ngx-chips';




@NgModule({
    imports: [
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        AbpModule,
        CommonModule,
        TableModule,
        PaginatorModule,
        GridsterModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgxChartsModule,
        BsDatepickerModule.forRoot(),
        PerfectScrollbarModule,
        CountoModule,
        FileUploadModule,
        AutosizeModule,
        SchemaFormModule.forRoot(),
        BsDatepickerModule,
        TimepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgxJsonViewerModule,
        AppBsModalModule,
        AngularDraggableModule,
        DynamicModule.forRoot(),
        MainSharedModule,
        TagInputModule
    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        MetronicsStringComponent,
        MetronicsArrayComponent,
        MetronicsButtonComponent,
        MetronicsDateComponent,
        MetronicsObjectComponent,
        MetronicsIntegerComponent,
        MetronicsSelectComponent,
        BigInputComponent,
        TimelineComponent,
        PrintShippingLabelsModalComponent,
        PrintShippingLabelsComponent,
        CustomizableDashboardComponent,
        WidgetOrdersSummaryComponent,
        WidgetEdByStateSummaryComponent,
        WidgetAllCarriesSummaryComponent,
        SLAStatusLegendComponent,
        SLAStatusUiComponent,
        WidgetConsignmentStatsComponent,
        WidgetGeneralStatsComponent,
        WidgetDailySalesComponent,
        WidgetEditionStatisticsComponent,
        WidgetHostTopStatsComponent,
        WidgetIncomeStatisticsComponent,
        WidgetMemberActivityComponent,
        WidgetProfitShareComponent,
        WidgetRecentTenantsComponent,
        WidgetRegionalStatsComponent,
        WidgetSalesSummaryComponent,
        WidgetSubscriptionExpiringTenantsComponent,
        WidgetTopStatsComponent,
        FilterDateRangePickerComponent,
        AddWidgetModalComponent,
        ConsignmentSearchComponent,
        ModalContentComponent,
        ProgressbarModelComponent,
        DatePickerComponent
    ],
    exports: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        EntityTypeHistoryModalComponent,
        EntityChangeDetailModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        MetronicsStringComponent,
        MetronicsArrayComponent,
        CustomizableDashboardComponent,
        NgxChartsModule,
        MetronicsButtonComponent,
        MetronicsDateComponent,
        MetronicsObjectComponent,
        MetronicsIntegerComponent,
        MetronicsSelectComponent,
        SchemaFormModule,
        BsDatepickerModule,
        UtilsModule,
        ModalModule,
        TableModule,
        PaginatorModule,
        BsDropdownModule,
        BigInputComponent,
        TimelineComponent,
        TimepickerModule,
        NgxJsonViewerModule,
        PrintShippingLabelsModalComponent,
        PrintShippingLabelsComponent,
        ConsignmentSearchComponent,
        ProgressbarModelComponent,
        DatePickerComponent,
        TagInputModule
    ],
    entryComponents: [
        MetronicsStringComponent,
        MetronicsArrayComponent,
        MetronicsButtonComponent,
        MetronicsDateComponent,
        MetronicsObjectComponent,
        MetronicsIntegerComponent,
        MetronicsSelectComponent,
        WidgetConsignmentStatsComponent,
        WidgetOrdersSummaryComponent,
        WidgetEdByStateSummaryComponent,
        WidgetAllCarriesSummaryComponent,
        WidgetConsignmentStatsComponent,
        WidgetGeneralStatsComponent,
        WidgetDailySalesComponent,
        WidgetEditionStatisticsComponent,
        WidgetHostTopStatsComponent,
        WidgetIncomeStatisticsComponent,
        WidgetMemberActivityComponent,
        WidgetProfitShareComponent,
        WidgetRecentTenantsComponent,
        WidgetRegionalStatsComponent,
        WidgetSalesSummaryComponent,
        WidgetSubscriptionExpiringTenantsComponent,
        WidgetTopStatsComponent,
        FilterDateRangePickerComponent,
        ModalContentComponent
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        DashboardViewConfigurationService,
        ConsignmentStatusDashboardServiceProxy,
        { provide: TooltipConfig, useFactory: NgxBootstrapTooltipConfigService.getTooltipConfig },
        { provide: PopoverConfig, useFactory: NgxBootstrapPopoverConfigService.getPopoverConfig },
        { provide: TimepickerConfig, useFactory: NgxBootstrapTimepickerConfigService.getTimepickerConfig },
        { provide: AccordionConfig, useFactory: NgxBootstrapAccordionConfigService.getAccordionConfig },
        { provide: TabsetConfig, useFactory: NgxBootstrapTabsetConfigService.getTabsetConfig },
        { provide: BsDropdownConfig, useFactory: NgxBootstrapDropdownConfigService.getBsDropdownConfig },
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig }
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AppCommonModule,
            providers: [
                AppAuthService,
                AppRouteGuard,
                { provide: TooltipConfig, useFactory: NgxBootstrapTooltipConfigService.getTooltipConfig },
                { provide: PopoverConfig, useFactory: NgxBootstrapPopoverConfigService.getPopoverConfig },
                { provide: TimepickerConfig, useFactory: NgxBootstrapTimepickerConfigService.getTimepickerConfig },
                { provide: AccordionConfig, useFactory: NgxBootstrapAccordionConfigService.getAccordionConfig },
                { provide: TabsetConfig, useFactory: NgxBootstrapTabsetConfigService.getTabsetConfig },
                { provide: BsDropdownConfig, useFactory: NgxBootstrapDropdownConfigService.getBsDropdownConfig },
                { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
                { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
                { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale },
            ]
        };
    }
}
