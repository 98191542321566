var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DashboardChartBase } from '../widgets/dashboard-chart-base';
var AllCarriesSummaryTable = /** @class */ (function (_super) {
    __extends(AllCarriesSummaryTable, _super);
    function AllCarriesSummaryTable(dataService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.datas = [];
        _this.tileTitle = 'All Carriers Summary';
        _this.title = _this.tileTitle;
        return _this;
    }
    AllCarriesSummaryTable.prototype.setChartData = function (items) {
        this.datas = items;
        if (items.length > 0) {
            this.fields = Object.keys(items[0])
                .filter(function (k) { return k !== 'courierCode'; })
                .map(function (k) {
                return {
                    key: k,
                    localizeKey: k.charAt(0).toUpperCase() + k.slice(1),
                    type: Array.isArray(items[0][k]) ? 'Array' : typeof items[0][k]
                };
            });
        }
    };
    AllCarriesSummaryTable.prototype.reload = function () {
        var _this = this;
        this.showLoading();
        this.dataService.getConsignmentsForDashboardAllCarriers().subscribe(function (result) {
            _this.title = _this.tileTitle + ' (' + (result.mainTitle || 'BAU') + ')';
            _this.setChartData(result.consignmentsSummaryDataAllCarriers || []);
            _this.hideLoading();
        });
    };
    return AllCarriesSummaryTable;
}(DashboardChartBase));
export { AllCarriesSummaryTable };
