<p-multiSelect [options]="options" [(ngModel)]="selectedItems"
    [inputId]="name" [defaultLabel]="placeholder" (onPanelHide)="onPanelHide()"
    [name]="name" [optionLabel]="'name'" [dropdownIcon]="'pi pi-caret-down'">
    <ng-template let-value pTemplate="selectedItems">
        <ng-container *ngIf="!!value && value.length > 0">
            <ng-container *ngFor="let val of value">
                <div class="ui-multiselect-token">
                    <span class="ui-multiselect-token-label" >{{val.name}}</span>
                </div>
            </ng-container>
        </ng-container>
        <span *ngIf="!value || value.length === 0" class="ui-multiselected-empty-token ui-corner-all">{{placeholder}}</span>
    </ng-template>
    <p-footer>
        <a href="javascript:void()" (click)="selectedItems = []">Clear selection</a>
    </p-footer>
</p-multiSelect>