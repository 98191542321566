import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
var ɵ0 = function () { return import("./main/main.module.ngfactory").then(function (m) { return m.MainModuleNgFactory; }); } //Lazy load main module
, ɵ1 = function () { return import("./admin/admin.module.ngfactory").then(function (m) { return m.AdminModuleNgFactory; }); }, ɵ2 = { preload: true };
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule(router, spinnerService) {
        this.router = router;
        this.spinnerService = spinnerService;
        router.events.subscribe(function (event) {
            if (event instanceof RouteConfigLoadStart) {
                spinnerService.show();
            }
            if (event instanceof RouteConfigLoadEnd) {
                spinnerService.hide();
            }
            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
            }
        });
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2 };
