import { TimepickerConfig } from 'ngx-bootstrap/timepicker';


export class NgxBootstrapTimepickerConfigService {

    static getTimepickerConfig(): TimepickerConfig {
        return Object.assign(new TimepickerConfig(), {
        });
    }
}
