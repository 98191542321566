var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBase } from '../widget-component-base';
var MemberActivityTable = /** @class */ (function (_super) {
    __extends(MemberActivityTable, _super);
    function MemberActivityTable(_dashboardService) {
        var _this = _super.call(this) || this;
        _this._dashboardService = _dashboardService;
        return _this;
    }
    MemberActivityTable.prototype.init = function () {
        this.reload();
    };
    MemberActivityTable.prototype.reload = function () {
        var _this = this;
        this.showLoading();
        this._dashboardService
            .getMemberActivity()
            .subscribe(function (result) {
            _this.memberActivities = result.memberActivities;
            _this.hideLoading();
        });
    };
    return MemberActivityTable;
}(DashboardChartBase));
var WidgetMemberActivityComponent = /** @class */ (function (_super) {
    __extends(WidgetMemberActivityComponent, _super);
    function WidgetMemberActivityComponent(injector, _dashboardService) {
        var _this = _super.call(this, injector) || this;
        _this._dashboardService = _dashboardService;
        _this.memberActivityTable = new MemberActivityTable(_this._dashboardService);
        return _this;
    }
    WidgetMemberActivityComponent.prototype.ngOnInit = function () {
        this.memberActivityTable.init();
    };
    return WidgetMemberActivityComponent;
}(WidgetComponentBase));
export { WidgetMemberActivityComponent };
