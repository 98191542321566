/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./theme8-brand.component";
var styles_Theme8BrandComponent = [];
var RenderType_Theme8BrandComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_Theme8BrandComponent, data: {} });
export { RenderType_Theme8BrandComponent as RenderType_Theme8BrandComponent };
function View_Theme8BrandComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "logo"], ["class", "kt-header__brand-logo-default"], ["height", "38"], ["src", "/assets/common/images/app-logo-on-dark.svg"]], null, null, null, null, null))], null, null); }
function View_Theme8BrandComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "logo"], ["class", "kt-header__brand-logo-sticky"], ["height", "38"], ["src", "/assets/common/images/app-logo-on-light.svg"]], null, null, null, null, null))], null, null); }
function View_Theme8BrandComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "logo"], ["height", "38"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((((_co.remoteServiceBaseUrl + "/TenantCustomization/GetTenantLogo?skin=") + _co.currentTheme.baseSettings.menu.asideSkin) + "&tenantId=") + _co.appSession.tenant.id) + "&id=") + _co.appSession.tenant.logoId); _ck(_v, 0, 0, currVal_0); }); }
export function View_Theme8BrandComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "kt-header__brand   kt-grid__item"], ["id", "kt_header_brand"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "kt-header__brand-logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "a", [["routerLink", "/"], ["target", "_blank"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Theme8BrandComponent_1)), i0.ɵdid(5, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Theme8BrandComponent_2)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_Theme8BrandComponent_3)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "_blank"; var currVal_3 = "/"; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = (!_co.appSession.tenant || !_co.appSession.tenant.logoId); _ck(_v, 5, 0, currVal_4); var currVal_5 = (!_co.appSession.tenant || !_co.appSession.tenant.logoId); _ck(_v, 7, 0, currVal_5); var currVal_6 = (_co.appSession.tenant && _co.appSession.tenant.logoId); _ck(_v, 9, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_Theme8BrandComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "theme8-brand", [], null, null, null, View_Theme8BrandComponent_0, RenderType_Theme8BrandComponent)), i0.ɵdid(1, 49152, null, 0, i3.Theme8BrandComponent, [i0.Injector, i2.DOCUMENT], null, null)], null, null); }
var Theme8BrandComponentNgFactory = i0.ɵccf("theme8-brand", i3.Theme8BrandComponent, View_Theme8BrandComponent_Host_0, {}, {}, []);
export { Theme8BrandComponentNgFactory as Theme8BrandComponentNgFactory };
