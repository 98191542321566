import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation } from '@angular/core';
import { NameValueOfInt32, AppConfigurationLookupServiceProxy, NameValueOfString, INameValueOfString } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConfigurationService } from '@app/shared/services/appconfigration.service';

@Component({
    selector: 'eventDetailZendeskMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './event-detail-zendesk-multiselect.component.html'
})

export class EventDetailZendeskMultiSelectComponent extends AppComponentBase implements OnInit {

    @Input()
    set selectedItems(items: NameValueOfString[]) {
        this._selectedItems = items || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfString[] {
        return this._selectedItems;
    }
    @Input() name = 'ExceptionType';
    @Output() selectedItemsChange: EventEmitter<NameValueOfString[]> = new EventEmitter<NameValueOfString[]>();

    placeholder = this.l('Exception');
    options: NameValueOfString[] = new Array<NameValueOfString>();

    private _selectedItems: NameValueOfString[] = [];


    constructor(injector: Injector, private _appConfigurationService: AppConfigurationService) {
       super(injector)
    }

    ngOnInit() {
        this.getEventDetails();
    }

    getEventDetails() {
        this._appConfigurationService.getEventDetailWithZendeskType().subscribe(res => {
            this.options = res;
        });
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}