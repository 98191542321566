var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, ElementRef, Injector, OnDestroy } from '@angular/core';
import { HostDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import * as _ from 'lodash';
import { WidgetComponentBase } from '../widget-component-base';
var WidgetEditionStatisticsComponent = /** @class */ (function (_super) {
    __extends(WidgetEditionStatisticsComponent, _super);
    function WidgetEditionStatisticsComponent(injector, _hostDashboardServiceProxy) {
        var _this = _super.call(this, injector) || this;
        _this._hostDashboardServiceProxy = _hostDashboardServiceProxy;
        _this.selectedDateRange = [moment().add(-7, 'days').startOf('day'), moment().endOf('day')];
        _this.editionStatisticsHasData = false;
        _this.showChart = function () {
            _this._hostDashboardServiceProxy.getEditionTenantStatistics(_this.selectedDateRange[0], _this.selectedDateRange[1])
                .subscribe(function (editionTenantStatistics) {
                _this.editionStatisticsData = _this.normalizeEditionStatisticsData(editionTenantStatistics);
                _this.editionStatisticsHasData = _.filter(_this.editionStatisticsData, function (data) { return data.value > 0; }).length > 0;
            });
        };
        _this.onDateRangeFilterChange = function (dateRange) {
            if (!dateRange || dateRange.length !== 2 || (_this.selectedDateRange[0] === dateRange[0] && _this.selectedDateRange[1] === dateRange[1])) {
                return;
            }
            _this.selectedDateRange[0] = dateRange[0];
            _this.selectedDateRange[1] = dateRange[1];
            _this.runDelayed(_this.showChart);
        };
        return _this;
    }
    WidgetEditionStatisticsComponent.prototype.ngOnInit = function () {
        this.subDateRangeFilter();
        this.runDelayed(this.showChart);
    };
    WidgetEditionStatisticsComponent.prototype.normalizeEditionStatisticsData = function (data) {
        if (!data || !data.editionStatistics || data.editionStatistics.length === 0) {
            return [];
        }
        var chartData = new Array(data.editionStatistics.length);
        for (var i = 0; i < data.editionStatistics.length; i++) {
            chartData[i] = {
                name: data.editionStatistics[i].label,
                value: data.editionStatistics[i].value
            };
        }
        return chartData;
    };
    WidgetEditionStatisticsComponent.prototype.subDateRangeFilter = function () {
        abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
    };
    WidgetEditionStatisticsComponent.prototype.unSubDateRangeFilter = function () {
        abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
    };
    WidgetEditionStatisticsComponent.prototype.ngOnDestroy = function () {
        this.unSubDateRangeFilter();
    };
    return WidgetEditionStatisticsComponent;
}(WidgetComponentBase));
export { WidgetEditionStatisticsComponent };
