import { TokenAuthServiceProxy } from '@shared/service-proxies/service-proxies';
import { TokenService } from 'abp-ng2-module/dist/src/auth/token.service';
import { Subject, of } from 'rxjs';
import { UtilsService } from 'abp-ng2-module/dist/src/utils/utils.service';
import { AppConsts } from '@shared/AppConsts';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/service-proxies/service-proxies";
import * as i2 from "abp-ng2-module/dist/src/auth/token.service";
import * as i3 from "abp-ng2-module/dist/src/utils/utils.service";
var ZeroRefreshTokenService = /** @class */ (function () {
    function ZeroRefreshTokenService(_tokenAuthService, _tokenService, _utilsService) {
        this._tokenAuthService = _tokenAuthService;
        this._tokenService = _tokenService;
        this._utilsService = _utilsService;
    }
    ZeroRefreshTokenService.prototype.tryAuthWithRefreshToken = function () {
        var _this = this;
        var refreshTokenObservable = new Subject();
        var token = this._tokenService.getRefreshToken();
        if (!token || token.trim() === '') {
            return of(false);
        }
        this._tokenAuthService.refreshToken(token)
            .subscribe(function (tokenResult) {
            if (tokenResult && tokenResult.accessToken) {
                var tokenExpireDate = (new Date(new Date().getTime() + 1000 * tokenResult.expireInSeconds));
                _this._tokenService.setToken(tokenResult.accessToken, tokenExpireDate);
                _this._utilsService.setCookieValue(AppConsts.authorization.encrptedAuthTokenName, tokenResult.encryptedAccessToken, tokenExpireDate, abp.appPath);
                refreshTokenObservable.next(true);
            }
            else {
                refreshTokenObservable.next(false);
            }
        }, function (error) {
            refreshTokenObservable.next(false);
        });
        return refreshTokenObservable;
    };
    ZeroRefreshTokenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ZeroRefreshTokenService_Factory() { return new ZeroRefreshTokenService(i0.ɵɵinject(i1.TokenAuthServiceProxy), i0.ɵɵinject(i2.TokenService), i0.ɵɵinject(i3.UtilsService)); }, token: ZeroRefreshTokenService, providedIn: "root" });
    return ZeroRefreshTokenService;
}());
export { ZeroRefreshTokenService };
