
        <div class="widget form-group  kt-form__group row">
            <label [attr.for]="id" class="horizontal control-label col-lg-3">
                {{ schema.title }}
                <span *ngIf="schema.isRequired" class="m--font-danger">*</span>
            </label>
            <span *ngIf="schema.description" class="formHelp">{{
                schema.description
            }}</span>
            <div class="col-lg-9">
                <div class="input-group date">
                    <input
                        type="text"
                        class="form-control m-input"
                        #dpDMY="bsDatepicker"
                        bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', customTodayClass: 'datePicker-highlight-current-day' }"
                        [attr.readonly]="schema.readOnly ? true : null"
                        [name]="name"
                        (bsValueChange)="setValue($event)"
                        [bsValue]="value"
                    />
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="la la-calendar"></i>
                        </span>
                    </div>
                </div>
                <div
                    class="has-danger"
                    *ngIf="
                        control.invalid &&
                        control.errors &&
                        control.errors.length
                    "
                >
                    <div class="form-control-feedback">
                        {{ 'DateInvalidPattern' | localize }}
                    </div>
                </div>
            </div>
        </div>
    