var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, EventEmitter, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConfigurationService } from '@app/shared/services/appconfigration.service';
var ConsignmentStatusMultiSelectComponent = /** @class */ (function (_super) {
    __extends(ConsignmentStatusMultiSelectComponent, _super);
    function ConsignmentStatusMultiSelectComponent(injector, _appConfigurationService) {
        var _this = _super.call(this, injector) || this;
        _this._appConfigurationService = _appConfigurationService;
        _this.name = 'consignmentStatusFilter';
        _this.visibleFilter = false;
        _this.selectedItemsChange = new EventEmitter();
        _this.placeholder = _this.l('ConsignmentStatus');
        _this.options = new Array();
        _this._selectedItems = [];
        return _this;
    }
    Object.defineProperty(ConsignmentStatusMultiSelectComponent.prototype, "selectedItems", {
        get: function () {
            return this._selectedItems;
        },
        set: function (items) {
            this._selectedItems = items || [];
            this.selectedItemsChange.emit(this._selectedItems);
        },
        enumerable: true,
        configurable: true
    });
    ;
    ConsignmentStatusMultiSelectComponent.prototype.ngOnInit = function () {
        this.getConsignmentStatuses();
    };
    ConsignmentStatusMultiSelectComponent.prototype.getConsignmentStatuses = function () {
        var _this = this;
        if (!!this.visibleFilter) {
            this._appConfigurationService.getClientVisibleConsignmentStatuss()
                .subscribe(function (consignmentStatuses) { return _this.options = consignmentStatuses.map(function (r) { return ({ name: r.name + " (" + r.value + ")", value: r.value }); }); });
        }
        else {
            this._appConfigurationService.getConsignmentStatuss()
                .subscribe(function (consignmentStatuses) { return _this.options = consignmentStatuses.map(function (r) { return ({ name: r.name + " (" + r.value + ")", value: r.value }); }); });
        }
    };
    ConsignmentStatusMultiSelectComponent.prototype.onPanelHide = function () {
        document.getElementById(this.name).focus();
    };
    return ConsignmentStatusMultiSelectComponent;
}(AppComponentBase));
export { ConsignmentStatusMultiSelectComponent };
