import { Observable } from '@node_modules/rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApplicationInsightsService } from './application-insights.service';
declare const BrowserPrint: any;

@Injectable()
export class BrowserPrintService {

    private chosenPrinter: any;
    private errors$ = new BehaviorSubject<any>(null);
    private status$ = new BehaviorSubject<any>(null);
    private readonly READY_TO_PRINT = 'Ready to Print';
    private readonly ZEBRA_PRINTER_ERROR = 'zebra_printer_error'


    constructor(
        private _applicationInsightsService: ApplicationInsightsService
    ) {}


    initialize(zebraPrinterConfiguration: ZebraPrinterConfiguration, callback) {
        this.chosenPrinter = (new BrowserPrint.Device(zebraPrinterConfiguration));
        this.setError(null);
        this.status$.next(null);

        BrowserPrint.getDefaultDevice('printer', () => {
            if ((this.chosenPrinter != null) && (this.chosenPrinter.connection != undefined)) {
                this.checkPrinterStatus(status => this.handleCheckPrinterStatusResponse(status)
                    .then(res => {
                        this.status$.next(res);
                        this.setError(null);
                    })
                    .catch(err => {
                        this._applicationInsightsService.trackException({id: this.ZEBRA_PRINTER_ERROR, error: err ? err : 'UnknownZebraPrintError', severityLevel: 2});
                        abp.log.warn(`ALERT-WARN: First attempt to connect to Zebra printer ${this.chosenPrinter.name} (${this.chosenPrinter.uid}) failed: ${err ? err : 'UnknownZebraPrintError'}. Second attempt underway.`);
                        this.checkPrinterStatus(status => {
                            if (status !== this.READY_TO_PRINT) {
                                this.setError(status ? status : 'UnknownZebraPrintError');
                                this._applicationInsightsService.trackException({id: this.ZEBRA_PRINTER_ERROR, error: status ? status : 'UnknownZebraPrintError', severityLevel: 3});
                                abp.log.error(`ALERT-ERROR: Second and final attempt to connect to Zebra printer ${this.chosenPrinter.name} (${this.chosenPrinter.uid}) failed: ${status ? status : 'UnknownZebraPrintError'}.`);
                            }
                            this.status$.next(status);
                        });
                    }));
                callback(true);
            } else {
                callback(false);
                this.setError('NoDefaultZebraPrinter');
            }
        },
        () => {
            callback(false);
            this.setError('BrowserPrintNotInstalled');
        });
    }

    sendData(data: string) {
        this.chosenPrinter.send(data);
    }

    sendUrl(url: string) {
        this.chosenPrinter.sendUrl(url);
    }

    handleCheckPrinterStatusResponse(status: string) {
        return new Promise((resolve, reject) => {
            if (status === this.READY_TO_PRINT) {
                resolve(status);
            } else {
                reject(status);
            }
        });
    }

    checkPrinterStatus(finishedFunction) {
        this.chosenPrinter.sendThenRead("~HQES",
            text => {
                var statuses = new Array();
                var ok = false;
                var is_error = text.charAt(70);
                var media = text.charAt(88);
                var head = text.charAt(87);
                var pause = text.charAt(84);
                // check each flag that prevents printing
                if (is_error == '0') {
                    ok = true;
                    statuses.push(this.READY_TO_PRINT);
                }
                if (media == '1')
                    statuses.push("Paper out");
                if (media == '2')
                    statuses.push("Ribbon Out");
                if (media == '4')
                    statuses.push("Media Door Open");
                if (media == '8')
                    statuses.push("Cutter Fault");
                if (head == '1')
                    statuses.push("Printhead Overheating");
                if (head == '2')
                    statuses.push("Motor Overheating");
                if (head == '4')
                    statuses.push("Printhead Fault");
                if (head == '8')
                    statuses.push("Incorrect Printhead");
                if (pause == '1')
                    statuses.push("Printer Paused");
                if ((!ok) && (statuses.length == 0))
                    statuses.push("Error: Unknown Error");
                finishedFunction(statuses.join());
            }, error => {
                finishedFunction(error);
            });
    }

    getPrinter(): any {
        return this.chosenPrinter;
    }

    setError(error: string) {
        this.errors$.next(error);
    }

    getErrors(): Observable<any> {
        return this.errors$.asObservable();
    }

    getStatus(): Observable<any> {
        return this.status$.asObservable();
    }

}

export class ZebraPrinterConfiguration {
    name: string;
    uid: string;
    connection = 'network';
    deviceType = 'printer';
    version: string;
    provider = 'com.zebra.ds.webdriver.desktop.provider.DefaultDeviceProvider';
    manufacturer = 'Zebra Technologies';
}



