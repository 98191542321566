var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
var Theme8BrandComponent = /** @class */ (function (_super) {
    __extends(Theme8BrandComponent, _super);
    function Theme8BrandComponent(injector, document) {
        var _this = _super.call(this, injector) || this;
        _this.document = document;
        _this.remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
        return _this;
    }
    Theme8BrandComponent.prototype.clickTopbarToggle = function () {
        this.document.body.classList.toggle('m-topbar--on');
    };
    return Theme8BrandComponent;
}(AppComponentBase));
export { Theme8BrandComponent };
