var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector } from '@angular/core';
import { OrdersDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardOrderService } from '@app/shared/common/dashboard-service/dashboard-order.service';
import { OrdersSummaryChart } from '../../models/orders-summary-chart';
import { WidgetComponentBase } from '../widget-component-base';
var WidgetOrdersSummaryComponent = /** @class */ (function (_super) {
    __extends(WidgetOrdersSummaryComponent, _super);
    function WidgetOrdersSummaryComponent(injector, dataservice, dashboardOrderService) {
        var _this = _super.call(this, injector) || this;
        _this.dataservice = dataservice;
        _this.dashboardOrderService = dashboardOrderService;
        _this.ordersSummaryBarChart = new OrdersSummaryChart(_this.dataservice);
        return _this;
    }
    WidgetOrdersSummaryComponent.prototype.ngOnInit = function () {
        this.ordersSummaryBarChart.reload();
    };
    WidgetOrdersSummaryComponent.prototype.onSelect = function (data) {
        this.dashboardOrderService.navigate({ xFilter: data.extra.id, yFilter: data.filterName, zFilter: undefined });
    };
    return WidgetOrdersSummaryComponent;
}(WidgetComponentBase));
export { WidgetOrdersSummaryComponent };
