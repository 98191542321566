var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { NameValueOfString } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppNavigationService } from '../app-navigation.service';
import { Router } from '@angular/router';
var MenuSearchBarComponent = /** @class */ (function (_super) {
    __extends(MenuSearchBarComponent, _super);
    function MenuSearchBarComponent(injector, _appNavigationService, router) {
        var _this = _super.call(this, injector) || this;
        _this._appNavigationService = _appNavigationService;
        _this.isMenuSearchActive = false;
        _this.initializeMenuSearch();
        return _this;
    }
    MenuSearchBarComponent.prototype.showMenuItem = function (menuItem) {
        return this._appNavigationService.showMenuItem(menuItem);
    };
    MenuSearchBarComponent.prototype.getAllMenuItems = function () {
        var _this = this;
        return this._appNavigationService.getAllMenuItems().filter(function (item) { return _this.showMenuItem(item) && item.route; }).map(function (menuItem) {
            return {
                name: _this.l(menuItem.name),
                route: menuItem.route
            };
        });
    };
    MenuSearchBarComponent.prototype.searchMenuItem = function (event) {
        this.searchMenuResults = this.allMenuItems
            .filter(function (item) {
            return item.name.toLowerCase().includes(event.query.toLowerCase()) ||
                item.route.toLowerCase().includes(event.query.toLowerCase());
        })
            .map(function (menuItem) {
            return new NameValueOfString({
                name: menuItem.name,
                value: menuItem.route
            });
        });
    };
    MenuSearchBarComponent.prototype.selectMenuItem = function (event) {
        var _this = this;
        if (event && event.value) {
            this.router.navigate([event.value]).then(function (navigated) {
                _this.selected = '';
            });
        }
    };
    MenuSearchBarComponent.prototype.initializeMenuSearch = function () {
        this.isMenuSearchActive = false;
        var themeSettings = this.currentTheme.baseSettings;
        if (themeSettings && themeSettings.menu && themeSettings.menu.searchActive) {
            this.allMenuItems = this.getAllMenuItems();
            this.isMenuSearchActive = true;
        }
    };
    return MenuSearchBarComponent;
}(AppComponentBase));
export { MenuSearchBarComponent };
