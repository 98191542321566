var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TokenService } from '@abp/auth/token.service';
import { Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileServiceProxy, UpdateProfilePictureInput } from '@shared/service-proxies/service-proxies';
import { FileUploader } from 'ng2-file-upload';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
var ChangeProfilePictureModalComponent = /** @class */ (function (_super) {
    __extends(ChangeProfilePictureModalComponent, _super);
    function ChangeProfilePictureModalComponent(injector, _profileService, _tokenService) {
        var _this = _super.call(this, injector) || this;
        _this._profileService = _profileService;
        _this._tokenService = _tokenService;
        _this.active = false;
        _this.saving = false;
        _this.maxProfilPictureBytesUserFriendlyValue = 5;
        _this._uploaderOptions = {};
        _this.imageChangedEvent = '';
        return _this;
    }
    ChangeProfilePictureModalComponent.prototype.initializeModal = function () {
        this.active = true;
        this.temporaryPictureUrl = '';
        this.temporaryPictureFileName = '';
        this.initFileUploader();
    };
    ChangeProfilePictureModalComponent.prototype.show = function () {
        this.initializeModal();
        this.modal.show();
    };
    ChangeProfilePictureModalComponent.prototype.close = function () {
        this.active = false;
        this.imageChangedEvent = '';
        this.uploader.clearQueue();
        this.modal.hide();
    };
    ChangeProfilePictureModalComponent.prototype.fileChangeEvent = function (event) {
        if (event.target.files[0].size > 5242880) { //5MB
            this.message.warn(this.l('ProfilePicture_Warn_SizeLimit', this.maxProfilPictureBytesUserFriendlyValue));
            return;
        }
        this.imageChangedEvent = event;
    };
    ChangeProfilePictureModalComponent.prototype.imageCroppedFile = function (file) {
        var files = [file];
        this.uploader.clearQueue();
        this.uploader.addToQueue(files);
    };
    ChangeProfilePictureModalComponent.prototype.initFileUploader = function () {
        var _this = this;
        this.uploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + '/Profile/UploadProfilePicture' });
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
        this.uploader.onAfterAddingFile = function (file) {
            file.withCredentials = false;
        };
        this.uploader.onBuildItemForm = function (fileItem, form) {
            form.append('FileType', fileItem.file.type);
            form.append('FileName', 'ProfilePicture');
            form.append('FileToken', _this.guid());
        };
        this.uploader.onSuccessItem = function (item, response, status) {
            var resp = JSON.parse(response);
            if (resp.success) {
                _this.updateProfilePicture(resp.result.fileToken);
            }
            else {
                _this.message.error(resp.error.message);
            }
        };
        this.uploader.setOptions(this._uploaderOptions);
    };
    ChangeProfilePictureModalComponent.prototype.updateProfilePicture = function (fileToken) {
        var _this = this;
        var input = new UpdateProfilePictureInput();
        input.fileToken = fileToken;
        input.x = 0;
        input.y = 0;
        input.width = 0;
        input.height = 0;
        this.saving = true;
        this._profileService.updateProfilePicture(input)
            .pipe(finalize(function () { _this.saving = false; }))
            .subscribe(function () {
            abp.event.trigger('profilePictureChanged');
            _this.close();
        });
    };
    ChangeProfilePictureModalComponent.prototype.guid = function () {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    };
    ChangeProfilePictureModalComponent.prototype.save = function () {
        this.uploader.uploadAll();
    };
    return ChangeProfilePictureModalComponent;
}(AppComponentBase));
export { ChangeProfilePictureModalComponent };
