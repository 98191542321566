import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { ApplicationInsightsService } from './application-insights.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
    private readonly UNKNOWN_ERROR = 'unknown_error';
    applicationInsightsService: ApplicationInsightsService;
    constructor(private _injector: Injector) {
        super();
    }

    handleError(error: Error) {
        this.applicationInsightsService = this._injector.get(ApplicationInsightsService);
        this.applicationInsightsService.trackException({ id: this.UNKNOWN_ERROR, error: error }); // Manually log exception
        abp.log.error(error);
    }
}
