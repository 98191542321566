<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{ edByStateSummaryTable.title }}
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <a href="javascript:;" class="btn btn-primary" (click)="edByStateSummaryTable.reload()">
                <i class="fa fa-redo"></i> Refresh
            </a>
        </div>
    </div>
    <div class="kt-portlet__body" [busyIf]="edByStateSummaryTable.loading">
        <div id="region_statistics_content">
            <table class="table">
                <thead>
                    <tr>
                        <td *ngFor="let field of edByStateSummaryTable.fields" class="m-widget11__label">
                            {{ field.localizeKey | localize }}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of edByStateSummaryTable.datas; let index = index">
                        <td *ngFor="let field of edByStateSummaryTable.fields">
                            <span *ngIf="field.key === 'state'">
                                {{ data[field.key] }}
                            </span>
                            <sla-status-ui [data]="data" [field]="field" *ngIf="field.type === 'object'" [dashboardKeyName]="'state'" (clicked)="fieldClick($event)"></sla-status-ui>
                            <a *ngIf="field.key !== 'state' && field.type !== 'object'" href="javascript:;" (click)="fieldClick({xFilter: data['state'], yFilter:field.localizeKey})">
                                {{ data[field.key] }}
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <sla-status-legend></sla-status-legend>
</div>