/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-search-bar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/primeng/components/autocomplete/autocomplete.ngfactory";
import * as i3 from "primeng/components/autocomplete/autocomplete";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../shared/common/pipes/localize.pipe";
import * as i6 from "@angular/common";
import * as i7 from "./menu-search-bar.component";
import * as i8 from "../app-navigation.service";
import * as i9 from "@angular/router";
var styles_MenuSearchBarComponent = [i0.styles];
var RenderType_MenuSearchBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuSearchBarComponent, data: {} });
export { RenderType_MenuSearchBarComponent as RenderType_MenuSearchBarComponent };
function View_MenuSearchBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p-autoComplete", [["field", "name"], ["inputStyleClass", "form-control menuSearchBarInput"], ["styleClass", "menuSearchBarContainer"]], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "completeMethod"], [null, "onSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selected = $event) !== false);
        ad = (pd_0 && ad);
    } if (("completeMethod" === en)) {
        var pd_1 = (_co.searchMenuItem($event) !== false);
        ad = (pd_1 && ad);
    } if (("onSelect" === en)) {
        var pd_2 = (_co.selectMenuItem($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_AutoComplete_0, i2.RenderType_AutoComplete)), i1.ɵdid(1, 9879552, null, 1, i3.AutoComplete, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.IterableDiffers], { minLength: [0, "minLength"], styleClass: [1, "styleClass"], inputStyleClass: [2, "inputStyleClass"], placeholder: [3, "placeholder"], field: [4, "field"], suggestions: [5, "suggestions"] }, { completeMethod: "completeMethod", onSelect: "onSelect" }), i1.ɵqud(603979776, 1, { templates: 1 }), i1.ɵppd(3, 1), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.AutoComplete]), i1.ɵdid(5, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(7, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = 1; var currVal_10 = "menuSearchBarContainer"; var currVal_11 = "form-control menuSearchBarInput"; var currVal_12 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 3, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), "QuickNav")), ""); var currVal_13 = "name"; var currVal_14 = _co.searchMenuResults; _ck(_v, 1, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.selected; _ck(_v, 5, 0, currVal_15); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).filled; var currVal_1 = (i1.ɵnov(_v, 1).focus && !i1.ɵnov(_v, 1).disabled); var currVal_2 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 7).ngClassValid; var currVal_7 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_MenuSearchBarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.LocalizePipe, [i1.Injector]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuSearchBarComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMenuSearchActive; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MenuSearchBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "menu-search-bar", [], null, null, null, View_MenuSearchBarComponent_0, RenderType_MenuSearchBarComponent)), i1.ɵdid(1, 49152, null, 0, i7.MenuSearchBarComponent, [i1.Injector, i8.AppNavigationService, i9.Router], null, null)], null, null); }
var MenuSearchBarComponentNgFactory = i1.ɵccf("menu-search-bar", i7.MenuSearchBarComponent, View_MenuSearchBarComponent_Host_0, {}, {}, []);
export { MenuSearchBarComponentNgFactory as MenuSearchBarComponentNgFactory };
