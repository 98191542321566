<div class="kt-pricing-1__btn btn-group" role="group" id="Rating">
    <button type="button" title="Total" class="btn btn-status btn-success" (click)="clicked.emit({xFilter: data[dashboardKeyName], yFilter:field.localizeKey,zFilter:total})">
        {{ data[field.key].total}}
    </button>
    <button type="button" title="At Risk" class="btn btn-status btn-warning" (click)="clicked.emit({xFilter: data[dashboardKeyName], yFilter:field.localizeKey,zFilter:atRisk})">
        {{ data[field.key].atRisk}}
    </button>
    <button type="button" title="Breached" class="btn btn-status btn-danger" (click)="clicked.emit({xFilter: data[dashboardKeyName], yFilter:field.localizeKey,zFilter:breached})">
        {{ data[field.key].breached }}
    </button>
</div>
