import { Component } from "@angular/core";

import { DatePipe } from "@angular/common";
import { ControlWidget } from "ngx-schema-form";

@Component({
    selector: "sf-date-widget",
    providers: [DatePipe],
    template: `
        <div class="widget form-group  kt-form__group row">
            <label [attr.for]="id" class="horizontal control-label col-lg-3">
                {{ schema.title }}
                <span *ngIf="schema.isRequired" class="m--font-danger">*</span>
            </label>
            <span *ngIf="schema.description" class="formHelp">{{
                schema.description
            }}</span>
            <div class="col-lg-9">
                <div class="input-group date">
                    <input
                        type="text"
                        class="form-control m-input"
                        #dpDMY="bsDatepicker"
                        bsDatepicker
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', customTodayClass: 'datePicker-highlight-current-day' }"
                        [attr.readonly]="schema.readOnly ? true : null"
                        [name]="name"
                        (bsValueChange)="setValue($event)"
                        [bsValue]="value"
                    />
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="la la-calendar"></i>
                        </span>
                    </div>
                </div>
                <div
                    class="has-danger"
                    *ngIf="
                        control.invalid &&
                        control.errors &&
                        control.errors.length
                    "
                >
                    <div class="form-control-feedback">
                        {{ 'DateInvalidPattern' | localize }}
                    </div>
                </div>
            </div>
        </div>
    `
})
export class MetronicsDateComponent extends ControlWidget {
    value: Date;
    constructor(private datepipe: DatePipe) {
        super();
    }
    ngAfterViewInit() {
        super.ngAfterViewInit();
        if (!!this.control.value) {
            this.value = new Date(
                this.control.value.replace(
                    /(\d{2})\/(\d{2})\/(\d{4})/,
                    "$2/$1/$3"
                )
            );
        }
    }
    setValue(value: any) {
        if (!!value) {
            this.control.setValue(this.datepipe.transform(value, "dd/MM/yyyy"));
        } else {
            this.control.setValue('');
            this.control.setErrors(null);
        }
    }
}
