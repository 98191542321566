import { OnDemandPreloadService } from './on-demand-preload-service';
import { EMPTY } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./on-demand-preload-service";
var OnDemandPreloadStrategy = /** @class */ (function () {
    function OnDemandPreloadStrategy(preloadOnDemandService) {
        this.preloadOnDemandService = preloadOnDemandService;
        this.preloadOnDemand$ = this.preloadOnDemandService.state;
    }
    OnDemandPreloadStrategy.prototype.preload = function (route, load) {
        var _this = this;
        return this.preloadOnDemand$.pipe(mergeMap(function (preloadOptions) {
            var shouldPreload = _this.preloadCheck(route, preloadOptions);
            return shouldPreload ? load() : EMPTY;
        }));
    };
    OnDemandPreloadStrategy.prototype.preloadCheck = function (route, preloadOptions) {
        return ((route.data &&
            route.data['preload']) ||
            [route.path, '*'].includes(preloadOptions.routePath) &&
                preloadOptions.preload);
    };
    OnDemandPreloadStrategy.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnDemandPreloadStrategy_Factory() { return new OnDemandPreloadStrategy(i0.ɵɵinject(i1.OnDemandPreloadService)); }, token: OnDemandPreloadStrategy, providedIn: "root" });
    return OnDemandPreloadStrategy;
}());
export { OnDemandPreloadStrategy };
