/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/ngx-schema-form/ngx-schema-form.ngfactory";
import * as i2 from "ngx-schema-form";
import * as i3 from "@angular/common";
import * as i4 from "./array.component";
var styles_MetronicsArrayComponent = [];
var RenderType_MetronicsArrayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MetronicsArrayComponent, data: {} });
export { RenderType_MetronicsArrayComponent as RenderType_MetronicsArrayComponent };
function View_MetronicsArrayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "m--font-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["*"]))], null, null); }
function View_MetronicsArrayComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "formHelp"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.schema.description; _ck(_v, 1, 0, currVal_0); }); }
function View_MetronicsArrayComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "sf-form-element", [], null, null, null, i1.View_FormElementComponent_0, i1.RenderType_FormElementComponent)), i0.ɵdid(2, 245760, null, 0, i2.FormElementComponent, [i2.ɵb, i2.ɵe, i0.Renderer2, i0.ElementRef], { formProperty: [0, "formProperty"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MetronicsArrayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "widget form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "fieldset", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "legend", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MetronicsArrayComponent_1)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MetronicsArrayComponent_2)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MetronicsArrayComponent_3)), i0.ɵdid(9, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.schema.isRequired; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.schema.description; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.formProperty.properties; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.schema.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_MetronicsArrayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sf-array-widget", [], null, null, null, View_MetronicsArrayComponent_0, RenderType_MetronicsArrayComponent)), i0.ɵdid(1, 4243456, null, 0, i4.MetronicsArrayComponent, [], null, null)], null, null); }
var MetronicsArrayComponentNgFactory = i0.ɵccf("sf-array-widget", i4.MetronicsArrayComponent, View_MetronicsArrayComponent_Host_0, {}, {}, []);
export { MetronicsArrayComponentNgFactory as MetronicsArrayComponentNgFactory };
