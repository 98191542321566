import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { FeatureCheckerService } from '@abp/features/feature-checker.service';
import { LocalizationService } from '@abp/localization/localization.service';
import { MessageService } from '@abp/message/message.service';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { NotifyService } from '@abp/notify/notify.service';
import { SettingService } from '@abp/settings/setting.service';
import { Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import { UiCustomizationSettingsDto, ExceptionType, INameValueOfInt32, NameValueOfString, NameValueOfInt32, BusinessHour, DayOfWeek } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HtmlHelper } from '@shared/helpers/HtmlHelper';

import * as moment from 'moment';
import { QueryString } from '@app/shared/query-string.model';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { LastRouteService } from '@shared/utils/last-route.service';
import { ApplicationInsightsService } from '@app/shared/services/application-insights.service';
import { WeekDay } from '@angular/common';

export abstract class AppComponentBase {
    selectAll = 'All';
    selectNone = 'None';
    selectAllFlag = new NameValueOfString({
        name: this.selectAll,
        value: this.selectAll
    });
    selectNoneFlag = new NameValueOfString({
        name: this.selectNone,
        value: this.selectNone
    });
    selectAllFlagInt = new NameValueOfInt32({
        name: this.selectAll,
        value: null
    });
    selectNoneFlagInt = new NameValueOfInt32({
        name: this.selectNone,
        value: null
    });
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;

    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    datetimeService: DateTimeService;
    spinnerService: NgxSpinnerService;
    datetimeOptions: any;
    lastRouteService: LastRouteService;
    applicationInsightsService: ApplicationInsightsService;

    defaultDateRange: moment.Moment[] = [moment().add(-90, 'days').startOf('day'), moment().endOf('day')];
    noDateValue: moment.Moment[];

    endOfDay = moment().endOf('day');

    route: ActivatedRoute;
    router: Router;

    //todo: following need to configyfied
    dateFormat = 'DD/MM/YY';

    calendarDateOnlyFormat = {
        lastDay: this.dateFormat,
        sameDay: this.dateFormat,
        nextDay: this.dateFormat,
        lastWeek: this.dateFormat,
        nextWeek: this.dateFormat,
        sameElse: this.dateFormat
    };

    calendarDateFormat = {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: this.dateFormat,
        nextWeek: this.dateFormat,
        sameElse: this.dateFormat
    };

    calendarDateTimeFormat = {
        lastDay: '[Yesterday at] LT',
        sameDay: '[Today at] LT',
        nextDay: '[Tomorrow at] LT',
        lastWeek: `${this.dateFormat} LT`,
        nextWeek: `${this.dateFormat} LT`,
        sameElse: `${this.dateFormat} LT`
    };

    params: ParamMap;

    constructor(injector: Injector) {
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this.params = this.route.snapshot.queryParamMap;
        this.datetimeService = injector.get(DateTimeService);
        this.datetimeOptions = this.datetimeService.createDateRangePickerOptions();
        this.lastRouteService = injector.get(LastRouteService);
        this.spinnerService = injector.get(NgxSpinnerService);
        this.applicationInsightsService = injector.get(ApplicationInsightsService);
    }

    flattenDeep(array) {
        return array.reduce((acc, val) =>
            Array.isArray(val) ?
                acc.concat(this.flattenDeep(val)) :
                acc.concat(val),
            []);
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.applicationInsightsService.logEvent(name, properties);
    }

    l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        permissions = [].concat.apply([], permissions);
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    get currentTheme(): UiCustomizationSettingsDto {
        return this.appSession.theme;
    }

    get containerClass(): string {
        if (this.appSession.theme.baseSettings.layout.layoutType === 'fluid') {
            return 'kt-container kt-container--fluid';
        }

        return 'kt-container';
    }

    d(date: moment.Moment, dateOnly = false): string {
        return (!date) ? '' : (dateOnly ? date.calendar(null, this.calendarDateOnlyFormat) : date.calendar(null, this.calendarDateFormat));
    }

    dt(date: moment.Moment): string {

        return (!date) ? '' : date.calendar(null, this.calendarDateTimeFormat);
    }

    dtz(date: moment.Moment, state: string): string {
        if (!date) {
            return '';
        }
        let _date = moment.tz(date, this.getTimeZoneByState(state));
        return _date.calendar(null, this.calendarDateTimeFormat);

    }

    getTimeZoneByState(state: string) {
        switch (state.toUpperCase()) {
            case 'WA':
                return 'Australia/Perth';
            case 'NT':
            case 'SA':
                return 'Australia/Darwin';
            case 'QLD':
                return 'Australia/Brisbane';
            case 'TAS':
                return 'Australia/Hobart';
            case 'ACT':
            case 'VIC':
            case 'NSW':
                return 'Australia/Sydney';
        }
    }

    getDay(v: number) {
        return WeekDay[v];
    }

    wd(businessHour: BusinessHour[]): string[] {
        if (businessHour != null && businessHour.some(e=> e.open)) {
            return businessHour.filter(e => e.open).map(e => DayOfWeek[e.weekday]).map(e => e.substring(0,3));
        }
        else {
            return null;
        }
    }


    getParamsFromFilters(filters: QueryString[]) {
        let params: Params = {};
        for (const filter of filters) {
            if (filter.value != null && filter.value.length !== 0) {
                if (typeof filter.value === 'string') {
                    params[filter.name] = filter.value || null;
                } else {
                    params[filter.name] = JSON.stringify(filter.value);
                }
            }
        }
        return params;
    }

    addFiltersToQueryString(...filters: QueryString[]) {
        let queryParams = {};
        for (const filter of filters) {
            if (filter.value != null && filter.value.length !== 0) {
                if (typeof filter.value === 'string') {
                    queryParams[filter.name] = filter.value || null;
                } else {
                    queryParams[filter.name] = JSON.stringify(filter.value);
                }
            }
        }
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: Object.assign(this.getParamsFromFilters(filters), queryParams),
            replaceUrl: true
        });
    }

    getFilterValue(rawValue: string) {
        if (!rawValue) { return; }
        if (HtmlHelper.isJson(rawValue)) {
            return JSON.parse(rawValue);
        } else {
            return rawValue;
        }
    }

    navigateToManifestFileRecords(fileName: string, status: string = null) {
        const manifestFileNameFilter = { name: fileName, value: fileName };
        let queryParams = {
            manifestFileNameFilter: JSON.stringify(manifestFileNameFilter),
            minUpdatedAtFilter: moment(this.datetimeOptions.minDate),
            maxUpdatedAtFilter: moment(this.endOfDay)
        };
        if (status !== null) {
            queryParams['statusFilter'] = JSON.stringify({ name: this.l(status), value: status });
        }
        this.router.navigate(['/app/main/manifests/manifestFileRecords'], {
            queryParams: queryParams
        });
    }
    navigateToCostregion(couriername: string, suburb: string) {
        this.router.navigate(['/app/main/appConfiguration/costRegionLocations'],
            {
                queryParams: {
                    newsuburb: suburb,
                    newcourier: couriername,
                    addnewCostRegion: true
                }
            });
    }

    navigateToConsignments(barcodes : string) {
        this.router.navigate(['/app/main/shipments/consignments'],
            {
                queryParams: {
                    barcodeFilter: barcodes,
                    resetDates: true
                }
            });
    }

    copyToClipboard(item: string, $event: any = null): void {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = item;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);

        this.notify.success('copied', '', { timer: 500 });

        if ($event) {
            event.stopPropagation();
        }
    }
}
