<div class="kt-portlet kt-portlet--height-fluid ">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                <span *ngIf='!editMode' (click)='editMode=true' class='h3'>{{widget.settings.Title}}</span>
                <div class="gridster-item-content">
                <input *ngIf='editMode'  [(ngModel)]="widget.settings.Title"
                    (change)="saveWidgetTitle($event.target.value)" class='form-control' appAutofocus type='text'
                    (keydown.enter)='$event.target.blur()' (focusout)="editMode=false">
                </div>
            </h3>
        </div>
        <div *ngIf="!editModeEnabled && widget.settings.Data && widget.settings.Data.getAllConsignmentsInput" class="kt-portlet__head-toolbar">
            <button type="button" class="btn btn-outline-primary disabled" disabled>
                <span *ngIf="!!widget.settings.Data.getAllConsignmentsInput.selectedDateFilter">
                    {{l(widget.settings.Data.getAllConsignmentsInput.selectedDateFilter)}}
                </span>
            </button>
            <button type="button" class="btn btn-outline-primary disabled" disabled>
                <span *ngIf="!!widget.settings.Data.getAllConsignmentsInput.selectedRangeLabel else notRange">
                    {{l(widget.settings.Data.getAllConsignmentsInput.selectedRangeLabel)}}
                </span>
            </button>
            <ng-template #notRange>

                    <span *ngIf="!!widget.settings.Data.getAllConsignmentsInput.maxCreatedDateFilter" >
                        {{widget.settings.Data.getAllConsignmentsInput.minCreatedDateFilter | momentFormat: 'LL'}} -
                        {{widget.settings.Data.getAllConsignmentsInput.maxCreatedDateFilter | momentFormat: 'LL'}}
                    </span>
                    <span *ngIf="!!widget.settings.Data.getAllConsignmentsInput.maxOrderDateFilter">
                        {{widget.settings.Data.getAllConsignmentsInput.minOrderDateFilter | momentFormat: 'LL'}} -
                        {{widget.settings.Data.getAllConsignmentsInput.maxOrderDateFilter | momentFormat: 'LL'}}
                    </span>
                    <span *ngIf="!!widget.settings.Data.getAllConsignmentsInput.maxShipDateFilter">
                        {{widget.settings.Data.getAllConsignmentsInput.minShipDateFilter | momentFormat: 'LL'}} -
                        {{widget.settings.Data.getAllConsignmentsInput.maxShipDateFilter | momentFormat: 'LL'}}
                    </span>
                    <span *ngIf="!!widget.settings.Data.getAllConsignmentsInput.maxPreferredShipDateFilter">
                        {{widget.settings.Data.getAllConsignmentsInput.minPreferredShipDateFilter | momentFormat: 'LL'}}
                        -
                        {{widget.settings.Data.getAllConsignmentsInput.maxPreferredShipDateFilter | momentFormat: 'LL'}}
                    </span>
            </ng-template>
            <button *ngIf="!!widget.settings.Data.getAllConsignmentsInput.selectedTimeRange" type="button" class="btn btn-outline-primary disabled" disabled>
                <span >
                    {{l(widget.settings.Data.getAllConsignmentsInput.selectedTimeRange)}}
                </span>
            </button>
            <a href="javascript:;" class="btn btn-primary" (click)="consignmentStats.reload()">
                <i class="fa fa-redo"></i> Refresh
            </a>
        </div>
    </div>
    <div class="kt-portlet__body" [perfectScrollbar]>

        <div class="kt-widget kt-widget--user-profile-2">
            <div class="kt-widget__body">
                <div class="kt-widget__content" (click)="redirectToConsignments()"
                    *ngIf="!editModeEnabled && consignmentStats">
                    <div class="kt-widget__stats kt-margin-r-20">
                        <div class="kt-widget__icon">
                            <i class="flaticon2-delivery-truck"></i>
                        </div>
                        <div class="kt-widget__details">
                            <span class="kt-widget__title">Consignments</span>
                            <span class="kt-widget__value">{{consignmentStats.result.consignmentCount}}</span>
                        </div>
                    </div>

                    <div class="kt-widget__stats">
                        <div class="kt-widget__icon">
                            <i class="flaticon2-delivery-package"></i>
                        </div>
                        <div class="kt-widget__details">
                            <span class="kt-widget__title">Packages</span>
                            <span class="kt-widget__value">{{consignmentStats.result.packageCount}}</span>
                        </div>
                    </div>
                    <div class="kt-widget__stats">
                        <div class="kt-widget__icon">
                            <i class="flaticon2-layers"></i>
                        </div>
                        <div class="kt-widget__details">
                            <span class="kt-widget__title">Weight</span>
                            <span class="kt-widget__value">{{consignmentStats.result.totalWeight/1000 | number:'1.2-2'}}
                                Kgs</span>
                        </div>
                    </div>
                </div>
                <div *ngIf="!!editModeEnabled">
                    <div class="gridster-item-content">
                    <button type="button" class="btn btn-primary"
                        (click)="openModalWithComponent()">{{l('ChangeStatsCriteria')}}</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>