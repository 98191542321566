var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { AbpSessionService } from '@abp/session/abp-session.service';
import { ImpersonationService } from '@app/admin/users/impersonation.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { LinkedAccountService } from '@app/shared/layout/linked-account.service';
import { AppConsts } from '@shared/AppConsts';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { ChangeUserLanguageDto, ProfileServiceProxy, UserLinkServiceProxy } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
var TopBarComponent = /** @class */ (function (_super) {
    __extends(TopBarComponent, _super);
    function TopBarComponent(injector, _abpSessionService, _abpMultiTenancyService, _profileServiceProxy, _userLinkServiceProxy, _authService, _impersonationService, _linkedAccountService) {
        var _this = _super.call(this, injector) || this;
        _this._abpSessionService = _abpSessionService;
        _this._abpMultiTenancyService = _abpMultiTenancyService;
        _this._profileServiceProxy = _profileServiceProxy;
        _this._userLinkServiceProxy = _userLinkServiceProxy;
        _this._authService = _authService;
        _this._impersonationService = _impersonationService;
        _this._linkedAccountService = _linkedAccountService;
        _this.isHost = false;
        _this.isImpersonatedLogin = false;
        _this.isMultiTenancyEnabled = false;
        _this.shownLoginName = '';
        _this.tenancyName = '';
        _this.userName = '';
        _this.profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
        _this.defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + _this.currentTheme.baseSettings.menu.asideSkin + '.svg';
        _this.unreadChatMessageCount = 0;
        _this.remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
        _this.chatConnected = false;
        _this.isQuickThemeSelectEnabled = _this.setting.getBoolean('App.UserManagement.IsQuickThemeSelectEnabled');
        return _this;
    }
    TopBarComponent.prototype.ngOnInit = function () {
        this.isHost = !this._abpSessionService.tenantId;
        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
        this.languages = _.filter(this.localization.languages, function (l) { return (l).isDisabled === false; });
        this.currentLanguage = this.localization.currentLanguage;
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
        this.setCurrentLoginInformations();
        this.getProfilePicture();
        this.getRecentlyLinkedUsers();
        this.registerToEvents();
    };
    TopBarComponent.prototype.registerToEvents = function () {
        var _this = this;
        abp.event.on('profilePictureChanged', function () {
            _this.getProfilePicture();
        });
        abp.event.on('app.chat.unreadMessageCountChanged', function (messageCount) {
            _this.unreadChatMessageCount = messageCount;
        });
        abp.event.on('app.chat.connected', function () {
            _this.chatConnected = true;
        });
        abp.event.on('app.getRecentlyLinkedUsers', function () {
            _this.getRecentlyLinkedUsers();
        });
        abp.event.on('app.onMySettingsModalSaved', function () {
            _this.onMySettingsModalSaved();
        });
    };
    TopBarComponent.prototype.changeLanguage = function (languageName) {
        var input = new ChangeUserLanguageDto();
        input.languageName = languageName;
        this._profileServiceProxy.changeLanguage(input).subscribe(function () {
            abp.utils.setCookieValue('Abp.Localization.CultureName', languageName, new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
            abp.appPath);
            window.location.reload();
        });
    };
    TopBarComponent.prototype.setCurrentLoginInformations = function () {
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        this.userName = this.appSession.user.userName;
    };
    TopBarComponent.prototype.getShownUserName = function (linkedUser) {
        if (!this._abpMultiTenancyService.isEnabled) {
            return linkedUser.username;
        }
        return (linkedUser.tenantId ? linkedUser.tenancyName : '.') + '\\' + linkedUser.username;
    };
    TopBarComponent.prototype.getProfilePicture = function () {
        var _this = this;
        this._profileServiceProxy.getProfilePicture().subscribe(function (result) {
            if (result && result.profilePicture) {
                _this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    };
    TopBarComponent.prototype.getRecentlyLinkedUsers = function () {
        var _this = this;
        this._userLinkServiceProxy.getRecentlyUsedLinkedUsers().subscribe(function (result) {
            _this.recentlyLinkedUsers = result.items;
        });
    };
    TopBarComponent.prototype.showLoginAttempts = function () {
        abp.event.trigger('app.show.loginAttemptsModal');
    };
    TopBarComponent.prototype.showLinkedAccounts = function () {
        abp.event.trigger('app.show.linkedAccountsModal');
    };
    TopBarComponent.prototype.changePassword = function () {
        abp.event.trigger('app.show.changePasswordModal');
    };
    TopBarComponent.prototype.changeDepot = function () {
        abp.event.trigger('app.show.changeDepotModal');
    };
    TopBarComponent.prototype.changeProfilePicture = function () {
        abp.event.trigger('app.show.changeProfilePictureModal');
    };
    TopBarComponent.prototype.changeMySettings = function () {
        abp.event.trigger('app.show.mySettingsModal');
    };
    TopBarComponent.prototype.logout = function () {
        this._authService.logout();
    };
    TopBarComponent.prototype.onMySettingsModalSaved = function () {
        this.shownLoginName = this.appSession.getShownLoginName();
    };
    TopBarComponent.prototype.backToMyAccount = function () {
        this._impersonationService.backToImpersonator();
    };
    TopBarComponent.prototype.switchToLinkedUser = function (linkedUser) {
        this._linkedAccountService.switchToAccount(linkedUser.id, linkedUser.tenantId);
    };
    TopBarComponent.prototype.downloadCollectedData = function () {
        var _this = this;
        this._profileServiceProxy.prepareCollectedData().subscribe(function () {
            _this.message.success(_this.l('GdprDataPrepareStartedNotification'), _this.l('Successful'));
        });
    };
    return TopBarComponent;
}(ThemesLayoutBaseComponent));
export { TopBarComponent };
