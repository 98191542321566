var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { FileOperationsServiceProxy } from '@shared/service-proxies/service-proxies';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
var FileDownloadService = /** @class */ (function (_super) {
    __extends(FileDownloadService, _super);
    function FileDownloadService(_httpClient, injector, _activatedRoute, _router, _fileOperationService) {
        var _this = _super.call(this, injector) || this;
        _this._httpClient = _httpClient;
        _this._activatedRoute = _activatedRoute;
        _this._router = _router;
        _this._fileOperationService = _fileOperationService;
        return _this;
    }
    FileDownloadService.prototype.downloadTempFile = function (file) {
        var url = AppConsts.remoteServiceBaseUrl + '/File/DownloadTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
        location.href = url; //TODO: This causes reloading of same page in Firefox
    };
    FileDownloadService.prototype.downloadOnly = function (file) {
        var url = AppConsts.remoteServiceBaseUrl + '/File/DownloadTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
        return this._httpClient.get(url, { responseType: 'blob' })
            .pipe(map(function (res) {
            return new Blob([res], { type: 'application/pdf', });
        }));
    };
    FileDownloadService.prototype.downloadPodImage = function (fileName) {
        var url = AppConsts.remoteServiceBaseUrl + '/File/DownloadPodImage?podIdentifierInFred=' + fileName;
        return this._httpClient.get(url, { responseType: 'blob' });
    };
    FileDownloadService.prototype.downloadBlobFile = function (permission, fileName) {
        var _this = this;
        this._fileOperationService.getFileContentByPermission(permission, fileName)
            .subscribe(function (result) {
            if (result) {
                _this.downloadTempFile(result);
            }
            else {
                _this.message.error(_this.l('RequestedFileDoesNotExists'));
            }
        }, function () {
            console.log('Error downloading the file.');
        }, function () {
            _this._router.navigate([], { relativeTo: _this._activatedRoute });
        });
    };
    FileDownloadService.prototype.convertBase64StringtoUint8 = function (data) {
        var rawData = window.atob(data);
        var datalength = rawData.length;
        var byteArray = new Uint8Array(new ArrayBuffer(datalength));
        for (var i = 0; i < datalength; i++) {
            byteArray[i] = rawData.charCodeAt(i);
        }
        return byteArray;
    };
    return FileDownloadService;
}(AppComponentBase));
export { FileDownloadService };
