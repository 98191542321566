import { AbpHttpInterceptor, RefreshTokenService } from '@abp/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';

@NgModule({
    providers: [
        ApiServiceProxies.PalletsServiceProxy,    
        ApiServiceProxies.PalletStatusChangeServiceProxy,    
        ApiServiceProxies.PalletRescheduleServiceProxy,
        ApiServiceProxies.ExternalVendorsServiceProxy,        
        ApiServiceProxies.StoresServiceProxy,        
        ApiServiceProxies.BrandsServiceProxy,        
        ApiServiceProxies.ReturnToSuppliersServiceProxy,        
        ApiServiceProxies.PrintersServiceProxy,
        ApiServiceProxies.BurndownEventsServiceProxy,
        ApiServiceProxies.CostRegionLocationsServiceProxy,
        ApiServiceProxies.StoreNumberDeliveryPostcodesServiceProxy,
        ApiServiceProxies.CostRegionsServiceProxy,
        ApiServiceProxies.DriverDaysWorkedServiceProxy,
        ApiServiceProxies.BrandRegionSLAsServiceProxy,
        ApiServiceProxies.BrandCustomerRegionsServiceProxy,
        ApiServiceProxies.MiscCostChargeTypesServiceProxy,
        ApiServiceProxies.MiscChargesServiceProxy,
        ApiServiceProxies.CrossBordersServiceProxy,
        ApiServiceProxies.LoadsServiceProxy,
        ApiServiceProxies.BarcodeScanHistoriesServiceProxy,
        ApiServiceProxies.PackageStatusServiceProxy,
        ApiServiceProxies.OrdersServiceProxy,
        ApiServiceProxies.PackageWeightsServiceProxy,
        ApiServiceProxies.ZenDeskFieldsServiceProxy,
        ApiServiceProxies.TerritoryConfigurationsServiceProxy,
        ApiServiceProxies.DeliveryDateExclusionsServiceProxy,
        ApiServiceProxies.CouriersServiceProxy,
        ApiServiceProxies.EventDetailsServiceProxy,
        ApiServiceProxies.EventGroupsServiceProxy,
        ApiServiceProxies.DepotLocationsServiceProxy,
        ApiServiceProxies.PackagesServiceProxy,
        ApiServiceProxies.PackageEventsServiceProxy,
        ApiServiceProxies.PackageHistoryServiceProxy,
        ApiServiceProxies.DeliveryTypeSettingsServiceProxy,
        ApiServiceProxies.LocationSettingsServiceProxy,
        ApiServiceProxies.CarriersServiceProxy,
        ApiServiceProxies.LocationDetailsServiceProxy,
        ApiServiceProxies.DepotToDepotSettingsServiceProxy,
        ApiServiceProxies.AppConfigurationLookupServiceProxy,
        ApiServiceProxies.LocationsServiceProxy,
        ApiServiceProxies.ConsignmentsServiceProxy,
        ApiServiceProxies.ConsignmentsOnForwardServiceProxy,
        ApiServiceProxies.ConsignmentsPrintLabelsServiceProxy,
        ApiServiceProxies.ConsignmentsExportForRoutingServiceProxy,
        ApiServiceProxies.ConsignmentsRescheduleServiceProxy,
        ApiServiceProxies.ConsignmentsExportToExcelServiceProxy,
        ApiServiceProxies.ConsignmentsChangeStatusServiceProxy,
        ApiServiceProxies.ConsignmentsChangeCarrierServiceProxy,
        ApiServiceProxies.ConsignmentsSendCommunicationServiceProxy,
        ApiServiceProxies.ManifestFileRecordsServiceProxy,
        ApiServiceProxies.ManifestFilesServiceProxy,
        ApiServiceProxies.ClientsServiceProxy,
        ApiServiceProxies.ClientGroupsServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.FinanceServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.FileOperationsServiceProxy,
        ApiServiceProxies.WebhookEventServiceProxy,
        ApiServiceProxies.WebhookSubscriptionServiceProxy,
        ApiServiceProxies.WebhookSendAttemptServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
