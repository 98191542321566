import { Component, Injector, OnInit } from '@angular/core';
import { DashboardConsignmentService } from '@app/shared/common/dashboard-service/dashboard-consignment.service';
import { IDashboardFilters } from '../../models/IDashboardFilters';
import { AllCarriesSummaryTable } from '../../models/all-carries-summary-table';
import { WidgetComponentBase } from '../widget-component-base';
import { ConsignmentStatusDashboardServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-widget-all-carriers-summary',
  templateUrl: './widget-all-carriers-summary.component.html',
  styleUrls: ['./widget-all-carriers-summary.component.less']
})
export class WidgetAllCarriesSummaryComponent extends WidgetComponentBase implements OnInit {
  allCarriesSummaryTable: AllCarriesSummaryTable;
  constructor(
    injector: Injector,
    private dataService: ConsignmentStatusDashboardServiceProxy,
    private dashboardConsignmentService: DashboardConsignmentService
  ) {
    super(injector);
    this.allCarriesSummaryTable = new AllCarriesSummaryTable(this.dataService);
  }


  ngOnInit() {
    this.allCarriesSummaryTable.reload();
  }

  fieldClick(filter: IDashboardFilters) {
    this.dashboardConsignmentService.navigate(filter);
  }
}
