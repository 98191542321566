import { Directive, ElementRef, Input, OnInit, AfterViewInit, Output } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import * as _ from 'lodash';

@Directive({
    selector: '[buttonBusy]'
})
export class ButtonBusyDirective implements OnInit, AfterViewInit {

    @Input() set buttonBusy(buttonBusy: boolean | ButtonBusy) {
        if (typeof buttonBusy === 'boolean') {
            this.refreshState(buttonBusy);
        } else {
            this.refreshState(buttonBusy.isBusy, buttonBusy.isButtonDisabled);
        }
    }
    @Input() set busyText(busyText: string) {
        this._busyText = busyText;
    }

    _busyText: string;
    private _button: any;
    private _originalButtonInnerHtml: any;

    constructor(
        private _element: ElementRef,
        private _appLocalizationService: AppLocalizationService
    ) {}

    ngOnInit(): void {
        this._button = this._element.nativeElement;
    }

    ngAfterViewInit(): void {
        this._originalButtonInnerHtml = this._button.innerHTML;
    }

    refreshState(isBusy: boolean, isButtonDisabled: boolean = false): void {
        if (!this._button) {
            return;
        }

        if (isBusy) {
            // disable button
            this._button.disabled = true;

            this._button.innerHTML = '<i class="fa fa-spin fa-spinner"></i>' +
                '<span>' + (this._busyText ? this._busyText : this._appLocalizationService.l('ProcessingWithThreeDot')) + '</span>';

            this._button.setAttribute('_disabledBefore', true);
        } else {
            if (!this._button.getAttribute('_disabledBefore')) {
                return;
            }

            // enable button (if not disabled by parent)
            this._button.disabled = isButtonDisabled;
            this._button.innerHTML = this._originalButtonInnerHtml;
        }
    }
}

interface ButtonBusy {
    isBusy: boolean;
    isButtonDisabled: boolean;
}
