<p-multiSelect [options]="options" [(ngModel)]="selectedItems" (onPanelHide)="onPanelHide()"
    [inputId]="name" [defaultLabel]="placeholder" [virtualScroll]="true" [itemSize]="30"
    [name]="name" [optionLabel]="'name'" [dropdownIcon]="'pi pi-caret-down'">
    <ng-template let-value pTemplate="selectedItems">
        <ng-container *ngIf="!!value && value.length > 0">
            <ng-container *ngFor="let val of value; let i = index">
                <div *ngIf="i < 5"class="ui-multiselect-token">
                    <span class="ui-multiselect-token-label" >{{val.name}}</span>
                </div>
            </ng-container>
            <div class="ui-multiselect-token" *ngIf="value.length > 5">
                <span class="ui-multiselect-token-label">Plus {{value.length - 5}} more</span>
            </div>
        </ng-container>
        <span *ngIf="!value || value.length === 0" class="ui-multiselected-empty-token ui-corner-all">{{placeholder}}</span>
    </ng-template>
    <p-header [class.multiselect-loading]="!options.length">
        <div *ngIf="!options.length">Loading {{placeholder}} items...</div>
    </p-header>
    <p-footer>
        <a href="javascript:void()" (click)="selectedItems = []">Clear selection</a>
    </p-footer>
</p-multiSelect>