import { UtilsService } from '@abp/utils/utils.service';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { AppConsts } from '@shared/AppConsts';
import { SubdomainTenancyNameFinder } from '@shared/helpers/SubdomainTenancyNameFinder';
import * as moment from 'moment';
import * as _ from 'lodash';
import { UrlHelper } from './shared/helpers/UrlHelper';
import { XmlHttpRequestHelper } from '@shared/helpers/XmlHttpRequestHelper';
import { DynamicResourcesHelper } from '@shared/helpers/DynamicResourcesHelper';
import { environment } from './environments/environment';
import { LocaleMappingService } from '@shared/locale-mapping.service';
var AppPreBootstrap = /** @class */ (function () {
    function AppPreBootstrap() {
    }
    AppPreBootstrap.run = function (appRootUrl, callback, resolve, reject) {
        AppPreBootstrap.getApplicationConfig(appRootUrl, function () {
            if (UrlHelper.isInstallUrl(location.href)) {
                AppPreBootstrap.loadAssetsForInstallPage(callback);
                return;
            }
            var queryStringObj = UrlHelper.getQueryParameters();
            if (queryStringObj.redirect && queryStringObj.redirect === 'TenantRegistration') {
                if (queryStringObj.forceNewRegistration) {
                    new AppAuthService().logout();
                }
                location.href = AppConsts.appBaseUrl + '/account/select-edition';
            }
            else if (queryStringObj.impersonationToken) {
                AppPreBootstrap.impersonatedAuthenticate(queryStringObj.impersonationToken, queryStringObj.tenantId, function () { AppPreBootstrap.getUserConfiguration(callback); });
            }
            else if (queryStringObj.switchAccountToken) {
                AppPreBootstrap.linkedAccountAuthenticate(queryStringObj.switchAccountToken, queryStringObj.tenantId, function () { AppPreBootstrap.getUserConfiguration(callback); });
            }
            else {
                AppPreBootstrap.getUserConfiguration(callback);
            }
        });
    };
    AppPreBootstrap.bootstrap = function (moduleType, compilerOptions) {
        return platformBrowserDynamic().bootstrapModule(moduleType, compilerOptions);
    };
    AppPreBootstrap.getApplicationConfig = function (appRootUrl, callback) {
        var type = 'GET';
        var url = appRootUrl + 'assets/' + environment.appConfig;
        var customHeaders = [
            {
                name: abp.multiTenancy.tenantIdCookieName,
                value: abp.multiTenancy.getTenantIdCookie() + ''
            }
        ];
        XmlHttpRequestHelper.ajax(type, url, customHeaders, null, function (result) {
            var subdomainTenancyNameFinder = new SubdomainTenancyNameFinder();
            var tenancyName = subdomainTenancyNameFinder.getCurrentTenancyNameOrNull(result.appBaseUrl);
            AppConsts.appBaseUrlFormat = result.appBaseUrl;
            AppConsts.remoteServiceBaseUrlFormat = result.remoteServiceBaseUrl;
            AppConsts.instrumentationKey = result.instrumentationKey;
            AppConsts.localeMappings = result.localeMappings;
            if (tenancyName == null) {
                AppConsts.appBaseUrl = result.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
                AppConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl + '.', '');
            }
            else {
                AppConsts.appBaseUrl = result.appBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl, tenancyName);
                AppConsts.remoteServiceBaseUrl = result.remoteServiceBaseUrl.replace(AppConsts.tenancyNamePlaceHolderInUrl, tenancyName);
            }
            callback();
        });
    };
    AppPreBootstrap.getCurrentClockProvider = function (currentProviderName) {
        if (currentProviderName === 'unspecifiedClockProvider') {
            return abp.timing.unspecifiedClockProvider;
        }
        if (currentProviderName === 'utcClockProvider') {
            return abp.timing.utcClockProvider;
        }
        return abp.timing.localClockProvider;
    };
    AppPreBootstrap.impersonatedAuthenticate = function (impersonationToken, tenantId, callback) {
        var _a;
        abp.multiTenancy.setTenantIdCookie(tenantId);
        var cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
        var requestHeaders = (_a = {
                '.AspNetCore.Culture': ('c=' + cookieLangValue + '|uic=' + cookieLangValue)
            },
            _a[abp.multiTenancy.tenantIdCookieName] = abp.multiTenancy.getTenantIdCookie(),
            _a);
        XmlHttpRequestHelper.ajax('POST', AppConsts.remoteServiceBaseUrl + '/api/TokenAuth/ImpersonatedAuthenticate?impersonationToken=' + impersonationToken, requestHeaders, null, function (response) {
            var result = response.result;
            abp.auth.setToken(result.accessToken);
            AppPreBootstrap.setEncryptedTokenCookie(result.encryptedAccessToken);
            location.search = '';
            callback();
        });
    };
    AppPreBootstrap.linkedAccountAuthenticate = function (switchAccountToken, tenantId, callback) {
        var _a;
        abp.multiTenancy.setTenantIdCookie(tenantId);
        var cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
        var requestHeaders = (_a = {
                '.AspNetCore.Culture': ('c=' + cookieLangValue + '|uic=' + cookieLangValue)
            },
            _a[abp.multiTenancy.tenantIdCookieName] = abp.multiTenancy.getTenantIdCookie(),
            _a);
        XmlHttpRequestHelper.ajax('POST', AppConsts.remoteServiceBaseUrl + '/api/TokenAuth/LinkedAccountAuthenticate?switchAccountToken=' + switchAccountToken, requestHeaders, null, function (response) {
            var result = response.result;
            abp.auth.setToken(result.accessToken);
            AppPreBootstrap.setEncryptedTokenCookie(result.encryptedAccessToken);
            location.search = '';
            callback();
        });
    };
    AppPreBootstrap.getUserConfiguration = function (callback) {
        var _this = this;
        var _a;
        var cookieLangValue = abp.utils.getCookieValue('Abp.Localization.CultureName');
        var token = abp.auth.getToken();
        var requestHeaders = (_a = {
                '.AspNetCore.Culture': ('c=' + cookieLangValue + '|uic=' + cookieLangValue)
            },
            _a[abp.multiTenancy.tenantIdCookieName] = abp.multiTenancy.getTenantIdCookie(),
            _a);
        if (token) {
            requestHeaders['Authorization'] = 'Bearer ' + token;
        }
        return XmlHttpRequestHelper.ajax('GET', AppConsts.remoteServiceBaseUrl + '/AbpUserConfiguration/GetAll', requestHeaders, null, function (response) {
            var result = response.result;
            _.merge(abp, result);
            abp.clock.provider = _this.getCurrentClockProvider(result.clock.provider);
            AppPreBootstrap.configureMoment();
            abp.event.trigger('abp.dynamicScriptsInitialized');
            AppConsts.recaptchaSiteKey = abp.setting.get('Recaptcha.SiteKey');
            AppConsts.subscriptionExpireNootifyDayCount = parseInt(abp.setting.get('App.TenantManagement.SubscriptionExpireNotifyDayCount'));
            DynamicResourcesHelper.loadResources(callback);
        });
    };
    AppPreBootstrap.configureMoment = function () {
        moment.locale(new LocaleMappingService().map('moment', abp.localization.currentLanguage.name));
        window.moment.locale(new LocaleMappingService().map('moment', abp.localization.currentLanguage.name));
        if (abp.clock.provider.supportsMultipleTimezone) {
            moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
            window.moment.tz.setDefault(abp.timing.timeZoneInfo.iana.timeZoneId);
        }
        else {
            moment.fn.toJSON = function () {
                return this.format();
            };
            moment.fn.toISOString = function () {
                return this.format();
            };
        }
    };
    AppPreBootstrap.setEncryptedTokenCookie = function (encryptedToken) {
        new UtilsService().setCookieValue(AppConsts.authorization.encrptedAuthTokenName, encryptedToken, new Date(new Date().getTime() + 365 * 86400000), //1 year
        abp.appPath);
    };
    AppPreBootstrap.loadAssetsForInstallPage = function (callback) {
        abp.setting.values['App.UiManagement.Theme'] = 'default';
        abp.setting.values['default.App.UiManagement.ThemeColor'] = 'default';
        DynamicResourcesHelper.loadResources(callback);
    };
    return AppPreBootstrap;
}());
export { AppPreBootstrap };
