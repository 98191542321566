import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation } from '@angular/core';
import { NameValueOfString } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConfigurationService } from '@app/shared/services/appconfigration.service';

@Component({
    selector: 'brandsMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './brand-multiselect.component.html'
})

export class BrandMultiSelectComponent extends AppComponentBase implements OnInit {

    @Input()
    set selectedItems(file: NameValueOfString[]) {
        this._selectedItems = file || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfString[] {
        return this._selectedItems;
    }
    @Input() name = 'BrandFilter';
    @Output() selectedItemsChange: EventEmitter<NameValueOfString[]> = new EventEmitter<NameValueOfString[]>();

    placeholder = this.l('Brand');

    options: NameValueOfString[] = new Array<NameValueOfString>();

    private _selectedItems: NameValueOfString[] = [];

    constructor(injector: Injector, private _appConfigurationService: AppConfigurationService) {
       super(injector)
    }

    ngOnInit() {
        this._appConfigurationService.getBrands().subscribe(brands => this.options = brands);
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}