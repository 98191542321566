var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { curveBasis } from 'd3-shape';
import { WidgetComponentBase } from '../widget-component-base';
var RegionalStatsTable = /** @class */ (function (_super) {
    __extends(RegionalStatsTable, _super);
    function RegionalStatsTable(_dashboardService) {
        var _this = _super.call(this) || this;
        _this._dashboardService = _dashboardService;
        _this.colors = ['#00c5dc', '#f4516c', '#34bfa3', '#ffb822'];
        _this.customColors = [
            { name: '1', value: '#00c5dc' },
            { name: '2', value: '#f4516c' },
            { name: '3', value: '#34bfa3' },
            { name: '4', value: '#ffb822' },
            { name: '5', value: '#00c5dc' }
        ];
        _this.curve = curveBasis;
        return _this;
    }
    RegionalStatsTable.prototype.init = function () {
        this.reload();
    };
    RegionalStatsTable.prototype.formatData = function () {
        for (var j = 0; j < this.stats.length; j++) {
            var stat = this.stats[j];
            var series = [];
            for (var i = 0; i < stat.change.length; i++) {
                series.push({
                    name: i + 1,
                    value: stat.change[i]
                });
            }
            stat.changeData = [
                {
                    'name': j + 1,
                    'series': series
                }
            ];
        }
    };
    RegionalStatsTable.prototype.reload = function () {
        var _this = this;
        this.showLoading();
        this._dashboardService
            .getRegionalStats()
            .subscribe(function (result) {
            _this.stats = result.stats;
            _this.formatData();
            _this.hideLoading();
        });
    };
    return RegionalStatsTable;
}(DashboardChartBase));
var WidgetRegionalStatsComponent = /** @class */ (function (_super) {
    __extends(WidgetRegionalStatsComponent, _super);
    function WidgetRegionalStatsComponent(injector, _dashboardService) {
        var _this = _super.call(this, injector) || this;
        _this._dashboardService = _dashboardService;
        _this.regionalStatsTable = new RegionalStatsTable(_this._dashboardService);
        return _this;
    }
    WidgetRegionalStatsComponent.prototype.ngOnInit = function () {
        this.regionalStatsTable.init();
    };
    return WidgetRegionalStatsComponent;
}(WidgetComponentBase));
export { WidgetRegionalStatsComponent };
