var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, EventEmitter, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConfigurationService } from '@app/shared/services/appconfigration.service';
var TerritoryConfigurationMultiSelectComponent = /** @class */ (function (_super) {
    __extends(TerritoryConfigurationMultiSelectComponent, _super);
    function TerritoryConfigurationMultiSelectComponent(injector, _appConfigurationService) {
        var _this = _super.call(this, injector) || this;
        _this._appConfigurationService = _appConfigurationService;
        _this.name = 'territoryConfigurationMultiselect';
        _this.selectedItemsChange = new EventEmitter();
        _this.options = new Array();
        _this._selectedItems = [];
        _this.placeholder = _this.l('Territory');
        return _this;
    }
    Object.defineProperty(TerritoryConfigurationMultiSelectComponent.prototype, "selectedItems", {
        get: function () {
            return this._selectedItems;
        },
        set: function (items) {
            this._selectedItems = items || [];
            this.selectedItemsChange.emit(this._selectedItems);
        },
        enumerable: true,
        configurable: true
    });
    ;
    TerritoryConfigurationMultiSelectComponent.prototype.ngOnInit = function () {
        if (this.selectedItems.length) {
            this.getTerritoryConfigurations();
        }
    };
    TerritoryConfigurationMultiSelectComponent.prototype.getTerritoryConfigurations = function () {
        var _this = this;
        this._appConfigurationService.getTerritoryConfigurations()
            .subscribe(function (territoryConfigurations) {
            _this.options = territoryConfigurations;
        });
    };
    TerritoryConfigurationMultiSelectComponent.prototype.onPanelShow = function () {
        if (!this.options.length) {
            this.getTerritoryConfigurations();
        }
    };
    TerritoryConfigurationMultiSelectComponent.prototype.onPanelHide = function () {
        document.getElementById(this.name).focus();
    };
    return TerritoryConfigurationMultiSelectComponent;
}(AppComponentBase));
export { TerritoryConfigurationMultiSelectComponent };
