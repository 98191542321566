import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation, OnChanges } from '@angular/core';
import { NameValueOfInt32, AppConfigurationLookupServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConfigurationService } from '@app/shared/services/appconfigration.service';

@Component({
    selector: 'carrierMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './carrier-multiselect.component.html'
})

export class CarrierMultiSelectComponent extends AppComponentBase implements OnInit {

    @Input()
    set selectedItems(file: NameValueOfInt32[]) {
        this._selectedItems = file || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfInt32[] {
        return this._selectedItems;
    }
    @Input() isDriver: boolean;
    @Input() hasD365Entity: boolean;
    @Input() depotIds: Array<string> = [];
    @Input() name = 'CarrierCodeFilter';
    @Output() selectedItemsChange: EventEmitter<NameValueOfInt32[]> = new EventEmitter<NameValueOfInt32[]>();

    placeholder = this.l('CarrierCode');

    options: NameValueOfInt32[] = new Array<NameValueOfInt32>();

    private _selectedItems: NameValueOfInt32[] = [];

    constructor(injector: Injector,
        private _appConfigurationService: AppConfigurationService,
        private _appConfigurationLookupService: AppConfigurationLookupServiceProxy) {
       super(injector)
    }

    ngOnInit() {
        this.getCarriers();
    }

    getCarriers() {
        if (!!this.isDriver || !!this.hasD365Entity || this.depotIds.length > 0 ) {
            this._appConfigurationLookupService.getCarriers('', this.depotIds, this.isDriver, this.hasD365Entity)
                .subscribe(carriers => this.options = carriers);
        } else {
            this._appConfigurationService.getCarriers().subscribe(carriers => this.options = carriers);
        }
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}