
        <input
            *ngIf="this.schema.widget.id === 'hidden'; else notHiddenFieldBlock"
            [attr.name]="name"
            type="hidden"
            [formControl]="control"
        />
        <ng-template #notHiddenFieldBlock>
            <div class="widget form-group kt-form__group row">
                <label
                    [attr.for]="id"
                    class="horizontal control-label col-lg-3"
                >
                    {{ schema.title }}
                    <span *ngIf="schema.isRequired" class="m--font-danger"
                        >*</span
                    >
                </label>
                <div class="col-lg-9">
                    <input
                        [name]="name"
                        [attr.id]="name"
                        [attr.readonly]="
                            schema.widget.id !== 'color' && schema.readOnly
                                ? true
                                : null
                        "
                        class="text-widget.id textline-widget form-control"
                        [attr.type]="
                            !this.schema.widget.id ||
                            this.schema.widget.id === 'string'
                                ? 'text'
                                : this.schema.widget.id
                        "
                        [attr.id]="id"
                        [formControl]="control"
                        [attr.placeholder]="schema.placeholder"
                        [attr.maxLength]="schema.maxLength || null"
                        [attr.minLength]="schema.minLength || null"
                        [attr.required]="schema.isRequired || null"
                        [attr.disabled]="
                            schema.widget.id == 'color' && schema.readOnly
                                ? true
                                : null
                        "
                    />
                    <input
                        *ngIf="schema.widget.id === 'color' && schema.readOnly"
                        [attr.id]="name"
                        [attr.name]="name"
                        type="hidden"
                        [formControl]="control"
                    />
                    <span *ngIf="schema.description" class="formHelp">{{
                        schema.description
                    }}</span>
                    <div
                        class="has-danger"
                        *ngIf="control.invalid && control.errors && control.errors.length"
                    >
                        <div class="form-control-feedback">
                            {{ control.errors[0].message | localize }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    