var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { EventEmitter, Injector, ElementRef, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
var BigInputComponent = /** @class */ (function (_super) {
    __extends(BigInputComponent, _super);
    function BigInputComponent(injector, _changeDetector) {
        var _this = _super.call(this, injector) || this;
        _this._changeDetector = _changeDetector;
        _this.labelResourceKey = null;
        _this.onChange = new EventEmitter();
        _this._input = null;
        _this.bigInputSelected = false;
        _this.isMultipleShown = false;
        _this.inputCount = 0;
        return _this;
    }
    Object.defineProperty(BigInputComponent.prototype, "input", {
        get: function () {
            return this._input;
        },
        set: function (input) {
            if (!input && this.inputCount > 1) {
                this.bigInputSelected = false;
            }
            this._input = input;
        },
        enumerable: true,
        configurable: true
    });
    BigInputComponent.prototype.ngOnInit = function () {
        if (!!this._input) {
            this.inputCount = this.input.toString().split(',').length;
            if (this.inputCount > 1) {
                this.bigInputSelected = true;
                this._changeDetector.detectChanges();
            }
        }
    };
    BigInputComponent.prototype.onInputTextChange = function (event) {
        this.onInputChange(event.target.value);
    };
    BigInputComponent.prototype.clearBigInputSelected = function () {
        this.input = null;
        this.bigInputSelected = false;
        this.onInputChange(this.input);
    };
    BigInputComponent.prototype.show = function () {
        this.modal.show();
    };
    BigInputComponent.prototype.close = function () {
        this.modal.hide();
    };
    BigInputComponent.prototype.ok = function () {
        this.close();
        if (!!this.input) {
            this.bigInputSelected = true;
        }
        this.onInputChange(this.input);
    };
    BigInputComponent.prototype.onInputChange = function (input) {
        this.invalidNumbers = false;
        if (!!input) {
            input = input.replace(/(\r\n|\n|\r)+/gm, ',').replace(/(\s)/gm, '').replace(/(,)+/gm, ',').replace(/^,|,$/gm, '');
            this.inputCount = input.toString().split(',').length;
        }
        if (!!this.validateNumbers && !!input) {
            this.invalidNumbers = input.toString().split(',').some(function (e) { return isNaN(Number(e)); });
        }
        if (this.invalidNumbers) {
            return;
        }
        this.onChange.emit(input);
    };
    BigInputComponent.prototype.shown = function () {
        this.isMultipleShown = true;
    };
    BigInputComponent.prototype.hidden = function () {
        this.isMultipleShown = false;
    };
    return BigInputComponent;
}(AppComponentBase));
export { BigInputComponent };
