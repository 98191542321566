import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation, OnChanges } from '@angular/core';
import { NameValueOfString } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConfigurationService } from '@app/shared/services/appconfigration.service';

@Component({
    selector: 'depotLocationMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './depotlocation-multiselect.component.html'
})

export class DepotLocationMultiSelectComponent extends AppComponentBase implements OnInit, OnChanges {

    @Input()
    set selectedItems(items: NameValueOfString[]) {
        this._selectedItems = items || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfString[] {
        return this._selectedItems;
    }
    @Input() fromDepotFilter: string;
    @Input() name = 'depotLocation';
    @Output() selectedItemsChange: EventEmitter<NameValueOfString[]> = new EventEmitter<NameValueOfString[]>();

    placeholder = this.l('DepotLocation');

    options: NameValueOfString[] = new Array<NameValueOfString>();

    private _selectedItems: NameValueOfString[] = [];

    constructor(injector: Injector, private _appConfigurationService: AppConfigurationService) {
       super(injector)
    }

    ngOnInit() {
        this.getDepotLocations();
    }

    ngOnChanges() {
        if (!!this.fromDepotFilter) {
            this.options = this.options.filter(
                e =>
                    e.value.toLowerCase() !== this.fromDepotFilter.toLowerCase()
            );
        }
    }

    getDepotLocations() {
        this._appConfigurationService.getDepotLocations()
            .subscribe(res => this.options = res.map((r: NameValueOfString) => <NameValueOfString>{name: `${r.name} (${r.value})`, value: r.value}));
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}