import { Component } from "@angular/core";

import { ObjectWidget } from "ngx-schema-form";

@Component({
    selector: "sf-form-object",
    template: `
        <div *ngFor="let fieldset of formProperty.schema.fieldsets"   class="m-portlet m-portlet--default" >
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title">
                        <h3
                            class="m-portlet__head-text m--font-brand"
                            *ngIf="fieldset.title"
                        >
                            {{ fieldset.title }}
                        </h3>
                    </div>
                </div>
                <div *ngIf="fieldset.description">
                    {{ fieldset.description }}
                </div>
            </div>
            <div class="m-portlet__body">
            <div
            class="has-danger"
            *ngIf="fieldset.errorMessages"
        >
            <div *ngFor="let error of fieldset.errorMessages">
                <div class="form-control-feedback">
                    {{ error | localize }}
                </div>
            </div>
        </div>
                <div *ngFor="let fieldId of fieldset.fields">
                    <sf-form-element
                        [formProperty]="formProperty.getProperty(fieldId)"
                    ></sf-form-element>
                </div>
            </div>
        </div>
    `
})
export class MetronicsObjectComponent extends ObjectWidget {}
