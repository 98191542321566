import * as moment from 'moment';

export class HtmlHelper {

    static encodeText(value: string): string {
        let div = document.createElement('div');
        div[('textContent' in div) ? 'textContent' : 'innerText'] = value;
        return div.innerHTML;

    }

    static decodeText(value: string): string {
        let div = document.createElement('div') as any;
        div.innerHTML = value;
        return ('textContent' in div) ? div.textContent : div.innerText;
    }

    static encodeJson(jsonObject: object): string {
        return JSON.parse(this.encodeText(JSON.stringify(jsonObject)));
    }

    static decodeJson(jsonObject: object): string {
        return JSON.parse(this.decodeText(JSON.stringify(jsonObject)));
    }

    static isJson(jsonString: string): boolean {
        try {
            JSON.parse(jsonString);
        } catch (e) {
            return false;
        }
        return true;
    }

    static DatesAreSameTime(date1: Date, date2: Date): boolean {
        return date1.getTime() === date2.getTime();
    }

    static getChargePeriod(dt = moment()): [moment.Moment, moment.Moment] {
        let periodStartDate = moment(dt);
        while (periodStartDate.format('dddd') !== 'Monday') { periodStartDate.add(-1, 'day'); }
        const periodEndDate = moment(periodStartDate).add(6, 'day');
        return [periodStartDate, periodEndDate];
    }

    static getValidChargeDates(dt = moment()): [moment.Moment, moment.Moment] {
        let periodStartDate = moment(dt);
        if (dt.format('dddd') === 'Monday') { periodStartDate.add(-7, 'day'); }
        while (periodStartDate.format('dddd') !== 'Monday') { periodStartDate.add(-1, 'day'); }

        const periodEndDate = moment(periodStartDate).add(6, 'day');
        return [periodStartDate, periodEndDate];
    }
}
