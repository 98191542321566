import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { FileDto, FileOperationsServiceProxy } from '@shared/service-proxies/service-proxies';
import {map} from 'rxjs/operators'
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Observable } from 'rxjs';

@Injectable()
export class FileDownloadService extends AppComponentBase {

    constructor(private _httpClient: HttpClient, injector: Injector,
        private _activatedRoute: ActivatedRoute, 
        private _router: Router, 
        private _fileOperationService: FileOperationsServiceProxy) {
        super(injector);
    }

    downloadTempFile(file: FileDto) {
        const url = AppConsts.remoteServiceBaseUrl + '/File/DownloadTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
        location.href = url; //TODO: This causes reloading of same page in Firefox
    }

    downloadOnly(file: FileDto): any {
        const url = AppConsts.remoteServiceBaseUrl + '/File/DownloadTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
        return this._httpClient.get(url, { responseType: 'blob'})
                .pipe(map(res => {
                return new Blob([res], { type: 'application/pdf', });
            }));
      }

      downloadPodImage(fileName: string): Observable<Blob> {
        const url = AppConsts.remoteServiceBaseUrl + '/File/DownloadPodImage?podIdentifierInFred=' + fileName;
        return this._httpClient.get(url, { responseType: 'blob' });
      }

      downloadBlobFile(permission: string, fileName: string): void {
        this._fileOperationService.getFileContentByPermission(permission, fileName)
            .subscribe(result => {
                if (result) {
                    this.downloadTempFile(result);
                    } else {
                        this.message.error(this.l('RequestedFileDoesNotExists'));
                    }
                },
                () => {
                    console.log('Error downloading the file.');
                },
                () => {
                    this._router.navigate([], { relativeTo: this._activatedRoute });
                });
    }

    convertBase64StringtoUint8(data: string) {
        var rawData = window.atob(data);
        var datalength = rawData.length;
        var byteArray = new Uint8Array(new ArrayBuffer(datalength));
    
        for(var i = 0; i < datalength; i++) {
            byteArray[i] = rawData.charCodeAt(i);
        }
        return byteArray;
    }
}
