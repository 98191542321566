import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation, OnChanges } from '@angular/core';
import { NameValueOfString, AppConfigurationLookupServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConfigurationService } from '@app/shared/services/appconfigration.service';

@Component({
    selector: 'palletisedFilterMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './palletised-multiselect.component.html'
})

export class PalletisedFilterMultiSelectComponent extends AppComponentBase implements OnInit {

    @Input()
    set selectedItems(file: NameValueOfString[]) {
        this._selectedItems = file || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfString[] {
        return this._selectedItems;
    }

    @Input() name = 'PalletisedFilter';
    @Output() selectedItemsChange: EventEmitter<NameValueOfString[]> = new EventEmitter<NameValueOfString[]>();

    placeholder = this.l('PalletisedFilter');

    options: NameValueOfString[] = new Array<NameValueOfString>();

    private _selectedItems: NameValueOfString[] = [];

    constructor(injector: Injector,
        private _appConfigurationService: AppConfigurationService,
        private _appConfigurationLookupService: AppConfigurationLookupServiceProxy) {
       super(injector)
    }

    ngOnInit() {
        this.getFilterValue();
    }

    getFilterValue() {
        this.options.push(new NameValueOfString({name: 'Palletised', value: 'true'}),
                new NameValueOfString({name:'Non Palletised', value:'false'}));
        
    //this._appConfigurationService.getPalletisedSelection().subscribe(filters => this.options = filters);
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}