/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-date-range-picker.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/utils/moment-format.pipe";
import * as i3 from "@angular/forms";
import * as i4 from "ngx-bootstrap/datepicker";
import * as i5 from "ngx-bootstrap/component-loader";
import * as i6 from "../../../../../../shared/utils/date-range-picker-moment-modifier.directive";
import * as i7 from "./filter-date-range-picker.component";
var styles_FilterDateRangePickerComponent = [i0.styles];
var RenderType_FilterDateRangePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterDateRangePickerComponent, data: {} });
export { RenderType_FilterDateRangePickerComponent as RenderType_FilterDateRangePickerComponent };
export function View_FilterDateRangePickerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.MomentFormatPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 8, "input", [["bsDaterangepicker", ""], ["class", "form-control  date-range-filter"], ["dateRangePickerMomentModifier", ""], ["disabled", "disabled"], ["placement", "bottom"], ["type", "text"]], [[8, "value", 0]], [[null, "dateChange"], [null, "change"], [null, "keyup.esc"], [null, "keydown"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("keyup.esc" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).hide() !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8).onKeydownEvent($event) !== false);
        ad = (pd_2 && ad);
    } if (("blur" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8).onBlur() !== false);
        ad = (pd_3 && ad);
    } if (("dateChange" === en)) {
        var pd_4 = ((_co.selectedDateRange = $event) !== false);
        ad = (pd_4 && ad);
    } if (("dateChange" === en)) {
        var pd_5 = (_co.onChange() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.BsDaterangepickerInputDirective]), i1.ɵprd(5120, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.BsDaterangepickerInputDirective]), i1.ɵdid(5, 4931584, [["dateRangePicker", 4]], 0, i4.BsDaterangepickerDirective, [i4.BsDaterangepickerConfig, i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i5.ComponentLoaderFactory], { placement: [0, "placement"], bsConfig: [1, "bsConfig"] }, null), i1.ɵpod(6, { customTodayClass: 0 }), i1.ɵdid(7, 671744, null, 0, i6.DateRangePickerMomentModifierDirective, [[4, i4.BsDaterangepickerDirective]], { date: [0, "date"] }, { dateChange: "dateChange" }), i1.ɵdid(8, 16384, null, 0, i4.BsDaterangepickerInputDirective, [i4.BsDaterangepickerDirective, i4.BsLocaleService, i1.Renderer2, i1.ElementRef, i1.ChangeDetectorRef], null, null), i1.ɵppd(9, 2), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).toggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-calendar-day p-0"], ["style", "color:white;"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "bottom"; var currVal_2 = _ck(_v, 6, 0, "datePicker-highlight-current-day"); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.selectedDateRange; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "", i1.ɵunv(_v, 2, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.selectedDateRange[0], "LL")), " - ", i1.ɵunv(_v, 2, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.selectedDateRange[1], "LL")), ""); _ck(_v, 2, 0, currVal_0); }); }
export function View_FilterDateRangePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-filter-date-range-picker", [], null, null, null, View_FilterDateRangePickerComponent_0, RenderType_FilterDateRangePickerComponent)), i1.ɵdid(1, 49152, null, 0, i7.FilterDateRangePickerComponent, [i1.Injector], null, null)], null, null); }
var FilterDateRangePickerComponentNgFactory = i1.ɵccf("app-filter-date-range-picker", i7.FilterDateRangePickerComponent, View_FilterDateRangePickerComponent_Host_0, {}, {}, []);
export { FilterDateRangePickerComponentNgFactory as FilterDateRangePickerComponentNgFactory };
