export class DashboardCustomizationConst {
    static widgets = {
        tenant: {
            ordersSummary: 'Widgets_Tenant_OrdersSummary',
            edByStateSummary: 'Widgets_Tenant_EdByStateSummary',
            allCarriesSummary: 'Widgets_Tenant_AllCarriesSummary',
            consignmentStats: 'Widgets_Tenant_ConsignmentStats',
        },
        host: {
            topStats: 'Widgets_Host_TopStats',
            incomeStatistics: 'Widgets_Host_IncomeStatistics',
            editionStatistics: 'Widgets_Host_EditionStatistics',
            subscriptionExpiringTenants: 'Widgets_Host_SubscriptionExpiringTenants',
            recentTenants: 'Widgets_Host_RecentTenants'
        }
    };
    static filters = {
        filterDateRangePicker: 'Filters_DateRangePicker'
    };
    static dashboardNames = {
        defaultTenantDashboard: 'TenantDashboard',
        defaultHostDashboard: 'HostDashboard',
    };
    static Applications = {
        Angular: 'Angular'
    };
}
