var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector } from '@angular/core';
import { Table } from 'primeng/table';
import { HostDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBase } from '../widget-component-base';
var WidgetSubscriptionExpiringTenantsComponent = /** @class */ (function (_super) {
    __extends(WidgetSubscriptionExpiringTenantsComponent, _super);
    function WidgetSubscriptionExpiringTenantsComponent(injector, _hostDashboardServiceProxy) {
        var _this = _super.call(this, injector) || this;
        _this._hostDashboardServiceProxy = _hostDashboardServiceProxy;
        _this.dataLoading = true;
        return _this;
    }
    WidgetSubscriptionExpiringTenantsComponent.prototype.ngOnInit = function () {
        this.getData();
    };
    WidgetSubscriptionExpiringTenantsComponent.prototype.getData = function () {
        var _this = this;
        this._hostDashboardServiceProxy.getSubscriptionExpiringTenantsData().subscribe(function (data) {
            _this.expiringTenantsData = data;
            _this.dataLoading = false;
        });
    };
    WidgetSubscriptionExpiringTenantsComponent.prototype.gotoAllExpiringTenants = function () {
        var url = abp.appPath +
            'app/admin/tenants?' +
            'subscriptionEndDateStart=' +
            encodeURIComponent(this.expiringTenantsData.subscriptionEndDateStart.format()) +
            '&' +
            'subscriptionEndDateEnd=' +
            encodeURIComponent(this.expiringTenantsData.subscriptionEndDateEnd.format());
        window.open(url);
    };
    return WidgetSubscriptionExpiringTenantsComponent;
}(WidgetComponentBase));
export { WidgetSubscriptionExpiringTenantsComponent };
