import { ModuleWithProviders } from '@angular/core';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TimepickerConfig } from 'ngx-bootstrap/timepicker';
import { TabsetConfig } from 'ngx-bootstrap/tabs';
import { NgxBootstrapDropdownConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-dropdown-config.service';
import { NgxBootstrapTabsetConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-tabset-config.service';
import { NgxBootstrapAccordionConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-accordion-config.service';
import { AccordionConfig } from 'ngx-bootstrap/accordion';
import { NgxBootstrapTimepickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-timepicker-config.service';
import { PopoverConfig } from 'ngx-bootstrap/popover';
import { NgxBootstrapPopoverConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-popover-config.service';
import { TooltipConfig } from 'ngx-bootstrap/tooltip';
import { NgxBootstrapTooltipConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-tooltip-config.service';
var ɵ0 = NgxBootstrapTooltipConfigService.getTooltipConfig, ɵ1 = NgxBootstrapPopoverConfigService.getPopoverConfig, ɵ2 = NgxBootstrapTimepickerConfigService.getTimepickerConfig, ɵ3 = NgxBootstrapAccordionConfigService.getAccordionConfig, ɵ4 = NgxBootstrapTabsetConfigService.getTabsetConfig, ɵ5 = NgxBootstrapDropdownConfigService.getBsDropdownConfig, ɵ6 = NgxBootstrapDatePickerConfigService.getDatepickerConfig, ɵ7 = NgxBootstrapDatePickerConfigService.getDaterangepickerConfig;
var AppCommonModule = /** @class */ (function () {
    function AppCommonModule() {
    }
    AppCommonModule.forRoot = function () {
        return {
            ngModule: AppCommonModule,
            providers: [
                AppAuthService,
                AppRouteGuard,
                { provide: TooltipConfig, useFactory: NgxBootstrapTooltipConfigService.getTooltipConfig },
                { provide: PopoverConfig, useFactory: NgxBootstrapPopoverConfigService.getPopoverConfig },
                { provide: TimepickerConfig, useFactory: NgxBootstrapTimepickerConfigService.getTimepickerConfig },
                { provide: AccordionConfig, useFactory: NgxBootstrapAccordionConfigService.getAccordionConfig },
                { provide: TabsetConfig, useFactory: NgxBootstrapTabsetConfigService.getTabsetConfig },
                { provide: BsDropdownConfig, useFactory: NgxBootstrapDropdownConfigService.getBsDropdownConfig },
                { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
                { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
                { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale },
            ]
        };
    };
    return AppCommonModule;
}());
export { AppCommonModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
