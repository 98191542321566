var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Injector, ElementRef, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppNavigationService } from './app-navigation.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
var SideBarMenuComponent = /** @class */ (function (_super) {
    __extends(SideBarMenuComponent, _super);
    function SideBarMenuComponent(injector, el, router, permission, _appNavigationService, document, render) {
        var _this = _super.call(this, injector) || this;
        _this.el = el;
        _this.permission = permission;
        _this._appNavigationService = _appNavigationService;
        _this.document = document;
        _this.render = render;
        _this.menu = null;
        _this.currentRouteUrl = '';
        _this.menuOptions = {
            // vertical scroll
            scroll: null,
            // submenu setup
            submenu: {
                desktop: {
                    default: 'dropdown',
                    state: {
                        body: 'kt-aside--minimize',
                        mode: 'dropdown'
                    }
                },
                tablet: 'accordion',
                mobile: 'accordion' // menu set to accordion in mobile mode
            },
            // accordion setup
            accordion: {
                expandAll: false // allow having multiple expanded accordions in the menu
            }
        };
        return _this;
    }
    SideBarMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.menu = this._appNavigationService.getMenu();
        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) { return _this.currentRouteUrl = _this.router.url.split(/[?#]/)[0]; });
    };
    SideBarMenuComponent.prototype.ngAfterViewInit = function () {
        this.scrollToCurrentMenuElement();
    };
    SideBarMenuComponent.prototype.showMenuItem = function (menuItem) {
        return this._appNavigationService.showMenuItem(menuItem);
    };
    SideBarMenuComponent.prototype.isMenuItemIsActive = function (item) {
        if (item.items.length) {
            return this.isMenuRootItemIsActive(item);
        }
        if (!item.route) {
            return false;
        }
        var urlTree = this.router.parseUrl(this.currentRouteUrl.replace(/\/$/, ''));
        var urlString = '/' + urlTree.root.children.primary.segments.map(function (segment) { return segment.path; }).join('/');
        return urlString === item.route.replace(/\/$/, '');
    };
    SideBarMenuComponent.prototype.isMenuRootItemIsActive = function (item) {
        var result = false;
        for (var _i = 0, _a = item.items; _i < _a.length; _i++) {
            var subItem = _a[_i];
            result = this.isMenuItemIsActive(subItem);
            if (result) {
                return true;
            }
        }
        return false;
    };
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     * @param e Event
     */
    SideBarMenuComponent.prototype.mouseEnter = function (e) {
        var _this = this;
        if (!this.currentTheme.baseSettings.menu.allowAsideMinimizing) {
            return;
        }
        // check if the left aside menu is fixed
        if (document.body.classList.contains('kt-aside--fixed')) {
            if (this.outsideTm) {
                clearTimeout(this.outsideTm);
                this.outsideTm = null;
            }
            this.insideTm = setTimeout(function () {
                // if the left aside menu is minimized
                if (document.body.classList.contains('kt-aside--minimize') && KTUtil.isInResponsiveRange('desktop')) {
                    // show the left aside menu
                    _this.render.removeClass(document.body, 'kt-aside--minimize');
                    _this.render.addClass(document.body, 'kt-aside--minimize-hover');
                }
            }, 50);
        }
    };
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     * @param e Event
     */
    SideBarMenuComponent.prototype.mouseLeave = function (e) {
        var _this = this;
        if (!this.currentTheme.baseSettings.menu.allowAsideMinimizing) {
            return;
        }
        if (document.body.classList.contains('kt-aside--fixed')) {
            if (this.insideTm) {
                clearTimeout(this.insideTm);
                this.insideTm = null;
            }
            this.outsideTm = setTimeout(function () {
                // if the left aside menu is expand
                if (document.body.classList.contains('kt-aside--minimize-hover') && KTUtil.isInResponsiveRange('desktop')) {
                    // hide back the left aside menu
                    _this.render.removeClass(document.body, 'kt-aside--minimize-hover');
                    _this.render.addClass(document.body, 'kt-aside--minimize');
                }
            }, 100);
        }
    };
    SideBarMenuComponent.prototype.scrollToCurrentMenuElement = function () {
        var path = location.pathname;
        var menuItem = document.querySelector('a[href=\'' + path + '\']');
        if (menuItem) {
            menuItem.scrollIntoView({ block: 'center' });
        }
    };
    return SideBarMenuComponent;
}(AppComponentBase));
export { SideBarMenuComponent };
