import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import * as _ from 'lodash';
var ErrorDef = /** @class */ (function () {
    function ErrorDef() {
    }
    return ErrorDef;
}());
var ValidationMessagesComponent = /** @class */ (function () {
    function ValidationMessagesComponent(appLocalizationService) {
        this.appLocalizationService = appLocalizationService;
        this._errorDefs = [];
        this.standartErrorDefs = [
            { error: 'required', localizationKey: 'ThisFieldIsRequired' },
            { error: 'minlength', localizationKey: 'PleaseEnterAtLeastNCharacter', errorProperty: 'requiredLength' },
            { error: 'maxlength', localizationKey: 'PleaseEnterNoMoreThanNCharacter', errorProperty: 'requiredLength' },
            { error: 'email', localizationKey: 'InvalidEmailAddress' },
            { error: 'pattern', localizationKey: 'InvalidPattern', errorProperty: 'requiredPattern' },
        ];
    }
    Object.defineProperty(ValidationMessagesComponent.prototype, "errorDefs", {
        set: function (value) {
            this._errorDefs = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ValidationMessagesComponent.prototype, "errorDefsInternal", {
        get: function () {
            var _this = this;
            var standarts = _.filter(this.standartErrorDefs, function (ed) { return !_.find(_this._errorDefs, function (edC) { return edC.error === ed.error; }); });
            var all = _.concat(standarts, this._errorDefs);
            return all;
        },
        enumerable: true,
        configurable: true
    });
    ValidationMessagesComponent.prototype.getErrorDefinitionIsInValid = function (errorDef) {
        return !!this.formCtrl.errors && !!this.formCtrl.errors[errorDef.error];
    };
    ValidationMessagesComponent.prototype.getErrorDefinitionMessage = function (errorDef) {
        var errorRequirement = this.formCtrl.errors[errorDef.error][errorDef.errorProperty];
        return !!errorRequirement
            ? this.appLocalizationService.l(errorDef.localizationKey, errorRequirement)
            : this.appLocalizationService.l(errorDef.localizationKey);
    };
    return ValidationMessagesComponent;
}());
export { ValidationMessagesComponent };
