var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { ChartDateInterval, HostDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import * as _ from 'lodash';
import { WidgetComponentBase } from '../widget-component-base';
var WidgetIncomeStatisticsComponent = /** @class */ (function (_super) {
    __extends(WidgetIncomeStatisticsComponent, _super);
    function WidgetIncomeStatisticsComponent(injector, _hostDashboardServiceProxy) {
        var _this = _super.call(this, injector) || this;
        _this._hostDashboardServiceProxy = _hostDashboardServiceProxy;
        _this.selectedIncomeStatisticsDateInterval = ChartDateInterval.Daily;
        _this.loadingIncomeStatistics = true;
        _this.selectedDateRange = [moment().add(-7, 'days').startOf('day'), moment().endOf('day')];
        _this.incomeStatisticsData = [];
        _this.incomeStatisticsHasData = false;
        _this.appIncomeStatisticsDateInterval = ChartDateInterval;
        _this.loadIncomeStatisticsData = function () {
            _this.loadingIncomeStatistics = true;
            _this._hostDashboardServiceProxy.getIncomeStatistics(_this.selectedIncomeStatisticsDateInterval, moment(_this.selectedDateRange[0]), moment(_this.selectedDateRange[1]))
                .subscribe(function (result) {
                _this.incomeStatisticsData = _this.normalizeIncomeStatisticsData(result.incomeStatistics);
                _this.incomeStatisticsHasData = _.filter(_this.incomeStatisticsData[0].series, function (data) { return data.value > 0; }).length > 0;
                _this.loadingIncomeStatistics = false;
            });
        };
        _this.onDateRangeFilterChange = function (dateRange) {
            if (!dateRange || dateRange.length !== 2 || (_this.selectedDateRange[0] === dateRange[0] && _this.selectedDateRange[1] === dateRange[1])) {
                return;
            }
            _this.selectedDateRange[0] = dateRange[0];
            _this.selectedDateRange[1] = dateRange[1];
            _this.runDelayed(_this.loadIncomeStatisticsData);
        };
        return _this;
    }
    WidgetIncomeStatisticsComponent.prototype.ngOnInit = function () {
        this.subDateRangeFilter();
        this.runDelayed(this.loadIncomeStatisticsData);
    };
    WidgetIncomeStatisticsComponent.prototype.incomeStatisticsDateIntervalChange = function (interval) {
        if (this.selectedIncomeStatisticsDateInterval === interval) {
            return;
        }
        this.selectedIncomeStatisticsDateInterval = interval;
        this.loadIncomeStatisticsData();
    };
    WidgetIncomeStatisticsComponent.prototype.normalizeIncomeStatisticsData = function (data) {
        var chartData = [];
        for (var i = 0; i < data.length; i++) {
            chartData.push({
                'name': moment(moment(data[i].date).utc().valueOf()).format('L'),
                'value': data[i].amount
            });
        }
        return [{
                name: '',
                series: chartData
            }];
    };
    WidgetIncomeStatisticsComponent.prototype.subDateRangeFilter = function () {
        abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
    };
    WidgetIncomeStatisticsComponent.prototype.unSubDateRangeFilter = function () {
        abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
    };
    WidgetIncomeStatisticsComponent.prototype.ngOnDestroy = function () {
        this.unSubDateRangeFilter();
    };
    return WidgetIncomeStatisticsComponent;
}(WidgetComponentBase));
export { WidgetIncomeStatisticsComponent };
