/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./widget-orders-summary.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../shared/utils/busy-if.directive";
import * as i3 from "../../../../../../../node_modules/@swimlane/ngx-charts/release/bar-chart/bar-horizontal-2d.component.ngfactory";
import * as i4 from "@swimlane/ngx-charts/release/bar-chart/bar-horizontal-2d.component";
import * as i5 from "./widget-orders-summary.component";
import * as i6 from "../../../../../../shared/service-proxies/service-proxies";
import * as i7 from "../../../dashboard-service/dashboard-order.service";
var styles_WidgetOrdersSummaryComponent = [i0.styles];
var RenderType_WidgetOrdersSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WidgetOrdersSummaryComponent, data: {} });
export { RenderType_WidgetOrdersSummaryComponent as RenderType_WidgetOrdersSummaryComponent };
export function View_WidgetOrdersSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "kt-portlet kt-portlet--height-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "kt-portlet__head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "kt-portlet__head-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "kt-portlet__head-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "kt-portlet__head-toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "btn btn-primary"], ["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ordersSummaryBarChart.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-redo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Refresh "])), (_l()(), i1.ɵeld(9, 16777216, null, null, 6, "div", [["class", "kt-portlet__body chart-main-container"]], null, null, null, null, null)), i1.ɵdid(10, 540672, null, 0, i2.BusyIfDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.Injector], { busyIf: [0, "busyIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "chart-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "ngx-charts-bar-horizontal-2d", [["legendPosition", "bottom"]], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.onSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_BarHorizontal2DComponent_0, i3.RenderType_BarHorizontal2DComponent)), i1.ɵdid(14, 4898816, null, 1, i4.BarHorizontal2DComponent, [i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef], { results: [0, "results"], customColors: [1, "customColors"], legend: [2, "legend"], legendPosition: [3, "legendPosition"], xAxis: [4, "xAxis"], yAxis: [5, "yAxis"], showDataLabel: [6, "showDataLabel"] }, { select: "select" }), i1.ɵqud(603979776, 1, { tooltipTemplate: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.ordersSummaryBarChart.loading; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.ordersSummaryBarChart.data; var currVal_3 = ((_co.ordersSummaryBarChart == null) ? null : _co.ordersSummaryBarChart.customColors); var currVal_4 = true; var currVal_5 = "bottom"; var currVal_6 = true; var currVal_7 = true; var currVal_8 = true; _ck(_v, 14, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.ordersSummaryBarChart == null) ? null : _co.ordersSummaryBarChart.title); _ck(_v, 4, 0, currVal_0); }); }
export function View_WidgetOrdersSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-widget-orders-summary", [], null, null, null, View_WidgetOrdersSummaryComponent_0, RenderType_WidgetOrdersSummaryComponent)), i1.ɵdid(1, 245760, null, 0, i5.WidgetOrdersSummaryComponent, [i1.Injector, i6.OrdersDashboardServiceProxy, i7.DashboardOrderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WidgetOrdersSummaryComponentNgFactory = i1.ɵccf("app-widget-orders-summary", i5.WidgetOrdersSummaryComponent, View_WidgetOrdersSummaryComponent_Host_0, { widget: "widget", page: "page" }, { onSaveWidgetSettings: "onSaveWidgetSettings" }, []);
export { WidgetOrdersSummaryComponentNgFactory as WidgetOrdersSummaryComponentNgFactory };
