
<div class="modal-header">
<div [class]="containerClass + ' kt-grid__item kt-grid__item--fluid'">
  <div class="d-flex">
      <div class="mr-auto p-2">
        <h4 class="modal-title pull-left">{{l('SaveConsignmentSearchTitle')}}</h4>
      </div>
      <div class="p-2">
        <table>
        <tbody>
          <tr>
            <td>
              <div dropdown>
                <div class="dropdown">
                  <button class="btn btn-primary dropdown-toggle"  aria-controls="dropdown-alignment" id="dropdownBasic1"
                      dropdownToggle>{{l(selectedDateFilter)}}<span class="caret"></span></button>
                  <div class="dropdown-menu" role="menu" *dropdownMenu aria-labelledby="dropdownBasic1">
                      <button (click)="setSelectedDateRange('CreatedDate')"
                          class="dropdown-item">{{l('CreatedDate')}}</button>
                      <button (click)="setSelectedDateRange('SlaDate')"
                          class="dropdown-item">{{l('SlaDate')}}</button>
                      <button (click)="setSelectedDateRange('OrderDate')"
                          class="dropdown-item">{{l("OrderDate")}}</button>
                      <button (click)="setSelectedDateRange('ShipDate')"
                          class="dropdown-item">{{l('ShipDate')}}</button>
                      <button (click)="setSelectedDateRange('PreferredShipDate')"
                          class="dropdown-item">{{l('PreferredShipDate')}}</button>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <appDatePicker #appDatePicker  (dateChange)="receiveDatePickerDates($event)"></appDatePicker>
            </td>
            <td>
              <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </td>
          </tr>
        </tbody>
        </table>    
       
      </div>
    </div>
</div>

</div>
<div class="modal-body">
    <div [class]="containerClass + ' kt-grid__item kt-grid__item--fluid'">
			<div class="row">
					<app-consignment-search #consignmentSearch [searchParams]="searchParams" [searchLabel]="l('Save')"></app-consignment-search>
				</div>
			</div>
</div>
  