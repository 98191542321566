/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./widget-general-stats.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-perfect-scrollbar";
import * as i3 from "../../../../../../../node_modules/@swimlane/ngx-charts/release/pie-chart/pie-grid.component.ngfactory";
import * as i4 from "@swimlane/ngx-charts/release/pie-chart/pie-grid.component";
import * as i5 from "./widget-general-stats.component";
import * as i6 from "../../../../../../shared/service-proxies/service-proxies";
var styles_WidgetGeneralStatsComponent = [i0.styles];
var RenderType_WidgetGeneralStatsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WidgetGeneralStatsComponent, data: {} });
export { RenderType_WidgetGeneralStatsComponent as RenderType_WidgetGeneralStatsComponent };
export function View_WidgetGeneralStatsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "kt-portlet kt-portlet--height-fluid "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "kt-portlet__head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "kt-portlet__head-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "kt-portlet__head-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" General Stats "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "kt-portlet__head-toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["class", "btn btn-primary"], ["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.generalStatsPieChart.reload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fa fa-redo"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Refresh "])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "kt-portlet__body"]], null, null, null, null, null)), i1.ɵdid(10, 999424, null, 0, i2.PerfectScrollbarDirective, [i1.NgZone, i1.KeyValueDiffers, i1.ElementRef, i1.PLATFORM_ID, [2, i2.PERFECT_SCROLLBAR_CONFIG]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "row"], ["style", "max-height: 200px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "ngx-charts-pie-grid", [], null, null, null, i3.View_PieGridComponent_0, i3.RenderType_PieGridComponent)), i1.ɵdid(13, 4898816, null, 1, i4.PieGridComponent, [i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef], { results: [0, "results"] }, null), i1.ɵqud(603979776, 1, { tooltipTemplate: 0 })], function (_ck, _v) { var _co = _v.component; _ck(_v, 10, 0); var currVal_0 = _co.generalStatsPieChart.data; _ck(_v, 13, 0, currVal_0); }, null); }
export function View_WidgetGeneralStatsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-widget-general-stats", [], null, null, null, View_WidgetGeneralStatsComponent_0, RenderType_WidgetGeneralStatsComponent)), i1.ɵdid(1, 245760, null, 0, i5.WidgetGeneralStatsComponent, [i1.Injector, i6.TenantDashboardServiceProxy], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WidgetGeneralStatsComponentNgFactory = i1.ɵccf("app-widget-general-stats", i5.WidgetGeneralStatsComponent, View_WidgetGeneralStatsComponent_Host_0, { widget: "widget", page: "page" }, { onSaveWidgetSettings: "onSaveWidgetSettings" }, []);
export { WidgetGeneralStatsComponentNgFactory as WidgetGeneralStatsComponentNgFactory };
