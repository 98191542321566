<table>
    <tbody>
        <tr>
            <td>
                <div container="body">
                    <a href="javascript:;" class="btn btn-outline-primary" (click)="dateRangePicker.toggle()">
                        <span *ngIf="!!_selectedRangeLabel else notRange">
                            {{l(_selectedRangeLabel)}}
                        </span>
                        <ng-template #notRange>
                            <span *ngIf="!!_selectedDateRange && _selectedDateRange.length else notSelected">
                                {{_selectedDateRange[0] | momentFormat: 'LL'}} -
                                {{_selectedDateRange[1] | momentFormat: 'LL'}}
                            </span>
                            <ng-template #notSelected>
                                <span >
                                    {{l('SelectDateRange')}}
                                </span>
                            </ng-template>
                        </ng-template>
                        <i class="fa fa-calendar-day pl-2 pt-0 pb-0" [attr.aria-label]="l('Expand')"></i>
                    </a>
                    <input type="text" #dateRangePicker="bsDaterangepicker" readonly="readonly" placement="left"
                        style="visibility: hidden; width:0" bsDaterangepicker [bsConfig]="daterangepickerConfig" />
                </div>
            </td>
            <td *ngIf="!!_selectedDateRange">
                <div dropdown *ngIf="!customRangeEnabled else customTime">
                    <div class="dropdown" placement="bottom right">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                            aria-controls="dropdown-alignment" dropdownToggle>
                            {{l(selectedTimeRange || '00:00 - 24:00')}}<span class="caret"></span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" role="menu" *dropdownMenu
                            aria-labelledby="dropdownMenuButton">
                            <button (click)="setSelectedTimeRange('00:00-24:00')" class="dropdown-item">00:00 -
                                24:00</button>
                            <button (click)="setSelectedTimeRange('12:00-12:00')" class="dropdown-item">12:00 -
                                12:00</button>
                            <li class="divider dropdown-divider"></li>
                            <button (click)="toggleCustomRange(true)"
                                class="dropdown-item">{{l('SetCustomRange')}}</button>
                        </div>
                    </div>
                </div>
                <ng-template #customTime>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <timepicker [(ngModel)]="_selectedDateRange[0]" [showMeridian]="false"
                                        [showSpinners]="false"></timepicker>
                                </td>
                                <td>
                                    <timepicker [(ngModel)]="_selectedDateRange[1]" [showMeridian]="false"
                                        [showSpinners]="false"></timepicker>
                                </td>
                                <td> <button class="btn btn-primary d-inline-flex" type="button"
                                        (click)="setSelectedTimeRange(_selectedDateRange[0].getHours().toString().padStart(2, '0') + ':' +_selectedDateRange[0].getMinutes().toString().padStart(2, '0')+'-'+_selectedDateRange[1].getHours().toString().padStart(2, '0')+':'+_selectedDateRange[1].getMinutes().toString().padStart(2, '0'))">{{l('Set')}}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-template>
            </td>
        </tr>
    </tbody>
</table>