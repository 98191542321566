import { AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Component, EventEmitter, Injector, Output, ViewChild, Input, ElementRef, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

@Component({
    selector: 'bigInput',
    templateUrl: './big-input.component.html'
})
export class BigInputComponent extends AppComponentBase implements OnInit {
    @Input() set input(input: string) {
        if (!input && this.inputCount > 1) {
            this.bigInputSelected = false;
        }
        this._input = input;
    }
    @Input() labelResourceKey: string = null;
    @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() validateNumbers: boolean;

    @ViewChild('modal', { static: true }) modal: ModalDirective;
    @ViewChild('smallInput', { static: true }) smallInput: ElementRef;

    get input(): string {
        return this._input;
    }
    private _input: string = null;
    bigInputSelected = false;
    isMultipleShown = false;
    inputCount = 0;
    invalidNumbers:boolean;
    constructor(
        injector: Injector,
        private _changeDetector: ChangeDetectorRef,
    ) {
        super(injector);
    }
    ngOnInit() {
    if (!!this._input) {
        this.inputCount = this.input.toString().split(',').length;
        if (this.inputCount > 1) {
            this.bigInputSelected = true;
            this._changeDetector.detectChanges();
        }
    }
    }

    onInputTextChange(event: any)
    {
        this.onInputChange(event.target.value);
    }

    clearBigInputSelected() {
        this.input = null;
        this.bigInputSelected = false;

        this.onInputChange(this.input);
    }

    show(): void {
        this.modal.show();
    }

    close(): void {
        this.modal.hide();
    }

    ok(): void {
        this.close();
        if (!!this.input) {
            this.bigInputSelected = true;
        }
        this.onInputChange(this.input);
    }

    onInputChange(input: string) {
        this.invalidNumbers = false;
        if (!!input) {
            input = input.replace(/(\r\n|\n|\r)+/gm, ',').replace(/(\s)/gm, '').replace(/(,)+/gm, ',').replace(/^,|,$/gm, '');
            this.inputCount = input.toString().split(',').length;
        }
        if(!!this.validateNumbers && !!input){
        this.invalidNumbers =  input.toString().split(',').some(e => isNaN(Number(e)));
        }
        if(this.invalidNumbers) {
            return;
        }
        this.onChange.emit(input);
    }

    shown(): void {
        this.isMultipleShown = true;
    }
    hidden(): void {
        this.isMultipleShown = false;
    }
}
