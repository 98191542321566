/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./button.component";
var styles_MetronicsButtonComponent = [];
var RenderType_MetronicsButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MetronicsButtonComponent, data: {} });
export { RenderType_MetronicsButtonComponent as RenderType_MetronicsButtonComponent };
export function View_MetronicsButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-lg m-btn m-btn--custom ml-3 float-right"]], [[2, "btn-primary", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.button.action($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.button.isPrimary; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.button.label; _ck(_v, 1, 0, currVal_1); }); }
export function View_MetronicsButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sf-button-widget", [], null, null, null, View_MetronicsButtonComponent_0, RenderType_MetronicsButtonComponent)), i0.ɵdid(1, 49152, null, 0, i1.MetronicsButtonComponent, [], null, null)], null, null); }
var MetronicsButtonComponentNgFactory = i0.ɵccf("sf-button-widget", i1.MetronicsButtonComponent, View_MetronicsButtonComponent_Host_0, {}, {}, []);
export { MetronicsButtonComponentNgFactory as MetronicsButtonComponentNgFactory };
