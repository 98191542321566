<div class="kt-footer kt-grid__item" id="kt_footer">
    <div [class]="containerClass" *ngIf="useBottomDiv">
        <div class="kt-footer__bottom">
            <div class="kt-footer__copyright">
                Eden <span
                    *ngIf="appSession.tenant && appSession.tenant.edition && appSession.tenant.edition.displayName">
                </span> | v{{appSession.application.version}}
                [{{releaseDate}}]
            </div>
            <div class="kt-footer__menu">
            </div>
        </div>
    </div>
    <div [class]="containerClass" *ngIf="!useBottomDiv">
        <div class="kt-footer__wrapper">
            <div class="kt-footer__copyright">
                Eden <span
                    *ngIf="appSession.tenant && appSession.tenant.edition && appSession.tenant.edition.displayName">
                </span> | v{{appSession.application.version}}
                [{{releaseDate}}]
            </div>
            <div class="kt-footer__menu">
            </div>
        </div>
    </div>
</div>