import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { FeatureCheckerService } from '@abp/features/feature-checker.service';
import { LocalizationService } from '@abp/localization/localization.service';
import { MessageService } from '@abp/message/message.service';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { NotifyService } from '@abp/notify/notify.service';
import { SettingService } from '@abp/settings/setting.service';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import { NameValueOfString, NameValueOfInt32, DayOfWeek } from '@shared/service-proxies/service-proxies';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HtmlHelper } from '@shared/helpers/HtmlHelper';
import * as moment from 'moment';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { LastRouteService } from '@shared/utils/last-route.service';
import { ApplicationInsightsService } from '@app/shared/services/application-insights.service';
import { WeekDay } from '@angular/common';
var AppComponentBase = /** @class */ (function () {
    function AppComponentBase(injector) {
        this.selectAll = 'All';
        this.selectNone = 'None';
        this.selectAllFlag = new NameValueOfString({
            name: this.selectAll,
            value: this.selectAll
        });
        this.selectNoneFlag = new NameValueOfString({
            name: this.selectNone,
            value: this.selectNone
        });
        this.selectAllFlagInt = new NameValueOfInt32({
            name: this.selectAll,
            value: null
        });
        this.selectNoneFlagInt = new NameValueOfInt32({
            name: this.selectNone,
            value: null
        });
        this.localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
        this.defaultDateRange = [moment().add(-90, 'days').startOf('day'), moment().endOf('day')];
        this.endOfDay = moment().endOf('day');
        //todo: following need to configyfied
        this.dateFormat = 'DD/MM/YY';
        this.calendarDateOnlyFormat = {
            lastDay: this.dateFormat,
            sameDay: this.dateFormat,
            nextDay: this.dateFormat,
            lastWeek: this.dateFormat,
            nextWeek: this.dateFormat,
            sameElse: this.dateFormat
        };
        this.calendarDateFormat = {
            lastDay: '[Yesterday]',
            sameDay: '[Today]',
            nextDay: '[Tomorrow]',
            lastWeek: this.dateFormat,
            nextWeek: this.dateFormat,
            sameElse: this.dateFormat
        };
        this.calendarDateTimeFormat = {
            lastDay: '[Yesterday at] LT',
            sameDay: '[Today at] LT',
            nextDay: '[Tomorrow at] LT',
            lastWeek: this.dateFormat + " LT",
            nextWeek: this.dateFormat + " LT",
            sameElse: this.dateFormat + " LT"
        };
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
        this.params = this.route.snapshot.queryParamMap;
        this.datetimeService = injector.get(DateTimeService);
        this.datetimeOptions = this.datetimeService.createDateRangePickerOptions();
        this.lastRouteService = injector.get(LastRouteService);
        this.spinnerService = injector.get(NgxSpinnerService);
        this.applicationInsightsService = injector.get(ApplicationInsightsService);
    }
    AppComponentBase.prototype.flattenDeep = function (array) {
        var _this = this;
        return array.reduce(function (acc, val) {
            return Array.isArray(val) ?
                acc.concat(_this.flattenDeep(val)) :
                acc.concat(val);
        }, []);
    };
    AppComponentBase.prototype.logEvent = function (name, properties) {
        this.applicationInsightsService.logEvent(name, properties);
    };
    AppComponentBase.prototype.l = function (key) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    };
    AppComponentBase.prototype.ls = function (sourcename, key) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        var localizedText = this.localization.localize(key, sourcename);
        if (!localizedText) {
            localizedText = key;
        }
        if (!args || !args.length) {
            return localizedText;
        }
        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, this.flattenDeep(args));
    };
    AppComponentBase.prototype.isGranted = function (permissionName) {
        return this.permission.isGranted(permissionName);
    };
    AppComponentBase.prototype.isGrantedAny = function () {
        var permissions = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            permissions[_i] = arguments[_i];
        }
        permissions = [].concat.apply([], permissions);
        if (!permissions) {
            return false;
        }
        for (var _a = 0, permissions_1 = permissions; _a < permissions_1.length; _a++) {
            var permission = permissions_1[_a];
            if (this.isGranted(permission)) {
                return true;
            }
        }
        return false;
    };
    AppComponentBase.prototype.s = function (key) {
        return abp.setting.get(key);
    };
    AppComponentBase.prototype.appRootUrl = function () {
        return this.appUrlService.appRootUrl;
    };
    Object.defineProperty(AppComponentBase.prototype, "currentTheme", {
        get: function () {
            return this.appSession.theme;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppComponentBase.prototype, "containerClass", {
        get: function () {
            if (this.appSession.theme.baseSettings.layout.layoutType === 'fluid') {
                return 'kt-container kt-container--fluid';
            }
            return 'kt-container';
        },
        enumerable: true,
        configurable: true
    });
    AppComponentBase.prototype.d = function (date, dateOnly) {
        if (dateOnly === void 0) { dateOnly = false; }
        return (!date) ? '' : (dateOnly ? date.calendar(null, this.calendarDateOnlyFormat) : date.calendar(null, this.calendarDateFormat));
    };
    AppComponentBase.prototype.dt = function (date) {
        return (!date) ? '' : date.calendar(null, this.calendarDateTimeFormat);
    };
    AppComponentBase.prototype.dtz = function (date, state) {
        if (!date) {
            return '';
        }
        var _date = moment.tz(date, this.getTimeZoneByState(state));
        return _date.calendar(null, this.calendarDateTimeFormat);
    };
    AppComponentBase.prototype.getTimeZoneByState = function (state) {
        switch (state.toUpperCase()) {
            case 'WA':
                return 'Australia/Perth';
            case 'NT':
            case 'SA':
                return 'Australia/Darwin';
            case 'QLD':
                return 'Australia/Brisbane';
            case 'TAS':
                return 'Australia/Hobart';
            case 'ACT':
            case 'VIC':
            case 'NSW':
                return 'Australia/Sydney';
        }
    };
    AppComponentBase.prototype.getDay = function (v) {
        return WeekDay[v];
    };
    AppComponentBase.prototype.wd = function (businessHour) {
        if (businessHour != null && businessHour.some(function (e) { return e.open; })) {
            return businessHour.filter(function (e) { return e.open; }).map(function (e) { return DayOfWeek[e.weekday]; }).map(function (e) { return e.substring(0, 3); });
        }
        else {
            return null;
        }
    };
    AppComponentBase.prototype.getParamsFromFilters = function (filters) {
        var params = {};
        for (var _i = 0, filters_1 = filters; _i < filters_1.length; _i++) {
            var filter = filters_1[_i];
            if (filter.value != null && filter.value.length !== 0) {
                if (typeof filter.value === 'string') {
                    params[filter.name] = filter.value || null;
                }
                else {
                    params[filter.name] = JSON.stringify(filter.value);
                }
            }
        }
        return params;
    };
    AppComponentBase.prototype.addFiltersToQueryString = function () {
        var filters = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            filters[_i] = arguments[_i];
        }
        var queryParams = {};
        for (var _a = 0, filters_2 = filters; _a < filters_2.length; _a++) {
            var filter = filters_2[_a];
            if (filter.value != null && filter.value.length !== 0) {
                if (typeof filter.value === 'string') {
                    queryParams[filter.name] = filter.value || null;
                }
                else {
                    queryParams[filter.name] = JSON.stringify(filter.value);
                }
            }
        }
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: Object.assign(this.getParamsFromFilters(filters), queryParams),
            replaceUrl: true
        });
    };
    AppComponentBase.prototype.getFilterValue = function (rawValue) {
        if (!rawValue) {
            return;
        }
        if (HtmlHelper.isJson(rawValue)) {
            return JSON.parse(rawValue);
        }
        else {
            return rawValue;
        }
    };
    AppComponentBase.prototype.navigateToManifestFileRecords = function (fileName, status) {
        if (status === void 0) { status = null; }
        var manifestFileNameFilter = { name: fileName, value: fileName };
        var queryParams = {
            manifestFileNameFilter: JSON.stringify(manifestFileNameFilter),
            minUpdatedAtFilter: moment(this.datetimeOptions.minDate),
            maxUpdatedAtFilter: moment(this.endOfDay)
        };
        if (status !== null) {
            queryParams['statusFilter'] = JSON.stringify({ name: this.l(status), value: status });
        }
        this.router.navigate(['/app/main/manifests/manifestFileRecords'], {
            queryParams: queryParams
        });
    };
    AppComponentBase.prototype.navigateToCostregion = function (couriername, suburb) {
        this.router.navigate(['/app/main/appConfiguration/costRegionLocations'], {
            queryParams: {
                newsuburb: suburb,
                newcourier: couriername,
                addnewCostRegion: true
            }
        });
    };
    AppComponentBase.prototype.navigateToConsignments = function (barcodes) {
        this.router.navigate(['/app/main/shipments/consignments'], {
            queryParams: {
                barcodeFilter: barcodes,
                resetDates: true
            }
        });
    };
    AppComponentBase.prototype.copyToClipboard = function (item, $event) {
        if ($event === void 0) { $event = null; }
        var selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = item;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.notify.success('copied', '', { timer: 500 });
        if ($event) {
            event.stopPropagation();
        }
    };
    return AppComponentBase;
}());
export { AppComponentBase };
