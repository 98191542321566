import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation } from '@angular/core';
import { NameValueOfString , HeatStatus} from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'heatStatusMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './heatStatus-multiselect.component.html'
})

export class HeatStatusMultiSelectComponent extends AppComponentBase implements OnInit {

    @Input()
    set selectedItems(file: NameValueOfString[]) {
        this._selectedItems = file || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfString[] {
        return this._selectedItems;
    }
    private _exclude: string[] = [];
    @Input()
    set exclude(exclude: string[]) {
        this._exclude = exclude;
        this.setHeatStatus();
    }
    @Input() name = 'HeatStatusFilter';
    @Output() selectedItemsChange: EventEmitter<NameValueOfString[]> = new EventEmitter<NameValueOfString[]>();

    heatStatusValues = HeatStatus;

    options: NameValueOfString[] = new Array<NameValueOfString>();
    
    private _selectedItems: NameValueOfString[] = [];
    @Input() placeholder = this.l('HeatStatus');

    constructor(injector: Injector) {
       super(injector)
    }

    ngOnInit() {
        this.setHeatStatus();
    }

    heatStatusKeys(): Array<string> {
        const keys = Object.keys(this.heatStatusValues);
        return keys.slice(keys.length / 2);
    }

    setHeatStatus(): void {
        this.options = [];

        this.heatStatusKeys().forEach(e => {
            if (!this._exclude.includes(e)) {
                this.options.push(
                    new NameValueOfString({ name: this.l(e), value: this.heatStatusValues[e] })
                );
            }       
        });
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}