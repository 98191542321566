import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Injector, Output, ViewChild, Input, ElementRef, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DatePickerArgs } from '@app/main/shipments/consignments/consignment-manager';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { BsDaterangepickerConfig, BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker';
import { BsCustomDates } from 'ngx-bootstrap/datepicker/themes/bs/bs-custom-dates-view.component';
import compare from 'just-compare';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'appDatePicker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})

export class DatePickerComponent extends AppComponentBase implements AfterViewInit, OnDestroy {

  @Output() dateChange = new EventEmitter<DatePickerArgs>();

  @Input() selectedTimeRange = '00:00-24:00';

  _selectedRangeLabel: string = null;
  customRangeEnabled = false;

  get selectedRangeLabel(): string {
    return this._selectedRangeLabel;
  }
  @Input('selectedRangeLabel')
  set selectedRangeLabel(selectedRangeLabel: string) {

    this._selectedRangeLabel = selectedRangeLabel;

    let selected: BsCustomDates[] = this.daterangepickerConfig.ranges.filter(range => compare(range.label, selectedRangeLabel));
    if (!!selected.length && selected[0].value) {
      this._selectedDateRange = [selected[0].value[0], selected[0].value[1]];
      if (!!this.bsDateRangepicker) {
        this.bsDateRangepicker.bsValue = this._selectedDateRange;
      }
    }
  }

  @ViewChild(BsDaterangepickerDirective, { static: false })
  set bsDaterangepicker(directive: BsDaterangepickerDirective) {
    this.bsDateRangepicker = directive;
  }

  bsDateRangepicker: BsDaterangepickerDirective;
  _selectedDateRange: Date[];

  get selectedDateRange(): moment.Moment[] {
    if (!this._selectedDateRange) {
      return null;
    } else {
      return NgxBootstrapDatePickerConfigService.getModifiedRangeBasedOnTime([moment(this._selectedDateRange[0]), moment(this._selectedDateRange[1])], this.selectedTimeRange);
    }
  }
  @Input('selectedDateRange')
  set selectedDateRange(args: moment.Moment[]) {
    if (!!args && args.length !== 0) {
      this._selectedDateRange = [new Date(args[0].toDate()), new Date(args[1].toDate())];
    } else {
      this._selectedDateRange = null;
    }
    if (!!this.bsDateRangepicker) {
      this.bsDateRangepicker.bsValue = this._selectedDateRange;
    }
  }

  daterangepickerConfig = NgxBootstrapDatePickerConfigService.getDaterangepickerConfig();

  @Input() date = new EventEmitter();

  subscribe: Subscription;
  lastDates: Date[] = null;

  constructor(
    injector: Injector  ) {
    super(injector);

  }

  setSelectedTimeRange(range: string) {
    this.selectedTimeRange = range;
    this.toggleCustomRange(false);
    this.sendBackDates();
  }

  ngOnDestroy() {
    this.subscribe.unsubscribe();
  }
  ngAfterViewInit() {
    this.bsDateRangepicker.bsValue = this._selectedDateRange;
    this.subscribe = this.bsDateRangepicker.bsValueChange
      .pipe(filter(dates => !!(dates && dates[0] instanceof Date && dates[1] instanceof Date
        && !compare(this.lastDates, dates) && dates[0].toString() !== 'Invalid Date' && dates[1].toString() !== 'Invalid Date')))
      .subscribe((dates: Date[]) => {
        this.lastDates = dates;
        let selected: BsCustomDates[] = this.bsDateRangepicker.bsConfig.ranges.filter(range => compare(range.value, dates));
        if (!!selected.length) {
          this._selectedRangeLabel = selected[0].label;
        } else {
          this._selectedRangeLabel = null;
        }
        this._selectedDateRange = dates;
        this.sendBackDates();
      });
  }

  toggleCustomRange(value: boolean) {
    this.customRangeEnabled = value;
  }

  sendBackDates() {
    let modifiedTimeRange: moment.Moment[] = null;
    if (!!this.selectedTimeRange) {
      modifiedTimeRange = NgxBootstrapDatePickerConfigService.getModifiedRangeBasedOnTime([moment(this._selectedDateRange[0]), moment(this._selectedDateRange[1])], this.selectedTimeRange);
    }
    this.dateChange.emit(<DatePickerArgs>{
      selectedDateRange: modifiedTimeRange,
      selectedRangeLabel: this._selectedRangeLabel,
      selectedTimeRange: this.selectedTimeRange
    });
  }
}
