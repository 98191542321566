var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { LinkToUserInput, UserLinkServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
var LinkAccountModalComponent = /** @class */ (function (_super) {
    __extends(LinkAccountModalComponent, _super);
    function LinkAccountModalComponent(injector, _userLinkService) {
        var _this = _super.call(this, injector) || this;
        _this._userLinkService = _userLinkService;
        _this.modalSave = new EventEmitter();
        _this.active = false;
        _this.saving = false;
        _this.linkUser = new LinkToUserInput();
        return _this;
    }
    LinkAccountModalComponent.prototype.show = function () {
        this.active = true;
        this.linkUser = new LinkToUserInput();
        this.linkUser.tenancyName = this.appSession.tenancyName;
        this.modal.show();
    };
    LinkAccountModalComponent.prototype.onShown = function () {
        document.getElementById('TenancyName').focus();
    };
    LinkAccountModalComponent.prototype.save = function () {
        var _this = this;
        this.saving = true;
        this._userLinkService.linkToUser(this.linkUser)
            .pipe(finalize(function () { _this.saving = false; }))
            .subscribe(function () {
            _this.notify.success(_this.l('SavedSuccessfully'));
            _this.close();
            _this.modalSave.emit(null);
        });
    };
    LinkAccountModalComponent.prototype.close = function () {
        this.active = false;
        this.modal.hide();
    };
    return LinkAccountModalComponent;
}(AppComponentBase));
export { LinkAccountModalComponent };
