import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';

@Injectable()
export class AppNavigationService {

    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {

    }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'flaticon-line-graph', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'flaticon-line-graph', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'flaticon-list-3', '/app/admin/tenants'),
            new AppMenuItem('AppConfiguration', 'Pages.AppConfiguration', 'flaticon-settings-1', '', [
                new AppMenuItem('ClientGroups', 'Pages.AppConfiguration.ClientGroups', 'flaticon-list-2', '/app/main/appConfiguration/clientGroups'),
                new AppMenuItem('Clients', 'Pages.AppConfiguration.Clients', 'flaticon-users', '/app/main/appConfiguration/clients'),
                new AppMenuItem('DepotLocations', 'Pages.AppConfiguration.DepotLocations', 'flaticon-buildings', '/app/main/appConfiguration/depotLocations', [], true),
                new AppMenuItem('DeliveryTypeSettings', 'Pages.AppConfiguration.DeliveryTypeSettings', 'flaticon-business', '/app/main/appConfiguration/deliveryTypeSettings'),
                new AppMenuItem('Printers', 'Pages.AppConfiguration.Printers', 'fa fa-print', '/app/main/appConfiguration/printers'),
                new AppMenuItem('DepotToDepotSettings', 'Pages.AppConfiguration.DepotToDepotSettings', 'flaticon-calendar-with-a-clock-time-tools', '/app/main/appConfiguration/depotToDepotSettings'),
                new AppMenuItem('PackageWeights', 'Pages.AppConfiguration.PackageWeights', 'flaticon2-cube', '/app/main/appConfiguration/packageWeights'),
                new AppMenuItem('Carriers', 'Pages.AppConfiguration.Carriers', 'flaticon-truck', '/app/main/appConfiguration/carriers'),
                new AppMenuItem('TerritoryConfigurations', 'Pages.AppConfiguration.TerritoryConfigurations', 'flaticon-map-location', '/app/main/appConfiguration/territoryConfigurations'),
                new AppMenuItem('LocationSettings', 'Pages.AppConfiguration.LocationSettings', 'flaticon-map', '/app/main/appConfiguration/locationSettings'),
                new AppMenuItem('CostRegions', 'Pages.AppConfiguration.CostRegions', 'flaticon2-contract', '/app/main/appConfiguration/costRegions'),
                new AppMenuItem('CostRegionLocations', 'Pages.AppConfiguration.CostRegionLocations', 'flaticon-map-location', '/app/main/appConfiguration/costRegionLocations'),
                new AppMenuItem('DeliveryDateExclusions', 'Pages.AppConfiguration.DeliveryDateExclusions', 'flaticon-calendar', '/app/main/appConfiguration/deliveryDateExclusions/All'),
                new AppMenuItem('Couriers', 'Pages.AppConfiguration.Couriers', 'flaticon-car', '/app/main/appConfiguration/couriers', [], true),
                new AppMenuItem('EventGroups', 'Pages.AppConfiguration.EventGroups', 'flaticon-layers', '/app/main/appConfiguration/eventGroups'),
                new AppMenuItem('EventDetails', 'Pages.AppConfiguration.EventDetails', 'flaticon-file-2', '/app/main/appConfiguration/eventDetails'),
                new AppMenuItem('BrandCustomerRegions', 'Pages.AppConfiguration.BrandCustomerRegions', 'fa fa-city', '/app/main/appConfiguration/brandCustomerRegions',[],true),
                new AppMenuItem('BrandRegionSLAs', 'Pages.AppConfiguration.BrandRegionSLAs', 'flaticon-calendar', '/app/main/appConfiguration/brandRegionSLAs'),
                new AppMenuItem('StoreNumberDeliveryPostcodes', 'Pages.AppConfiguration.StoreNumberDeliveryPostcodes', 'fa fa-warehouse', '/app/main/appConfiguration/storeNumberDeliveryPostcodes'),
                new AppMenuItem('BurndownEvents', 'Pages.AppConfiguration.BurndownEvents', 'fa fa-fire', '/app/main/appConfiguration/burndownEvents'),
                new AppMenuItem('Brands', 'Pages.AppConfiguration.Brands', 'flaticon2-layers-2', '/app/main/appConfiguration/brands'),
                new AppMenuItem('Stores', 'Pages.AppConfiguration.Stores', 'flaticon-buildings', '/app/main/appConfiguration/stores'),
                new AppMenuItem('ExternalVendors', 'Pages.AppConfiguration.ClientGroups.ExternalVendors', 'flaticon-list-2', '/app/main/appConfiguration/externalVendors'),
            ]),
            new AppMenuItem('Shipments', 'Pages.Shipments', 'flaticon-truck', '', [
                new AppMenuItem('Consignments', 'Pages.Shipments.Consignments', 'flaticon-truck', '/app/main/shipments/consignments'),
                new AppMenuItem('Orders', 'Pages.Shipments.Orders', 'flaticon2-shopping-cart-1', '/app/main/shipments/orders'),
            ]),
            new AppMenuItem('ZendeskConfiguration', 'Pages.ZendeskConfiguration', 'flaticon-settings-1', '', [
                new AppMenuItem('ZenDeskFields', 'Pages.ZendeskConfiguration.ZenDeskFields', 'flaticon-clipboard', '/app/main/zendeskConfiguration/zenDeskFields')]),

            new AppMenuItem('Operations', 'Pages.Operations', 'fa fa-map', '', [
                new AppMenuItem('DepotScans', 'Pages.Operations.BarcodeScanHistories.Create', 'fa fa-barcode', '/app/main/operations/depotScans'),
                new AppMenuItem('BarcodeScanHistories', 'Pages.Operations.BarcodeScanHistories', 'fa fa-barcode', '/app/main/operations/barcodeScanHistories'),
                new AppMenuItem('Loads', 'Pages.Operations.Loads', 'flaticon-open-box', '/app/main/operations/loads'),
                new AppMenuItem('PackageStatus', 'Pages.Operations.PackageStatus', 'flaticon-information', '/app/main/operations/packageStatus'),
                new AppMenuItem('CrossBorders', 'Pages.Operations.CrossBorders', 'flaticon-globe', '/app/main/operations/xBorders'),
                new AppMenuItem('ReturnToSuppliers', 'Pages.Operations.ReturnToSuppliers', 'flaticon-symbol', '/app/main/operations/returnToSuppliers'),
                new AppMenuItem('Pallets', 'Pages.Operations.Pallets', 'flaticon-tool', '/app/main/operations/pallets')

            ]),

            new AppMenuItem('Finance', 'Pages.Finance', 'flaticon-piggy-bank', '', [
                new AppMenuItem('MiscCharges', 'Pages.Finance.MiscCharges', 'flaticon-folder-1', '/app/main/finance/miscCharges'),
                new AppMenuItem('MiscCostChargeTypes', 'Pages.Finance.MiscCostChargeTypes', 'flaticon-plus', '/app/main/finance/miscCostChargeTypes'),
                new AppMenuItem('DriverDaysWorked', 'Pages.Finance.DriverDaysWorked', 'flaticon-truck', '/app/main/finance/driverDaysWorked'),
            ]),

            new AppMenuItem('Administration', '', 'flaticon-interface-8', '', [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings'),
                new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions')
            ]),
            new AppMenuItem('Manifests', 'Pages.Manifests', 'flaticon-clipboard', '', [
                new AppMenuItem('ManifestFiles', 'Pages.Manifests.ManifestFiles', 'flaticon-file-2', '/app/main/manifests/manifestFiles'),
                new AppMenuItem('ManifestFileRecords', 'Pages.Manifests.ManifestFileRecords', 'flaticon-list-3', '/app/main/manifests/manifestFileRecords')
            ]),

            new AppMenuItem('Geocodes', 'Pages.Geocodes', '	flaticon-placeholder-3', '', [
                new AppMenuItem('GeocodeLocations', 'Pages.Geocodes.UI', '	flaticon-map-location', '/app/main/geocode/updateGeocodes'),
            ]),
            new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null || subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
