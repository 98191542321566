import { Component, Injector, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ConsignmentSearchComponent } from '@app/shared/common/consignment-search/consignment-search.component';
import { ConsignmentsServiceProxy } from '../../../../../../shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { Params } from '@angular/router';
import { IConsignmentSearchOutput } from '../../../consignment-search/consignment-search.component';
import { DatePickerArgs } from '@app/main/shipments/consignments/consignment-manager';
import compare from 'just-compare';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { DatePickerComponent } from '@app/shared/common/date-picker/date-picker.component';

class ConsignmentStats extends DashboardChartBase {

  public result = {};


  constructor(private _consignmentsService: ConsignmentsServiceProxy, private consignmentSearchOutput: IConsignmentSearchOutput = null) {
    super();
  }

  reload() {
    this.showLoading();
    if (!!this.consignmentSearchOutput) {
      let searchInput = this.consignmentSearchOutput.getAllConsignmentsInput;

      if (!!searchInput.selectedRangeLabel) {
        let selectedDateRange: Date[] = [];
        let selected = NgxBootstrapDatePickerConfigService.getDaterangepickerConfig().ranges.filter(range => compare(range.label, searchInput.selectedRangeLabel));
        if (!!selected.length && selected[0].value) {
          selectedDateRange = [selected[0].value[0], selected[0].value[1]];
          searchInput.maxCreatedDateFilter = null;
          searchInput.minCreatedDateFilter = null;
          searchInput.maxSlaDateFilter = null;
          searchInput.minSlaDateFilter = null;
          searchInput.maxOrderDateFilter = null;
          searchInput.minOrderDateFilter = null;
          searchInput.maxShipDateFilter = null;
          searchInput.minShipDateFilter = null;
          searchInput.maxPreferredShipDateFilter = null;
          searchInput.minPreferredShipDateFilter = null;

          if (!selectedDateRange.length) {
            return;
          }
          let m_selectedDateRange = [moment(selectedDateRange[0]), moment(selectedDateRange[1])];
          m_selectedDateRange = NgxBootstrapDatePickerConfigService.getModifiedRangeBasedOnTime(m_selectedDateRange, this.consignmentSearchOutput.getAllConsignmentsInput.selectedTimeRange)

          let maxDate = m_selectedDateRange[1];
          let minDate = m_selectedDateRange[0];

          switch (searchInput.selectedDateFilter) {
            case 'CreatedDate': {
              searchInput.maxCreatedDateFilter = maxDate;
              searchInput.minCreatedDateFilter = minDate;
              break;
            }
            case 'SlaDate': {
              searchInput.maxSlaDateFilter = maxDate;
              searchInput.minSlaDateFilter = minDate;
              break;
            }
            case 'OrderDate': {
              searchInput.maxOrderDateFilter = maxDate;
              searchInput.minOrderDateFilter = minDate;
              break;
            }
            case 'ShipDate': {
              searchInput.maxShipDateFilter = maxDate;
              searchInput.minShipDateFilter = minDate;
              break;
            }
            case 'PreferredShipDate': {
              searchInput.maxPreferredShipDateFilter = maxDate;
              searchInput.minPreferredShipDateFilter = minDate;
              break;
            }
            default: {
              break;
            }
          }
        }
      }

      this._consignmentsService
        .getConsignmentStatistics(searchInput)
        .subscribe(result => {
          this.result = result;
          this.hideLoading();
        });
    }
  }
}

@Component({
  selector: 'modal-content',
  template: `
<div class="modal-header">
<div [class]="containerClass + ' kt-grid__item kt-grid__item--fluid'">
  <div class="d-flex">
      <div class="mr-auto p-2">
        <h4 class="modal-title pull-left">{{l('SaveConsignmentSearchTitle')}}</h4>
      </div>
      <div class="p-2">
        <table>
        <tbody>
          <tr>
            <td>
              <div dropdown>
                <div class="dropdown">
                  <button class="btn btn-primary dropdown-toggle"  aria-controls="dropdown-alignment" id="dropdownBasic1"
                      dropdownToggle>{{l(selectedDateFilter)}}<span class="caret"></span></button>
                  <div class="dropdown-menu" role="menu" *dropdownMenu aria-labelledby="dropdownBasic1">
                      <button (click)="setSelectedDateRange('CreatedDate')"
                          class="dropdown-item">{{l('CreatedDate')}}</button>
                      <button (click)="setSelectedDateRange('SlaDate')"
                          class="dropdown-item">{{l('SlaDate')}}</button>
                      <button (click)="setSelectedDateRange('OrderDate')"
                          class="dropdown-item">{{l("OrderDate")}}</button>
                      <button (click)="setSelectedDateRange('ShipDate')"
                          class="dropdown-item">{{l('ShipDate')}}</button>
                      <button (click)="setSelectedDateRange('PreferredShipDate')"
                          class="dropdown-item">{{l('PreferredShipDate')}}</button>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <appDatePicker #appDatePicker  (dateChange)="receiveDatePickerDates($event)"></appDatePicker>
            </td>
            <td>
              <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </td>
          </tr>
        </tbody>
        </table>    
       
      </div>
    </div>
</div>

</div>
<div class="modal-body">
    <div [class]="containerClass + ' kt-grid__item kt-grid__item--fluid'">
			<div class="row">
					<app-consignment-search #consignmentSearch [searchParams]="searchParams" [searchLabel]="l('Save')"></app-consignment-search>
				</div>
			</div>
</div>
  `
})

export class ModalContentComponent extends AppComponentBase implements AfterViewInit {
  @ViewChild('consignmentSearch', { static: false }) consignmentSearch: ConsignmentSearchComponent;
  @ViewChild('appDatePicker', { static: false }) appDatePicker: DatePickerComponent;
  searchParams: Params;
  selectedDateFilter = 'CreatedDate';
  selectedDateRange: moment.Moment[];
  selectedRangeLabel = 'Since Yesterday';
  selectedTimeRange = null;

  constructor(public bsModalRef: BsModalRef, injector: Injector) {
    super(injector);
  }


  ngAfterViewInit() {
    if (!!this.searchParams) {
      this.consignmentSearch.searchParams = this.searchParams;
      this.selectedDateFilter = this.searchParams.selectedDateFilter;
      if (!!this.searchParams.selectedRangeLabel) {
        this.appDatePicker.selectedRangeLabel = this.searchParams.selectedRangeLabel;
      } else {
        if (!!this.searchParams.selectedDateRange) {
          this.appDatePicker.selectedDateRange = this.searchParams.selectedDateRange;
        }
      }
      if (!!this.searchParams.selectedTimeRange) {
        this.appDatePicker.selectedTimeRange = this.searchParams.selectedTimeRange;
      }
    } else {
      this.appDatePicker.selectedRangeLabel = this.selectedRangeLabel;
    }
  }

  receiveDatePickerDates(event: DatePickerArgs) {
    this.selectedDateRange = event.selectedDateRange;
    this.selectedRangeLabel = event.selectedRangeLabel;
    this.selectedTimeRange = event.selectedTimeRange;
    this.updateSelectedDate(this.selectedDateRange);
  }

  setSelectedDateRange(selectedDateFilter: string) {
    this.selectedDateFilter = selectedDateFilter;
    this.updateSelectedDate(this.selectedDateRange);
  }

  updateSelectedDate(selectedDateRange: moment.Moment[]) {
    this.consignmentSearch.selectedDateFilter = this.selectedDateFilter;
    this.consignmentSearch.selectedRangeLabel = this.selectedRangeLabel;
    this.consignmentSearch.selectedTimeRange = this.selectedTimeRange;
    this.consignmentSearch.maxCreatedDateFilter = null;
    this.consignmentSearch.minCreatedDateFilter = null;
    this.consignmentSearch.maxSlaDateFilter = null;
    this.consignmentSearch.minSlaDateFilter = null;
    this.consignmentSearch.maxOrderDateFilter = null;
    this.consignmentSearch.minOrderDateFilter = null;
    this.consignmentSearch.maxShipDateFilter = null;
    this.consignmentSearch.minShipDateFilter = null;
    this.consignmentSearch.maxPreferredShipDateFilter = null;
    this.consignmentSearch.minPreferredShipDateFilter = null;
    if (!!this.selectedDateRange) {
      switch (this.selectedDateFilter) {
        case 'CreatedDate': {
          this.consignmentSearch.maxCreatedDateFilter = selectedDateRange[1];
          this.consignmentSearch.minCreatedDateFilter = selectedDateRange[0];
          break;
        }
        case 'SlaDate': {
          this.consignmentSearch.maxSlaDateFilter = selectedDateRange[1];
          this.consignmentSearch.minSlaDateFilter = selectedDateRange[0];
          break;
        }
        case 'OrderDate': {
          this.consignmentSearch.maxOrderDateFilter = selectedDateRange[1];
          this.consignmentSearch.minOrderDateFilter = selectedDateRange[0];
          break;
        }
        case 'ShipDate': {
          this.consignmentSearch.maxShipDateFilter = selectedDateRange[1];
          this.consignmentSearch.minShipDateFilter = selectedDateRange[0];
          break;
        }
        case 'PreferredShipDate': {
          this.consignmentSearch.maxPreferredShipDateFilter = selectedDateRange[1];
          this.consignmentSearch.minPreferredShipDateFilter = selectedDateRange[0];
          break;
        }
        default: {
          break;
        }
      }
    }
  }


}

@Component({
  selector: 'app-widget-consignment-stats',
  templateUrl: './widget-consignment-stats.component.html',
  styleUrls: ['./widget-consignment-stats.component.css']
})



export class WidgetConsignmentStatsComponent extends WidgetComponentBase implements OnChanges {
  @ViewChild('modal', { static: false }) modal: ModalDirective;
  bsModalRef: BsModalRef;
  editMode = false;
  consignmentStats: ConsignmentStats;
  redirectToConsignments() {
    if (!!this.widget.settings.Data) {
      let params = (<IConsignmentSearchOutput>this.widget.settings.Data).params;
      this.router.navigate(['/app/main/shipments/consignments'], {
        queryParams: params,
      });
    }
  }

  constructor(injector: Injector,
    private _consignmentsService: ConsignmentsServiceProxy,
    private modalService: BsModalService) {
    super(injector);
  }
  ngOnChanges(): void {
    if (!!this.widget && !!this.widget.settings) {
      this.consignmentStats = new ConsignmentStats(this._consignmentsService, this.widget.settings.Data);
      this.logEvent('consignment-stats_view', this.widget.settings);
      this.consignmentStats.reload();
    } else {
      this.widget.settings = {};
      this.widget.settings.Title = 'Set Title';
      this.openModalWithComponent();
    }
  }

  openModalWithComponent() {


    if (!!this.widget.settings.Data) {
      const initialState = { searchParams: (<IConsignmentSearchOutput>this.widget.settings.Data).params };
      this.bsModalRef = this.modalService.show(ModalContentComponent, { class: 'modal-xl', initialState });
    } else {
      this.bsModalRef = this.modalService.show(ModalContentComponent, Object.assign({}, { class: 'modal-xl' }));
    }

    this.bsModalRef.content.consignmentSearch.onSave.subscribe((value: IConsignmentSearchOutput) => {
      this.logEvent('consignment-stats_change', value);
      this.saveWidgetData(value);
      this.bsModalRef.hide();
    });
  }
}



