import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation } from '@angular/core';
import { NameValueOfString, RegionValue } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'regionValuesMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './regionValues-multiselect.component.html'
})

export class RegionValuesMultiSelectComponent extends AppComponentBase implements OnInit {

    @Input()
    set selectedItems(items: NameValueOfString[]) {
        this._selectedItems = items || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfString[] {
        return this._selectedItems;
    }
    private _exclude: string[] = [];
    @Input()
    set exclude(exclude: string[]) {
        this._exclude = exclude;
        this.setRegionValues();
    }
    @Input() name = 'RegionValueFilter';
    @Output() selectedItemsChange: EventEmitter<NameValueOfString[]> = new EventEmitter<NameValueOfString[]>();

    regionValueValues = RegionValue;

    options: NameValueOfString[] = new Array<NameValueOfString>();

    private _selectedItems: NameValueOfString[] = [];
    @Input() placeholder = this.l('Region');

    constructor(injector: Injector) {
       super(injector)
    }

    ngOnInit() {
        this.setRegionValues();
    }

    regionValueKeys(): Array<string> {
        const keys = Object.keys(this.regionValueValues);
        return keys.slice(keys.length / 2);
    }

    setRegionValues(): void {
        this.options = [];

        this.regionValueKeys().forEach(e => {
            if (!this._exclude.includes(e)) {
                this.options.push(
                    new NameValueOfString({ name: this.l(e), value: this.regionValueValues[e] })
                );
            }
        });
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}