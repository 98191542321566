import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DepotLocationsServiceProxy, ProfileServiceProxy, AppConfigurationLookupServiceProxy, NameValueOfString } from '@shared/service-proxies/service-proxies';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppPreBootstrap } from 'AppPreBootstrap';
import { DepotToggleService } from '@app/main/operations/shared/depotToggle/depotToggle.service';

@Component({
    selector: 'changeDepotModal',
    templateUrl: './change-depot-modal.component.html'
})

export class ChangeDepotModalComponent extends AppComponentBase {
    @ViewChild('changeDepotDropdown', {static: true}) changeDepotDropdown: ElementRef;
    @ViewChild('changeDepotModal', { static: true }) modal: ModalDirective;
    saving = false;
    active = false;
    depots$: Observable<NameValueOfString[]>;
    currentDepot = abp.setting.get('UserSettings.DepotAssociation.Depot') || '';
    chosenDepot = abp.setting.get('UserSettings.DepotAssociation.Depot') || '';
    appPreBootstrap = AppPreBootstrap;

    constructor(
        injector: Injector,
        private _depotService: DepotToggleService,
        private _appConfigurationLookupServiceProxy: AppConfigurationLookupServiceProxy,
        private _profileServiceProxy: ProfileServiceProxy
    ) {
        super(injector);
    }

    show(): void {
        this.active = true;
        this.depots$ = this._appConfigurationLookupServiceProxy.getDepotLocations("");
        this.depots$.subscribe(() => this.modal.show());
    }

    onShown() {
        // this.changeDepotDropdown.nativeElement.focus();
    }

    close(): void {
        this.active = false;
        this.saving = false;
        this.modal.hide();
    }

    save(): void {
        if (this.chosenDepot !== this.currentDepot) {
            this.saving = true;
            this._profileServiceProxy.updateAssociatedDepot(this.chosenDepot)
            .subscribe(() => {
                this.appPreBootstrap.getUserConfiguration(() => {
                    this._depotService.updateCurrentDepot(abp.setting.get('UserSettings.DepotAssociation.Depot'));
                    this.currentDepot = abp.setting.get('UserSettings.DepotAssociation.Depot') || '';
                    this.close();
                });
            });
        } else {
            this.close();
        }
    }
}
