var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SettingScopes, TimingServiceProxy } from '@shared/service-proxies/service-proxies';
import { ControlValueAccessor, FormControl } from '@angular/forms';
var TimeZoneComboComponent = /** @class */ (function (_super) {
    __extends(TimeZoneComboComponent, _super);
    function TimeZoneComboComponent(_timingService, injector) {
        var _this = _super.call(this, injector) || this;
        _this._timingService = _timingService;
        _this.timeZones = [];
        _this.selectedTimeZone = new FormControl('');
        _this.onTouched = function () { };
        return _this;
    }
    TimeZoneComboComponent.prototype.ngOnInit = function () {
        var self = this;
        self._timingService.getTimezones(self.defaultTimezoneScope).subscribe(function (result) {
            self.timeZones = result.items;
        });
    };
    TimeZoneComboComponent.prototype.writeValue = function (obj) {
        if (this.selectedTimeZone) {
            this.selectedTimeZone.setValue(obj);
        }
    };
    TimeZoneComboComponent.prototype.registerOnChange = function (fn) {
        this.selectedTimeZone.valueChanges.subscribe(fn);
    };
    TimeZoneComboComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    TimeZoneComboComponent.prototype.setDisabledState = function (isDisabled) {
        if (isDisabled) {
            this.selectedTimeZone.disable();
        }
        else {
            this.selectedTimeZone.enable();
        }
    };
    return TimeZoneComboComponent;
}(AppComponentBase));
export { TimeZoneComboComponent };
