import { DashboardChartBase } from '../widgets/dashboard-chart-base';
import { OrdersDashboardServiceProxy, OrdersSummaryDashboardOutput } from '@shared/service-proxies/service-proxies';

export class OrdersSummaryChart extends DashboardChartBase {
    public data = [];
    title: string;
    tileTitle = 'Order Summary';
    public customColors = [
        {
            name: 'FULFILLED',
            value: '#255b25'
        },
        {
            name: 'PRE MANIFESTED',
            value: '#e2878f'
        }
    ];
    constructor(private _dashboardService: OrdersDashboardServiceProxy) {
        super();
        this.title = this.tileTitle;
    }

    init(data: OrdersSummaryDashboardOutput) {
        this.title = this.tileTitle + ' (' + (data.mainTitle || 'BAU') + ')';
        if (!!data && !!data.ordersSummaryDatas) {
            this.data = data.ordersSummaryDatas.map(x => {
                return {
                    name: x.groupLabel,
                    series: [
                        {
                            name: 'PRE MANIFESTED',
                            filterName: 'preManifested',
                            value: x.preManifested,
                            extra: {
                                id: x.groupId
                            },
                            data: x.preManifested
                        },
                        {
                            name: 'FULFILLED',
                            filterName: 'fulfilled',
                            value: x.fulfilled,
                            extra: {
                                id: x.groupId
                            },
                            data: x.fulfilled
                        }
                    ]
                };
            });
        }

        this.hideLoading();
    }

    reload() {
        this.showLoading();

        this._dashboardService.getOrdersSummary().subscribe(result => {
            this.init(result);
        });
    }
}
