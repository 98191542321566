var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { UiCustomizationSettingsServiceProxy } from '@shared/service-proxies/service-proxies';
var ThemeSelectionPanelComponent = /** @class */ (function (_super) {
    __extends(ThemeSelectionPanelComponent, _super);
    function ThemeSelectionPanelComponent(injector, _uiCustomizationService) {
        var _this = _super.call(this, injector) || this;
        _this._uiCustomizationService = _uiCustomizationService;
        _this.currentThemeName = '';
        _this.offcanvasOptions = {
            overlay: true,
            baseClass: 'kt-demo-panel',
            closeBy: 'kt-theme_selection_panel_close',
            toggleBy: 'kt_theme_selection_panel_toggle'
        };
        return _this;
    }
    ThemeSelectionPanelComponent.prototype.ngOnInit = function () {
        this.currentThemeName = this.currentTheme.baseSettings.theme;
    };
    ThemeSelectionPanelComponent.prototype.getLocalizedThemeName = function (str) {
        return this.l('Theme_' + abp.utils.toPascalCase(str));
    };
    ThemeSelectionPanelComponent.prototype.changeTheme = function (themeName) {
        this._uiCustomizationService.changeThemeWithDefaultValues(themeName).subscribe(function () {
            window.location.reload();
        });
    };
    return ThemeSelectionPanelComponent;
}(AppComponentBase));
export { ThemeSelectionPanelComponent };
