var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { DashboardConsignmentService } from '@app/shared/common/dashboard-service/dashboard-consignment.service';
import { ConsignmentStatusDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { EdByStateSummaryTable } from '../../models/ed-by-state-summary-table';
import { WidgetComponentBase } from '../widget-component-base';
var WidgetEdByStateSummaryComponent = /** @class */ (function (_super) {
    __extends(WidgetEdByStateSummaryComponent, _super);
    function WidgetEdByStateSummaryComponent(injector, dataService, dashboardConsignmentService) {
        var _this = _super.call(this, injector) || this;
        _this.dataService = dataService;
        _this.dashboardConsignmentService = dashboardConsignmentService;
        _this.edByStateSummaryTable = new EdByStateSummaryTable(_this.dataService);
        return _this;
    }
    WidgetEdByStateSummaryComponent.prototype.ngOnInit = function () {
        this.edByStateSummaryTable.reload();
    };
    WidgetEdByStateSummaryComponent.prototype.fieldClick = function (filter) {
        this.dashboardConsignmentService.navigate(filter);
    };
    return WidgetEdByStateSummaryComponent;
}(WidgetComponentBase));
export { WidgetEdByStateSummaryComponent };
