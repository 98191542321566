<div bsModal #changeDepotModal="bs-modal" (onShown)="onShown()" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog">
        <div class="modal-content">
            <form *ngIf="active" #changeDepotModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"ChangeDepot" | localize}}</span>
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ng-container *ngIf="(depots$ | async); let depots">
                        <div class="form-group">
                            <label for="Timezone">{{"DepotLocations" | localize}}</label>
                            <select class="form-control" [(ngModel)]="chosenDepot" name="selectDepot" id="selectDepot" #changeDepotDropdown>
                                <option *ngFor="let depot of depots" [value]="depot.value">{{depot.value}} - {{depot.name}}</option>
                                <option [value]="''">None</option>
                            </select>
                        </div>
                    </ng-container>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary" [disabled]="saving"><i class="fa fa-save"></i>
                        <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>