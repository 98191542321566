var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, EventEmitter, Injector } from '@angular/core';
import { NameValueOfInt32, DeliveryType } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
var DeliveryTypesMultiSelectComponent = /** @class */ (function (_super) {
    __extends(DeliveryTypesMultiSelectComponent, _super);
    function DeliveryTypesMultiSelectComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.name = 'TypeFilter';
        _this.selectedItemsChange = new EventEmitter();
        _this.DeliveryTypeValues = DeliveryType;
        _this.options = new Array();
        _this.placeholder = _this.l("ConsignmentType");
        _this._selectedItems = [];
        return _this;
    }
    Object.defineProperty(DeliveryTypesMultiSelectComponent.prototype, "selectedItems", {
        get: function () {
            return this._selectedItems;
        },
        set: function (items) {
            this._selectedItems = items || [];
            this.selectedItemsChange.emit(this._selectedItems);
        },
        enumerable: true,
        configurable: true
    });
    ;
    DeliveryTypesMultiSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.deliveryTypeKeys().forEach(function (e) {
            if (e !== 'CollectNDeliver' && e !== 'Unknown') {
                _this.options.push(new NameValueOfInt32({ name: _this.l(e), value: _this.DeliveryTypeValues[e] }));
            }
        });
    };
    DeliveryTypesMultiSelectComponent.prototype.deliveryTypeKeys = function () {
        var keys = Object.keys(this.DeliveryTypeValues);
        return keys.slice(keys.length / 2);
    };
    DeliveryTypesMultiSelectComponent.prototype.onPanelHide = function () {
        document.getElementById(this.name).focus();
    };
    return DeliveryTypesMultiSelectComponent;
}(AppComponentBase));
export { DeliveryTypesMultiSelectComponent };
