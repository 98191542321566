import { Component } from "@angular/core";

import { IntegerWidget } from "ngx-schema-form";

@Component({
    selector: "sf-integer-widget",
    template: `
        <div class="widget form-group kt-form__group row">
            <label [attr.for]="id" class="horizontal control-label col-lg-3">
                {{ schema.title }} <span *ngIf="schema.isRequired" class="m--font-danger">*</span>
            </label>
            <div class="col-lg-9">
                <input
                    [attr.readonly]="schema.readOnly ? true : null"
                    [name]="name"
                    class="text-widget integer-widget form-control"
                    [formControl]="control"
                    [attr.type]="'number'"
                    [attr.min]="schema.minimum"
                    [attr.max]="schema.maximum"
                    [attr.placeholder]="schema.placeholder"
                    [attr.maxLength]="schema.maxLength || null"
                    [attr.minLength]="schema.minLength || null"
                />
                <span *ngIf="schema.description" class="formHelp">{{
                    schema.description
                }}</span>
                <div
                class="has-danger"
                *ngIf="control.invalid && control.errors && control.errors.length"
            >
                <div class="form-control-feedback">
                    {{ control.errors[0].message | localize }}
                </div>
            </div>
            </div>
        </div>
    `
})
export class MetronicsIntegerComponent extends IntegerWidget {}
