import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation } from '@angular/core';
import { NameValueOfString, PriorityType } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'priorityTypesMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './priorityTypes-multiselect.component.html'
})

export class PriorityTypesMultiSelectComponent extends AppComponentBase implements OnInit {

    @Input()
    set selectedItems(items: NameValueOfString[]) {
        this._selectedItems = items || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfString[] {
        return this._selectedItems;
    }
    private _exclude: string[] = [];
    @Input()
    set exclude(exclude: string[]) {
        this._exclude = exclude;
        this.setPriorityTypes();
    }
    @Input() name = 'PriorityTypeFilter';
    @Output() selectedItemsChange: EventEmitter<NameValueOfString[]> = new EventEmitter<NameValueOfString[]>();

    priorityTypeValues = PriorityType;

    options: NameValueOfString[] = new Array<NameValueOfString>();

    private _selectedItems: NameValueOfString[] = [];
    @Input() placeholder = this.l('Priority');

    constructor(injector: Injector) {
       super(injector)
    }

    ngOnInit() {
        this.setPriorityTypes();
    }

    priorityTypeKeys(): Array<string> {
        const keys = Object.keys(this.priorityTypeValues);
        return keys.slice(keys.length / 2);
    }

    setPriorityTypes(): void {
        this.options = [];

        this.priorityTypeKeys().forEach(e => {
            if (!this._exclude.includes(e)) {
                this.options.push(
                    new NameValueOfString({ name: this.l(e), value: this.priorityTypeValues[e] })
                );
            }
        });
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}