import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SLAStatusOutput } from '@shared/service-proxies/service-proxies';
import { DashboardTableField } from '../../models/dashboard-table-field';

@Component({
    selector: 'sla-status-ui',
    templateUrl: './sla-status-ui.component.html',
    styleUrls: ['./sla-status-ui.component.less']
})
export class SLAStatusUiComponent implements OnInit {
    @Input() data: SLAStatusOutput[];
    @Input() field: DashboardTableField;
    @Input() dashboardKeyName: string;
    @Output() clicked = new EventEmitter<object>();

    total = 'total';
    atRisk = 'atRisk';
    breached = 'breached';

    constructor() {}

    ngOnInit() {}

}
