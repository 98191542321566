import { DeliveryType } from "./service-proxies/service-proxies";

export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static instrumentationKey: string;
    static subscriptionExpireNootifyDayCount: number;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
        msapiUserName: 'msapi'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'DelOps'
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };

    static readonly grid = {
        defaultPageSize: 10
    };

    static readonly MinimumUpgradePaymentAmount = 1;
    static readonly regExp = {
        Email: /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&\'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&\'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)+$/
    };

    static readonly defaultCourierCode = 'Nexday';
    static readonly deliveryTypes: string[] = [DeliveryType[DeliveryType.Delivery], DeliveryType[DeliveryType.Redelivery], DeliveryType[DeliveryType.ReturnToSupplier], DeliveryType[DeliveryType.Redirection]];
    static readonly pickuptypes: string[] = [DeliveryType[DeliveryType.Pickup], DeliveryType[DeliveryType.Collect]];
    static readonly consignmentEntityFullName = 'EDG.DelOps.Shipments.Consignment';
    static readonly depoLocationEntityFullName = 'EDG.DelOps.AppConfiguration.DepotLocation';
    static readonly operationalTimeZone = 'Australia/Sydney';

    static readonly pickupTypesObject = { pickup: 'Pickup', collect: 'Collect' };
    static readonly consignmentStatuses = {
        problemPallet: '70',
        readyForLineHaul: '30',
        crossDocked: '41',
        readyToBeCrossdocked: '40',
        withDriver: '51',
        pickupComplete: '87',
        sortInProgress: '20',
        returned : '76',
        transhipinProgress : '64'
    };

    static readonly deliveryWith = {
        ed: 'ED',
        onForwarder: 'OnForwarder',
        threePL: '3PL'
    };

    static readonly loadStatuses = {
        pendingDepart: '1',
        departed: '2',
        arrived: '3',
        sortInProgress: '4',
        sortCompleted: '5'
    };

    static readonly packageEvents = {
        startSort: 'StartSort',
        arrivedAtDepot: 'ArrivedAtDepot',
        loadDeparted: 'LoadDeparted',
        autoRemovedFromLoadForExport: 'AutoRemovedFromLoadForExport',
        removedFromXBLoadForRTSScan: 'RemovedFromXBLoadForRTSScan',
        removedFromRTSLoadForXBScan: 'RemovedFromRTSLoadForXBScan',
        removedFromRTSLoadForCarrierChange: 'RemovedFromRTSLoadForCarrierChange',
        barcodePalletized:'BarcodePalletized',
        barcodeDepalletized:"BarcodeDepalletized",
        barcodeRescheduled: "BarcodeRescheduled",
        barcodeOverriden: "BarcodeOverriden"
    }

    static readonly rescheduleTypes = {
        reschedule: 'reschedule',
        updatePriority: 'updatePriority',
        redelivery: 'redelivery',
        redirection: 'redirection',

    };

    static readonly closedConsignmentStatuses = [
        'Connote Closed',
        'Connote Confirmed',
        'LAI - Complete',
        'Signed - Complete',
        'Pickup Complete'
    ];

    static readonly changeStatusTypeClosedStatuses = [
        'Confirm',
        'LAIComplete',
        'SignComplete',
        'PickUpComplete'
    ];

    static readonly cancelledConsignmentStatuses = [
        'Cancelled-Rollback',
        'Cancelled',
        'Cancelled-Merged'
    ];

    static readonly ZenDeskClosedTicketStatuses: string[] = ['solved', 'closed'];
    static readonly ZenDeskOpenTicketStatuses: string[] = ['open','new','pending','hold'];

    static readonly barcodeType = {
        barcodeNodata: 'barcodesNoData',
        barcodesProcessed: 'barcodesProcessed',
        barcodesFailedValidation: 'barcodesFailedValidation',
        barcodesProducts: 'barcodesProducts'
    };

    public static deliveryWindow =
        {
            nsw: 'App.DeliveryWindow.NSW',
            sa: 'App.DeliveryWindow.SA',
            act: 'App.DeliveryWindow.ACT',
            nt: 'App.DeliveryWindow.NT',
            qld: 'App.DeliveryWindow.QLD',
            wq: 'App.DeliveryWindow.WA',
            vic: 'App.DeliveryWindow.VIC',
            tas: 'App.DeliveryWindow.TAS',

        }

        static readonly orderDeliveryType = {
            unknown : 'Unknown',
            clc : 'CLC',
            clchd : 'CLC_HD',
            hd : 'HD',
            odd : 'ODD',
            ubr : 'UBR'
        };

        static readonly zenDeskTicketStatusTypes ={
            open: 'open',
            new : 'new',
            pending: 'pending',
            hold :'hold',
            solved :'solved',
            closed :'closed'
        };
}
