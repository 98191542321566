import { TooltipConfig } from 'ngx-bootstrap/tooltip';


export class NgxBootstrapTooltipConfigService {

    static getTooltipConfig(): TooltipConfig {
        return Object.assign(new TooltipConfig(), {
        });
    }
}
