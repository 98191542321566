var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileServiceProxy, UserLoginServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
var LoginAttemptsModalComponent = /** @class */ (function (_super) {
    __extends(LoginAttemptsModalComponent, _super);
    function LoginAttemptsModalComponent(injector, _userLoginService, _profileService) {
        var _this = _super.call(this, injector) || this;
        _this._userLoginService = _userLoginService;
        _this._profileService = _profileService;
        _this.profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
        _this.defaultProfilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
        return _this;
    }
    LoginAttemptsModalComponent.prototype.show = function () {
        var _this = this;
        this._userLoginService.getRecentUserLoginAttempts().subscribe(function (result) {
            _this.userLoginAttempts = result.items;
            _this._profileService.getProfilePicture().subscribe(function (result) {
                if (result && result.profilePicture) {
                    _this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
                }
                _this.modal.show();
            });
        });
    };
    LoginAttemptsModalComponent.prototype.close = function () {
        this.modal.hide();
    };
    LoginAttemptsModalComponent.prototype.setProfilePictureClass = function (userLoginAttemptResult) {
        var classes = {
            label: true,
            'label-success': userLoginAttemptResult === 'Success',
            'label-danger': userLoginAttemptResult !== 'Success'
        };
        return classes;
    };
    LoginAttemptsModalComponent.prototype.getLoginAttemptTime = function (userLoginAttempt) {
        return moment(userLoginAttempt.creationTime).fromNow() + ' (' + moment(userLoginAttempt.creationTime).format('YYYY-MM-DD hh:mm:ss') + ')';
    };
    return LoginAttemptsModalComponent;
}(AppComponentBase));
export { LoginAttemptsModalComponent };
