/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sla-status-legend.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sla-status-legend.component";
var styles_SLAStatusLegendComponent = [i0.styles];
var RenderType_SLAStatusLegendComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SLAStatusLegendComponent, data: {} });
export { RenderType_SLAStatusLegendComponent as RenderType_SLAStatusLegendComponent };
export function View_SLAStatusLegendComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "same-line font-weight-bolder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h6", [["class", "font-weight-bolder"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "pl-2 same-line kt-pricing-1__btn btn-group"], ["id", "Rating"], ["role", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-status btn-success btn-sm"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Total"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-status btn-warning btn-sm"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["At Risk"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-status btn-danger btn-sm"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Breached"]))], null, function (_ck, _v) { var currVal_0 = "Legend"; _ck(_v, 4, 0, currVal_0); }); }
export function View_SLAStatusLegendComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sla-status-legend", [], null, null, null, View_SLAStatusLegendComponent_0, RenderType_SLAStatusLegendComponent)), i1.ɵdid(1, 114688, null, 0, i2.SLAStatusLegendComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SLAStatusLegendComponentNgFactory = i1.ɵccf("sla-status-legend", i2.SLAStatusLegendComponent, View_SLAStatusLegendComponent_Host_0, {}, {}, []);
export { SLAStatusLegendComponentNgFactory as SLAStatusLegendComponentNgFactory };
