var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit, OnDestroy } from '@angular/core';
import { HostDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { WidgetComponentBase } from '../widget-component-base';
var WidgetHostTopStatsComponent = /** @class */ (function (_super) {
    __extends(WidgetHostTopStatsComponent, _super);
    function WidgetHostTopStatsComponent(injector, _hostDashboardServiceProxy) {
        var _this = _super.call(this, injector) || this;
        _this._hostDashboardServiceProxy = _hostDashboardServiceProxy;
        _this.selectedDateRange = [moment().add(-7, 'days').startOf('day'), moment().endOf('day')];
        _this.loading = true;
        _this.loadHostTopStatsData = function () {
            _this._hostDashboardServiceProxy.getTopStatsData(_this.selectedDateRange[0], _this.selectedDateRange[1]).subscribe(function (data) {
                _this.topStatsData = data;
                _this.loading = false;
            });
        };
        _this.onDateRangeFilterChange = function (dateRange) {
            if (!dateRange || dateRange.length !== 2 || (_this.selectedDateRange[0] === dateRange[0] && _this.selectedDateRange[1] === dateRange[1])) {
                return;
            }
            _this.selectedDateRange[0] = dateRange[0];
            _this.selectedDateRange[1] = dateRange[1];
            _this.runDelayed(_this.loadHostTopStatsData);
        };
        return _this;
    }
    WidgetHostTopStatsComponent.prototype.ngOnInit = function () {
        this.subDateRangeFilter();
        this.runDelayed(this.loadHostTopStatsData);
    };
    WidgetHostTopStatsComponent.prototype.subDateRangeFilter = function () {
        abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
    };
    WidgetHostTopStatsComponent.prototype.unSubDateRangeFilter = function () {
        abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
    };
    WidgetHostTopStatsComponent.prototype.ngOnDestroy = function () {
        this.unSubDateRangeFilter();
    };
    return WidgetHostTopStatsComponent;
}(WidgetComponentBase));
export { WidgetHostTopStatsComponent };
