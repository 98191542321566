/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./widget-daily-sales.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-perfect-scrollbar";
import * as i3 from "../../../../../../../node_modules/@swimlane/ngx-charts/release/bar-chart/bar-vertical.component.ngfactory";
import * as i4 from "@swimlane/ngx-charts/release/bar-chart/bar-vertical.component";
import * as i5 from "./widget-daily-sales.component";
import * as i6 from "../../../../../../shared/service-proxies/service-proxies";
var styles_WidgetDailySalesComponent = [i0.styles];
var RenderType_WidgetDailySalesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WidgetDailySalesComponent, data: {} });
export { RenderType_WidgetDailySalesComponent as RenderType_WidgetDailySalesComponent };
function View_WidgetDailySalesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Day ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.model.name; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.model.value; _ck(_v, 4, 0, currVal_1); }); }
export function View_WidgetDailySalesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "kt-portlet kt-portlet--height-fluid "]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "kt-portlet__head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "kt-portlet__head-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "kt-portlet__head-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Daily Sales "])), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "kt-portlet__body"]], null, null, null, null, null)), i1.ɵdid(6, 999424, null, 0, i2.PerfectScrollbarDirective, [i1.NgZone, i1.KeyValueDiffers, i1.ElementRef, i1.PLATFORM_ID, [2, i2.PERFECT_SCROLLBAR_CONFIG]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "col-md-12"], ["style", "max-height: 200px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "ngx-charts-bar-vertical", [], null, null, null, i3.View_BarVerticalComponent_0, i3.RenderType_BarVerticalComponent)), i1.ɵdid(10, 4898816, null, 1, i4.BarVerticalComponent, [i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef], { results: [0, "results"], scheme: [1, "scheme"] }, null), i1.ɵqud(603979776, 1, { tooltipTemplate: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["tooltipTemplate", 2]], null, 0, null, View_WidgetDailySalesComponent_1))], function (_ck, _v) { var _co = _v.component; _ck(_v, 6, 0); var currVal_0 = _co.dailySalesLineChart.chartData; var currVal_1 = _co.dailySalesLineChart.scheme; _ck(_v, 10, 0, currVal_0, currVal_1); }, null); }
export function View_WidgetDailySalesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-widget-daily-sales", [], null, null, null, View_WidgetDailySalesComponent_0, RenderType_WidgetDailySalesComponent)), i1.ɵdid(1, 245760, null, 0, i5.WidgetDailySalesComponent, [i1.Injector, i6.TenantDashboardServiceProxy], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WidgetDailySalesComponentNgFactory = i1.ɵccf("app-widget-daily-sales", i5.WidgetDailySalesComponent, View_WidgetDailySalesComponent_Host_0, { widget: "widget", page: "page" }, { onSaveWidgetSettings: "onSaveWidgetSettings" }, []);
export { WidgetDailySalesComponentNgFactory as WidgetDailySalesComponentNgFactory };
