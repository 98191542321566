var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { SalesSummaryDatePeriod, TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
var SalesSummaryChart = /** @class */ (function (_super) {
    __extends(SalesSummaryChart, _super);
    function SalesSummaryChart(_dashboardService) {
        var _this = _super.call(this) || this;
        _this._dashboardService = _dashboardService;
        _this.totalSales = 0;
        _this.totalSalesCounter = 0;
        _this.revenue = 0;
        _this.revenuesCounter = 0;
        _this.expenses = 0;
        _this.expensesCounter = 0;
        _this.growth = 0;
        _this.growthCounter = 0;
        _this.data = [];
        return _this;
    }
    SalesSummaryChart.prototype.init = function (salesSummaryData, totalSales, revenue, expenses, growth) {
        this.totalSales = totalSales;
        this.totalSalesCounter = totalSales;
        this.revenue = revenue;
        this.expenses = expenses;
        this.growth = growth;
        this.setChartData(salesSummaryData);
        this.hideLoading();
    };
    SalesSummaryChart.prototype.setChartData = function (items) {
        var sales = [];
        var profit = [];
        _.forEach(items, function (item) {
            sales.push({
                'name': item['period'],
                'value': item['sales']
            });
            profit.push({
                'name': item['period'],
                'value': item['profit']
            });
        });
        this.data = [
            {
                'name': 'Sales',
                'series': sales
            }, {
                'name': 'Profit',
                'series': profit
            }
        ];
    };
    SalesSummaryChart.prototype.reload = function (datePeriod) {
        var _this = this;
        if (this.selectedDatePeriod === datePeriod) {
            this.hideLoading();
            return;
        }
        this.selectedDatePeriod = datePeriod;
        this.showLoading();
        this._dashboardService
            .getSalesSummary(datePeriod)
            .subscribe(function (result) {
            _this.setChartData(result.salesSummary);
            _this.hideLoading();
        });
    };
    return SalesSummaryChart;
}(DashboardChartBase));
var WidgetSalesSummaryComponent = /** @class */ (function (_super) {
    __extends(WidgetSalesSummaryComponent, _super);
    function WidgetSalesSummaryComponent(injector, _tenantDashboardServiceProxy) {
        var _this = _super.call(this, injector) || this;
        _this._tenantDashboardServiceProxy = _tenantDashboardServiceProxy;
        _this.appSalesSummaryDateInterval = SalesSummaryDatePeriod;
        _this.onDateRangeFilterChange = function (dateRange) {
            _this.runDelayed(function () {
                _this.salesSummaryChart.reload(SalesSummaryDatePeriod.Daily);
            });
        };
        _this.salesSummaryChart = new SalesSummaryChart(_this._tenantDashboardServiceProxy);
        return _this;
    }
    WidgetSalesSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subDateRangeFilter();
        this.runDelayed(function () {
            _this.salesSummaryChart.reload(SalesSummaryDatePeriod.Daily);
        });
    };
    WidgetSalesSummaryComponent.prototype.subDateRangeFilter = function () {
        abp.event.on('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
    };
    WidgetSalesSummaryComponent.prototype.unSubDateRangeFilter = function () {
        abp.event.off('app.dashboardFilters.dateRangePicker.onDateChange', this.onDateRangeFilterChange);
    };
    WidgetSalesSummaryComponent.prototype.ngOnDestroy = function () {
        this.unSubDateRangeFilter();
    };
    return WidgetSalesSummaryComponent;
}(WidgetComponentBase));
export { WidgetSalesSummaryComponent };
