var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, Injector } from '@angular/core';
import { NameValueOfInt32, AppConfigurationLookupServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
var ManifestFileAutoCompleteComponent = /** @class */ (function (_super) {
    __extends(ManifestFileAutoCompleteComponent, _super);
    function ManifestFileAutoCompleteComponent(injector, _appConfigurationLookupServiceProxy) {
        var _this = _super.call(this, injector) || this;
        _this._appConfigurationLookupServiceProxy = _appConfigurationLookupServiceProxy;
        _this.filteredManifestFiles = [];
        _this.manifestFiles = new Array();
        _this.searchString = "";
        _this.dropDownActive = false;
        _this.resultCount = 25;
        _this.multiple = false;
        _this.styleClasses = 'width-percent-100';
        _this.manifestFileChange = new EventEmitter();
        _this.getManifestFiles(_this.searchString, _this.resultCount).subscribe(function (manifestFiles) {
            _this.manifestFiles = manifestFiles;
        });
        return _this;
    }
    ManifestFileAutoCompleteComponent.prototype.filterManifestFiles = function (event) {
        var _this = this;
        this.filteredManifestFiles = this.manifestFiles.filter(function (nv) {
            return nv.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1;
        });
        if (this.filteredManifestFiles.length === 0 || event.query.length % 3 === 0) {
            this.getManifestFiles(event.query.toLowerCase(), this.resultCount).subscribe(function (manifestFiles) {
                _this.manifestFiles = manifestFiles;
            });
        }
    };
    ManifestFileAutoCompleteComponent.prototype.getManifestFiles = function (searchString, resultCount) {
        return this._appConfigurationLookupServiceProxy.getManifestFiles(searchString, resultCount);
    };
    return ManifestFileAutoCompleteComponent;
}(AppComponentBase));
export { ManifestFileAutoCompleteComponent };
