/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sla-status-ui.component.less.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./sla-status-ui.component";
var styles_SLAStatusUiComponent = [i0.styles];
var RenderType_SLAStatusUiComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SLAStatusUiComponent, data: {} });
export { RenderType_SLAStatusUiComponent as RenderType_SLAStatusUiComponent };
export function View_SLAStatusUiComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "kt-pricing-1__btn btn-group"], ["id", "Rating"], ["role", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-status btn-success"], ["title", "Total"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clicked.emit({ xFilter: _co.data[_co.dashboardKeyName], yFilter: _co.field.localizeKey, zFilter: _co.total }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-status btn-warning"], ["title", "At Risk"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clicked.emit({ xFilter: _co.data[_co.dashboardKeyName], yFilter: _co.field.localizeKey, zFilter: _co.atRisk }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-status btn-danger"], ["title", "Breached"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clicked.emit({ xFilter: _co.data[_co.dashboardKeyName], yFilter: _co.field.localizeKey, zFilter: _co.breached }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data[_co.field.key].total; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.data[_co.field.key].atRisk; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.data[_co.field.key].breached; _ck(_v, 6, 0, currVal_2); }); }
export function View_SLAStatusUiComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sla-status-ui", [], null, null, null, View_SLAStatusUiComponent_0, RenderType_SLAStatusUiComponent)), i1.ɵdid(1, 114688, null, 0, i2.SLAStatusUiComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SLAStatusUiComponentNgFactory = i1.ɵccf("sla-status-ui", i2.SLAStatusUiComponent, View_SLAStatusUiComponent_Host_0, { data: "data", field: "field", dashboardKeyName: "dashboardKeyName" }, { clicked: "clicked" }, []);
export { SLAStatusUiComponentNgFactory as SLAStatusUiComponentNgFactory };
