var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnDestroy } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { timer } from 'rxjs';
import { AppAuthService } from '../auth/app-auth.service';
import { SessionServiceProxy } from '@shared/service-proxies/service-proxies';
import { LocalStorageService } from '@shared/utils/local-storage.service';
var SessionTimeoutModalComponent = /** @class */ (function (_super) {
    __extends(SessionTimeoutModalComponent, _super);
    function SessionTimeoutModalComponent(injector, _appAuthService, _sessionService, _localStorageService) {
        var _this = _super.call(this, injector) || this;
        _this._appAuthService = _appAuthService;
        _this._sessionService = _sessionService;
        _this._localStorageService = _localStorageService;
        _this.timeOutSecond = parseInt(_this.s('App.UserManagement.SessionTimeOut.ShowTimeOutNotificationSecond')); // show inactivity modal when TimeOutSecond passed
        _this.progresbarWidth = '100%';
        return _this;
    }
    SessionTimeoutModalComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    SessionTimeoutModalComponent.prototype.start = function () {
        var _this = this;
        this.currentSecond = this.timeOutSecond;
        this.subscription = timer(0, 1000).subscribe(function () {
            _this.changeNotifyContent();
        });
        this.modal.show();
    };
    SessionTimeoutModalComponent.prototype.stop = function () {
        this.currentSecond = this.timeOutSecond;
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.modal.hide();
    };
    SessionTimeoutModalComponent.prototype.done = function () {
        this.stop();
        var isSessionLockScreenEnabled = abp.setting.getBoolean('App.UserManagement.SessionTimeOut.ShowLockScreenWhenTimedOut');
        if (isSessionLockScreenEnabled) {
            this.redirectToLockScreen();
        }
        else {
            this._appAuthService.logout(true);
        }
    };
    SessionTimeoutModalComponent.prototype.changeNotifyContent = function () {
        this.currentSecond--;
        if (this.currentSecond <= 0) {
            this.progresbarWidth = '0%';
            this.done();
        }
        else {
            this.progresbarWidth = (this.currentSecond / this.timeOutSecond * 100) + '%';
        }
    };
    SessionTimeoutModalComponent.prototype.redirectToLockScreen = function () {
        var _this = this;
        this._sessionService.getCurrentLoginInformations()
            .subscribe(function (info) {
            if (info) {
                abp.utils.setCookieValue('userInfo', JSON.stringify({
                    userName: info.user.userName,
                    profilePictureId: info.user.profilePictureId,
                    tenant: info.tenant ? info.tenant.tenancyName : 'Host'
                }), null, abp.appPath);
                _this._appAuthService.logout(true, '/account/session-locked');
            }
            else {
                _this._appAuthService.logout(true);
            }
        }, function () {
            _this._appAuthService.logout(true);
        });
    };
    return SessionTimeoutModalComponent;
}(AppComponentBase));
export { SessionTimeoutModalComponent };
