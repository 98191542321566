import { Component } from '@angular/core';

import { SelectWidget } from 'ngx-schema-form';

@Component({
	selector: 'sf-select-widget',
	template: `<div class="widget form-group kt-form__group row">
	<label [attr.for]="id" class="horizontal control-label  col-lg-3">
		{{ schema.title }}  <span *ngIf="schema.isRequired" class="m--font-danger">*</span>
	</label>
	<div class="col-lg-9">
		<span *ngIf="schema.description" class="formHelp">
			{{schema.description}}
		</span>

		<select *ngIf="schema.type!='array'" [formControl]="control" [attr.name]="name" [disabled]="schema.readOnly" class="form-control">
			<option *ngFor="let option of schema.oneOf" [ngValue]="option.enum[0]" >{{option.description}}</option>
		</select>

		<select *ngIf="schema.type==='array'" multiple [formControl]="control" [attr.name]="name" [disabled]="schema.readOnly" class="form-control">
			<option *ngFor="let option of schema.items.oneOf" [ngValue]="option.enum[0]" >{{option.description}}</option>
		</select>

		<input *ngIf="schema.readOnly" [attr.name]="name" type="hidden" [formControl]="control">
		<div
		class="has-danger"
		*ngIf="control.invalid && control.errors && control.errors.length"
	>
		<div class="form-control-feedback">
			{{ control.errors[0].message | localize }}
		</div>
	</div>
	</div>
</div>`
})
export class MetronicsSelectComponent extends SelectWidget {}
