import { PopoverConfig } from 'ngx-bootstrap/popover';


export class NgxBootstrapPopoverConfigService {

    static getPopoverConfig(): PopoverConfig {
        return Object.assign(new PopoverConfig(), {
        });
    }
}
