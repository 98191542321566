import { AppComponentBase } from '@shared/common/app-component-base';
import { OnDestroy, Injector, Input, EventEmitter, Output } from '@angular/core';
import { timer, Subscription } from 'rxjs';
import { Widget, Page } from '@shared/service-proxies/service-proxies';

export interface IWidgetDataEvent {
    settings: any;
    pageId: string;
    widgetId: string;
}

export abstract class WidgetComponentBase extends AppComponentBase implements OnDestroy {
    delay = 300;
    timer: Subscription;
    editModeEnabled = false;
    @Input() widget: any;
    @Input() page: Page;
    @Output() onSaveWidgetSettings = new EventEmitter<IWidgetDataEvent>();

    constructor(injector: Injector) {
        super(injector);
        this.subEditModeEnabled();
    }

    /**
     * Run methods delayed. If runDelay called multiple time before its delay, only run last called.
     * @param method Method to call
     */
    runDelayed(method: () => void) {
        if (this.timer && !this.timer.closed) {
            this.timer.unsubscribe();
        }

        this.timer = timer(this.delay).subscribe(() => {
            method();
        });
    }

    saveWidgetData(data: any) {
        if (!this.widget.settings) {
            this.widget.settings = {};
        } else {
            this.widget.settings.Data = data;
        }
        this.onSaveWidgetSettings.emit(<IWidgetDataEvent>{
            settings: this.widget.settings,
            pageId: this.page.id,
            widgetId: this.widget.id
        });
    }

    saveWidgetTitle(title: string) {
        if (!this.widget.settings) {
            this.widget.settings = {};
            this.widget.settings.Title = 'Title';
        } else {
            this.widget.settings.Title = title;
        }
        this.onSaveWidgetSettings.emit(<IWidgetDataEvent>{
            settings: this.widget.settings,
            pageId: this.page.id,
            widgetId: this.widget.id
        });
    }

    subEditModeEnabled() {
        abp.event.on('app.customizableDashboard.onEditModeEnabled', editModeEnabled => { this.editModeEnabled = editModeEnabled; });
    }

    unSubEditModeEnabled() {
        abp.event.off('app.customizableDashboard.onEditModeEnabled', editModeEnabled => { this.editModeEnabled = editModeEnabled; });
    }

    ngOnDestroy(): void {
        if (this.timer && !this.timer.closed) {
            this.timer.unsubscribe();
        }
        this.unSubEditModeEnabled();
    }
}
