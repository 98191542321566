import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation } from '@angular/core';
import { NameValueOfString } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConfigurationService } from '@app/shared/services/appconfigration.service';

@Component({
    selector: 'consignmentStatusMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './consignmentStatus-multiselect.component.html'
})

export class ConsignmentStatusMultiSelectComponent extends AppComponentBase implements OnInit {
    @Input()
    set selectedItems(items: NameValueOfString[]) {
        this._selectedItems = items || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfString[] {
        return this._selectedItems;
    }
    @Input() name = 'consignmentStatusFilter';
    @Input() visibleFilter: Boolean = false;
    @Output() selectedItemsChange: EventEmitter<NameValueOfString[]> = new EventEmitter<NameValueOfString[]>();

    placeholder = this.l('ConsignmentStatus');
    options: NameValueOfString[] = new Array<NameValueOfString>();
    private _selectedItems: NameValueOfString[] = [];

    constructor(injector: Injector, private _appConfigurationService: AppConfigurationService) {
       super(injector)
    }

    ngOnInit() {
        this.getConsignmentStatuses();
    }

    getConsignmentStatuses() {
        if (!!this.visibleFilter) {
            this._appConfigurationService.getClientVisibleConsignmentStatuss()
                .subscribe(consignmentStatuses => this.options = consignmentStatuses.map((r: NameValueOfString) => <NameValueOfString>{name: `${r.name} (${r.value})`, value: r.value}));
        } else {
            this._appConfigurationService.getConsignmentStatuss()
                .subscribe(consignmentStatuses => this.options = consignmentStatuses.map((r: NameValueOfString) => <NameValueOfString>{name: `${r.name} (${r.value})`, value: r.value}));
        }
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}