import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation } from '@angular/core';
import { NameValueOfString } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '@shared/AppConsts';


@Component({
  selector: 'deliveryWithMultiselect',
  templateUrl: './delivery-with-multiselect.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class DeliveryWithMultiselectComponent extends AppComponentBase implements OnInit {

  @Input()
  set selectedItems(items: NameValueOfString[]) {
      this._selectedItems = items || [];
      this.selectedItemsChange.emit(this._selectedItems);
  };
  get selectedItems(): NameValueOfString[] {
      return this._selectedItems;
  }
  private _exclude: string[] = [];
  @Input()
  set exclude(exclude: string[]) {
      this._exclude = exclude;
      this.setDeliveryWithOptions();
  }
  @Input() name = 'DeliveryWithFilter';
  @Output() selectedItemsChange: EventEmitter<NameValueOfString[]> = new EventEmitter<NameValueOfString[]>();

  deliveryWithValues = AppConsts.deliveryWith;

  options: NameValueOfString[] = new Array<NameValueOfString>();

  private _selectedItems: NameValueOfString[] = [];
  @Input() placeholder = this.l('DeliveryWith');

  constructor(injector: Injector) {
     super(injector)
  }

  ngOnInit() {
      this.setDeliveryWithOptions();
  }

  deliveryWithKeys(): Array<string> {
    return [ this.deliveryWithValues.ed, this.deliveryWithValues.onForwarder, this.deliveryWithValues.threePL];
  }

  setDeliveryWithOptions(): void {
      this.options = [];

      this.deliveryWithKeys().forEach(e => {
          if (!this._exclude.includes(e)) {
            this.options.push(
                new NameValueOfString({ name: this.l(e), value: e})
            );
          }
      });
  }

  onPanelHide() {
      document.getElementById(this.name).focus();
  }
}