import { NavigationEnd, Router, Routes } from '@angular/router';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
var ɵ0 = function () { return import("./account/account.module.ngfactory").then(function (m) { return m.AccountModuleNgFactory; }); }, ɵ1 = { preload: true };
var routes = [
    { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
    {
        path: 'account',
        loadChildren: ɵ0,
        data: ɵ1
    },
    { path: '**', redirectTo: '/app/main/dashboard' }
];
var RootRoutingModule = /** @class */ (function () {
    function RootRoutingModule(router, _uiCustomizationService) {
        var _this = this;
        this.router = router;
        this._uiCustomizationService = _uiCustomizationService;
        router.events.subscribe(function (event) {
            setTimeout(function () {
                _this.toggleBodyCssClass(event.url);
            }, 0);
        });
    }
    RootRoutingModule.prototype.toggleBodyCssClass = function (url) {
        if (url) {
            if (url === '/') {
                if (abp.session.userId > 0) {
                    this.setAppModuleBodyClassInternal();
                }
                else {
                    this.setAccountModuleBodyClassInternal();
                }
            }
            if (url.indexOf('/account/') >= 0) {
                this.setAccountModuleBodyClassInternal();
            }
            else {
                this.setAppModuleBodyClassInternal();
            }
        }
    };
    RootRoutingModule.prototype.setAppModuleBodyClassInternal = function () {
        var currentBodyClass = document.body.className;
        var classesToRemember = '';
        if (currentBodyClass.indexOf('m-brand--minimize') >= 0) {
            classesToRemember += ' m-brand--minimize ';
        }
        if (currentBodyClass.indexOf('m-aside-left--minimize') >= 0) {
            classesToRemember += ' m-aside-left--minimize';
        }
        if (currentBodyClass.indexOf('m-brand--hide') >= 0) {
            classesToRemember += ' m-brand--hide';
        }
        if (currentBodyClass.indexOf('m-aside-left--hide') >= 0) {
            classesToRemember += ' m-aside-left--hide';
        }
        if (currentBodyClass.indexOf('swal2-shown') >= 0) {
            classesToRemember += ' swal2-shown';
        }
        if (currentBodyClass.indexOf('swal2-toast-shown') >= 0) {
            classesToRemember += ' swal2-toast-shown';
        }
        document.body.className = this._uiCustomizationService.getAppModuleBodyClass() + ' ' + classesToRemember;
    };
    RootRoutingModule.prototype.setAccountModuleBodyClassInternal = function () {
        var currentBodyClass = document.body.className;
        var classesToRemember = '';
        if (currentBodyClass.indexOf('swal2-shown') >= 0) {
            classesToRemember += ' swal2-shown';
        }
        if (currentBodyClass.indexOf('swal2-toast-shown') >= 0) {
            classesToRemember += ' swal2-toast-shown';
        }
        document.body.className = this._uiCustomizationService.getAccountModuleBodyClass() + ' ' + classesToRemember;
    };
    RootRoutingModule.prototype.getSetting = function (key) {
        return abp.setting.get(key);
    };
    return RootRoutingModule;
}());
export { RootRoutingModule };
export { ɵ0, ɵ1 };
