<form class="horizontal-form" (ngSubmit)="searchConsignments()" autocomplete="off">
    <div class="kt-form kt-form--label-align-right">
        <div class="row m--margin-bottom-10" [hidden]="!advancedFiltersAreShown">
            <ng-container *ngIf="!dashboardViewFilter">
                <div class="col-md-3">
                    <bigInput labelResourceKey="ConsignmentFilterName"
                        [input]="consignmentNumberFilter" (onChange)="onConsignmentsChange($event)"
                        id="ConsignmentNumberFilter" name="consignmentNumberFilter"></bigInput>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="TypeFilter">{{l("ConsignmentType")}}</label>
                        <deliveryTypesMultiselect [(selectedItems)]="typeFilter"></deliveryTypesMultiselect>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                    <label for="ClientFilter">{{l("Client")}}</label>
                    <clientCodesMultiselect [(selectedItems)]="clientIdFilter"></clientCodesMultiselect>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="ExitHubFilter">{{l("ExitHub")}}</label>
                        <depotLocationMultiselect [name]="'ExitHubFilter'" [(selectedItems)]="exitHubFilter"></depotLocationMultiselect>
                    </div>
                </div>


                <div class="col-md-3">
                    <bigInput labelResourceKey="Barcode" [input]="barcodeFilter"
                        (onChange)="onBarcodesChange($event)" id="BarcodeFilter"
                        name="barcodeFilter">
                    </bigInput>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="consignmentStatusFilter">{{l("ConsignmentStatus")}}</label>
                        <consignmentStatusMultiselect [(selectedItems)]="consignmentStatusFilter"></consignmentStatusMultiselect>
                    </div>
                </div>


                <div class="col-md-3">
                    <div class="form-group">
                        <label for="ClientGroupFilter">{{l("ClientGroup")}}</label>
                        <clientGroupsMultiselect [(selectedItems)]="clientGroupFilter"></clientGroupsMultiselect>
                    </div>
                </div>


                <div class="col-md-3">
                    <div class="form-group">
                        <label for="CarrierCodeFilter">{{l("CarrierCode")}}</label>
                        <carrierMultiselect [(selectedItems)]="carrierCodeFilter"></carrierMultiselect>
                    </div>
                </div>

                <div class="col-md-3">
                    <bigInput labelResourceKey="OrderNumber" [input]="orderNumberFilter"
                        (onChange)="onOrderNumbersChange($event)" id="OrderNumberFilter"
                        name="orderNumberFilter">
                    </bigInput>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="TerritoryFilter">{{l("Territory")}}</label>
                        <territoryConfigurationMultiselect [name]="TerritoryFilter" [(selectedItems)]="territoryFilter"></territoryConfigurationMultiselect>
                    </div>
                </div>


                <div class="col-md-3">
                    <div class="form-group">
                        <label for="ExceptionType">{{l("Exception")}}</label>
                        <eventDetailZendeskMultiselect [(selectedItems)]="exceptionFilter"></eventDetailZendeskMultiselect>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="BrandFilter">{{l("Brand")}}</label>
                        <brandsMultiselect [(selectedItems)]="brandFilter"></brandsMultiselect>
                    </div>
                </div>
                <div class="col-md-3">
                    <bigInput labelResourceKey="ExternalVendorOrderNumbersFilterName"
                        [input]="externalVendorOrderNumbersFilter" (onChange)="onExternalVendorOrderNumbersFilterChange($event)"
                        id="ExternalVendorOrderNumbersFilter" name="externalVendorOrderNumbersFilter"></bigInput>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="CustomerNameFilter">{{l("CustomerName")}}</label>
                        <input type="text" class="form-control" id="CustomerNameFilter"
                            name="customerNameFilter" [(ngModel)]="customerNameFilter">
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="ClientFileNameFilter">{{l("ClientFileName")}}</label>
                        <manifestFileAutoComplete [(manifestFile)]="clientFileNameFilter" [multiple]="true"
                            name="clientFileNameFilter"></manifestFileAutoComplete>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="DeliveryHubFilter">{{l("DeliveryHub")}}</label>
                        <depotLocationMultiselect [name]="'DeliveryHubFilter'" [(selectedItems)]="deliveryHubFilter"></depotLocationMultiselect>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="PriorityTypeFilter">{{l("Tags")}}</label>
                        <priorityTypesMultiselect [(selectedItems)]="priorityFilter" [placeholder]="l('Tags')"></priorityTypesMultiselect>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <bigInput labelResourceKey="{{l('DestinationPostcode')}}" [input]="destinationPostcodeFilter"
                            (onChange)="destinationPostcodeFilter = $event" [validateNumbers]="true"
                            id="PostCode" name="PostCode">
                        </bigInput>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="FieldName">{{l("FieldName")}}</label>
                        <select class="form-control" name="FieldName" id="FieldNameSelect"
                            [(ngModel)]="fieldName">
                            <option value=""></option>
                            <option value="loadIdFilter">{{l("LoadId")}}</option>
                            <option value="batchIdFilter">{{l('BatchId')}}</option>
                            <option value="xBorderIdFilter">{{l('CrossBorderId')}}</option>
                            <option value="palletIdFilter">{{l('PalletId')}}</option>
                            <option value="palletConnoteFilter">{{l('PalletConnoteNo')}}</option>
                            <option value="returnToSupplierIdFilter">{{l('ReturnToSupplierId')}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <bigInput labelResourceKey="FieldValue" [input]="fieldValue"
                            (onChange)="setFieldFilters($event)" [validateNumbers]="fieldName!=='palletConnoteFilter'"
                            id="FieldValue" name="fieldValue">
                        </bigInput>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="DeliveryWithFilter">{{l("DeliveryWith")}}</label>
                        <deliveryWithMultiselect [name]="'DeliveryWithFilter'" [(selectedItems)]="deliveryWithFilter"></deliveryWithMultiselect>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="MinLeadTimeFilter">{{l("RescheduledCount")}}</label>
                        <div class="input-group">
                            <input type="number" class="form-control" min="0" id="MinRescheduledCountFilter"
                                [(ngModel)]="minRescheduledCountFilter" placeholder="{{l('MinValue')}}"
                                name="minRescheduledCountFilter" />
                            <input type="number" class="form-control" min="0" [(ngModel)]="maxRescheduledCountFilter"
                                placeholder="{{l('MaxValue')}}" name="maxRescheduledCountFilter" />
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="RegionTypeFilter">{{l("RegionType")}}</label>
                        <regionTypesMultiselect [(selectedItems)]="regionTypeFilter" [placeholder]="l('RegionType')"></regionTypesMultiselect>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="RegionValueFilter">{{l("RegionValue")}}</label>
                        <regionValuesMultiselect [(selectedItems)]="regionValueFilter" [placeholder]="l('RegionValue')"></regionValuesMultiselect>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="HeatStatusFilter">{{l("HeatStatus")}}</label>
                        <heatStatusMultiselect [(selectedItems)]="heatStatusFilter"
                        [exclude]="['None']"></heatStatusMultiselect>
                    </div>
                </div>
                <div class="col-md-1">
                    <div class="form-group">
                        <label for="removedFromDashboardFilter">{{l("Removed")}}</label>
                        <span class="kt-switch kt-switch--icon kt-switch-form">
                            <label class="mt-1 mb-0">
                                <input type="checkbox" (change)="searchConsignments()"
                                    [(ngModel)]="removedFromDashboardFilter"
                                    name="removedFromDashboardFilter"
                                    id="removedFromDashboardFilter">
                                <span></span>
                            </label>
                        </span>
                    </div>
                </div>
                <div class="col-md-3"> 
                    <div class="form-group">
                        <label for="PalletFilter">Palletisation Options</label>
                        <palletisedFilterMultiselect [(selectedItems)]="palletisedFilter"></palletisedFilterMultiselect>
                    </div>
                </div>
            </ng-container>
            <div class="col-md-1">
                <div class="form-group">
                    <label for="actionedFilter">{{l("Actioned")}}</label>
                    <span class="kt-switch kt-switch--icon kt-switch-form">
                        <label class="mt-1 mb-0">
                            <input type="checkbox" (change)="searchConsignments()"
                                [(ngModel)]="actionedFilter" name="actionedFilter"
                                id="actionedFilter">
                            <span></span>
                        </label>
                    </span>
                </div>
            </div>
            <div *ngIf="!dashboardViewFilter" class="col-md-1 row justify-content-end mr-0 pr-0">
                <div class="d-flex h-100 ml-auto">
                    <button class="btn btn-primary align-self-center ml-auto mr-2" type="submit"><i
                            class="flaticon-search-1"></i>{{searchLabel}}</button>
                            <a class=" align-self-center" href="javascript: void(0);"
                                (click)="resetFilters()"> {{l('Reset')}}</a>
                </div>
            </div>
        </div>
        <div class="row align-items-start">
            <span class="clickable-item text-muted align-middle " *ngIf="!advancedFiltersAreShown"
                (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i
                    class="fa fa-angle-down"></i> {{l("ShowAdvancedFilters")}}</span>
            <span class="clickable-item text-muted align-middle" *ngIf="advancedFiltersAreShown"
                (click)="advancedFiltersAreShown=!advancedFiltersAreShown"><i
                    class="fa fa-angle-up"></i> {{l("HideAdvancedFilters")}}</span>
        </div>
    </div>
</form>
