var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, EventEmitter, Injector } from '@angular/core';
import { AppConfigurationLookupServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
var ClientGroupsMultiSelectComponent = /** @class */ (function (_super) {
    __extends(ClientGroupsMultiSelectComponent, _super);
    function ClientGroupsMultiSelectComponent(injector, _appConfigurationLookupServiceProxy) {
        var _this = _super.call(this, injector) || this;
        _this._appConfigurationLookupServiceProxy = _appConfigurationLookupServiceProxy;
        _this.name = 'ClientGroupFilter';
        _this.selectedItemsChange = new EventEmitter();
        _this.placeholder = _this.l('ClientGroup');
        _this.options = new Array();
        _this.filteredclientGroups = [];
        _this.searchString = "";
        _this._selectedItems = [];
        return _this;
    }
    Object.defineProperty(ClientGroupsMultiSelectComponent.prototype, "selectedItems", {
        get: function () {
            return this._selectedItems;
        },
        set: function (items) {
            this._selectedItems = items || [];
            this.selectedItemsChange.emit(this._selectedItems);
        },
        enumerable: true,
        configurable: true
    });
    ;
    ClientGroupsMultiSelectComponent.prototype.ngOnInit = function () {
        this.getClientGroups(this.searchString);
    };
    ClientGroupsMultiSelectComponent.prototype.getClientGroups = function (searchString) {
        var _this = this;
        this._appConfigurationLookupServiceProxy.getClientGroups(searchString)
            .subscribe(function (clientGroups) {
            _this.options = clientGroups.map(function (e) { return ({ name: e.name, value: e.value.id }); });
        });
    };
    ClientGroupsMultiSelectComponent.prototype.onPanelHide = function () {
        document.getElementById(this.name).focus();
    };
    return ClientGroupsMultiSelectComponent;
}(AppComponentBase));
export { ClientGroupsMultiSelectComponent };
