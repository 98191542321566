import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation } from '@angular/core';
import { NameValueOfInt32 } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConfigurationService } from '@app/shared/services/appconfigration.service';

@Component({
    selector: 'territoryConfigurationMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './territory-configuration-multiselect.component.html'
})

export class TerritoryConfigurationMultiSelectComponent extends AppComponentBase implements OnInit {
    @Input()
    set selectedItems(items: NameValueOfInt32[]) {
        this._selectedItems = items || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfInt32[] {
        return this._selectedItems;
    }
    @Input() name = 'territoryConfigurationMultiselect'
    @Output() selectedItemsChange: EventEmitter<NameValueOfInt32[]> = new EventEmitter<NameValueOfInt32[]>();

    options: NameValueOfInt32[] = new Array<NameValueOfInt32>();

    private _selectedItems: NameValueOfInt32[] = [];

    placeholder = this.l('Territory');

    constructor(injector: Injector, private _appConfigurationService: AppConfigurationService) {
       super(injector)
    }

    ngOnInit() {
        if (this.selectedItems.length) {
            this.getTerritoryConfigurations();
        }
    }

    getTerritoryConfigurations() {
        this._appConfigurationService.getTerritoryConfigurations()
            .subscribe(territoryConfigurations => {
                this.options = territoryConfigurations;
            });
    }

    onPanelShow() {
        if (!this.options.length) {
            this.getTerritoryConfigurations();
        }
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}