import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation } from '@angular/core';
import { NameValueOfInt32, DeliveryType } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'deliveryTypesMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './deliveryTypes-multiselect.component.html'
})

export class DeliveryTypesMultiSelectComponent extends AppComponentBase implements OnInit {

    @Input()
    set selectedItems(items: NameValueOfInt32[]) {
        this._selectedItems = items || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfInt32[] {
        return this._selectedItems;
    }
    @Input() name = 'TypeFilter';
    @Output() selectedItemsChange: EventEmitter<NameValueOfInt32[]> = new EventEmitter<NameValueOfInt32[]>();

    DeliveryTypeValues = DeliveryType;

    options: NameValueOfInt32[] = new Array<NameValueOfInt32>();

    placeholder = this.l("ConsignmentType");

    private _selectedItems: NameValueOfInt32[] = [];

    constructor(injector: Injector) {
       super(injector)
    }

    ngOnInit() {
        this.deliveryTypeKeys().forEach(e => {
            if (e !== 'CollectNDeliver' && e !== 'Unknown') {
                this.options.push(
                    new NameValueOfInt32({ name: this.l(e), value: this.DeliveryTypeValues[e] })
                );
            }
        });
    }

    deliveryTypeKeys(): Array<string> {
        const keys = Object.keys(this.DeliveryTypeValues);
        return keys.slice(keys.length / 2);
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}
