import { NgModule, ModuleWithProviders } from '@angular/core';
import { DashboardOrderService } from './dashboard-order.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: []
})
export class DashboardOrderModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: DashboardOrderModule,
            providers: [DashboardOrderService]
        };
    }
}
