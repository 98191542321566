/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./footer.component";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
function View_FooterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "kt-footer__bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "kt-footer__copyright"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Eden "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(6, null, [" | v", " [", "] "])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "kt-footer__menu"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.appSession.tenant && _co.appSession.tenant.edition) && _co.appSession.tenant.edition.displayName); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.containerClass; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.appSession.application.version; var currVal_3 = _co.releaseDate; _ck(_v, 6, 0, currVal_2, currVal_3); }); }
function View_FooterComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], null, null, null, null, null))], null, null); }
function View_FooterComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "kt-footer__wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "kt-footer__copyright"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Eden "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FooterComponent_4)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(6, null, [" | v", " [", "] "])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "kt-footer__menu"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.appSession.tenant && _co.appSession.tenant.edition) && _co.appSession.tenant.edition.displayName); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.containerClass; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.appSession.application.version; var currVal_3 = _co.releaseDate; _ck(_v, 6, 0, currVal_2, currVal_3); }); }
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "kt-footer kt-grid__item"], ["id", "kt_footer"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FooterComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.useBottomDiv; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.useBottomDiv; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "footer-bar", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 114688, null, 0, i2.FooterComponent, [i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i0.ɵccf("footer-bar", i2.FooterComponent, View_FooterComponent_Host_0, { useBottomDiv: "useBottomDiv" }, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
