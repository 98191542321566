var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, EventEmitter, Injector } from '@angular/core';
import { NameValueOfString, AppConfigurationLookupServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConfigurationService } from '@app/shared/services/appconfigration.service';
var PalletisedFilterMultiSelectComponent = /** @class */ (function (_super) {
    __extends(PalletisedFilterMultiSelectComponent, _super);
    function PalletisedFilterMultiSelectComponent(injector, _appConfigurationService, _appConfigurationLookupService) {
        var _this = _super.call(this, injector) || this;
        _this._appConfigurationService = _appConfigurationService;
        _this._appConfigurationLookupService = _appConfigurationLookupService;
        _this.name = 'PalletisedFilter';
        _this.selectedItemsChange = new EventEmitter();
        _this.placeholder = _this.l('PalletisedFilter');
        _this.options = new Array();
        _this._selectedItems = [];
        return _this;
    }
    Object.defineProperty(PalletisedFilterMultiSelectComponent.prototype, "selectedItems", {
        get: function () {
            return this._selectedItems;
        },
        set: function (file) {
            this._selectedItems = file || [];
            this.selectedItemsChange.emit(this._selectedItems);
        },
        enumerable: true,
        configurable: true
    });
    ;
    PalletisedFilterMultiSelectComponent.prototype.ngOnInit = function () {
        this.getFilterValue();
    };
    PalletisedFilterMultiSelectComponent.prototype.getFilterValue = function () {
        this.options.push(new NameValueOfString({ name: 'Palletised', value: 'true' }), new NameValueOfString({ name: 'Non Palletised', value: 'false' }));
        //this._appConfigurationService.getPalletisedSelection().subscribe(filters => this.options = filters);
    };
    PalletisedFilterMultiSelectComponent.prototype.onPanelHide = function () {
        document.getElementById(this.name).focus();
    };
    return PalletisedFilterMultiSelectComponent;
}(AppComponentBase));
export { PalletisedFilterMultiSelectComponent };
