import { DashboardTableField } from './dashboard-table-field';
import { ConsignmentsSummaryDataEdByState, ConsignmentStatusDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../widgets/dashboard-chart-base';

export class EdByStateSummaryTable extends DashboardChartBase {
    datas: ConsignmentsSummaryDataEdByState[] = [];
    fields: DashboardTableField[];
    title: string;
    tileTitle = 'Endeavour Delivery Summary';

    constructor(private dataService: ConsignmentStatusDashboardServiceProxy) {
        super();
        this.title = this.tileTitle;
    }

    setChartData(items: ConsignmentsSummaryDataEdByState[]): void {
        this.datas = items;
        if (items.length > 0) {
            this.fields = Object.keys(items[0]).map(k => {
                return {
                    key: k,
                    localizeKey: k.charAt(0).toUpperCase() + k.slice(1),
                    type: Array.isArray(items[0][k]) ? 'Array' : typeof items[0][k]
                };
            });
        }
    }

    reload() {
        this.showLoading();
        this.dataService.getConsignmentsForDashboardEdByState().subscribe(result => {
            this.title = this.tileTitle + ' (' + (result.mainTitle || 'BAU') + ')';
            this.setChartData(result.consignmentsSummaryDataEdByStates || []);
            this.hideLoading();
        });
    }
}
