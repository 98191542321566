var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { LayoutRefService } from '@metronic/app/core/_base/layout/services/layout-ref.service';
import { AppConsts } from '@shared/AppConsts';
var Theme10LayoutComponent = /** @class */ (function (_super) {
    __extends(Theme10LayoutComponent, _super);
    function Theme10LayoutComponent(injector, layoutRefService) {
        var _this = _super.call(this, injector) || this;
        _this.layoutRefService = layoutRefService;
        _this.remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
        return _this;
    }
    Theme10LayoutComponent.prototype.ngOnInit = function () {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    };
    Theme10LayoutComponent.prototype.ngAfterViewInit = function () {
        this.layoutRefService.addElement('header', this.ktHeader.nativeElement);
    };
    return Theme10LayoutComponent;
}(ThemesLayoutBaseComponent));
export { Theme10LayoutComponent };
