import { BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxBootstrapLocaleMappingService } from 'assets/ngx-bootstrap/ngx-bootstrap-locale-mapping.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ThemeHelper } from '@app/shared/layout/themes/ThemeHelper';
import * as moment from 'moment';

export class NgxBootstrapDatePickerConfigService {

    static getDaterangepickerConfig(): BsDaterangepickerConfig {
        return Object.assign(new BsDaterangepickerConfig(), <BsDaterangepickerConfig>{
            containerClass: 'topbarDatePickerAlt1 theme-' + NgxBootstrapDatePickerConfigService.getTheme(),
            showWeekNumbers: false,
            customTodayClass: 'datePicker-highlight-current-day',
            ranges: [{
                value: [new Date(new Date().setDate(new Date().getDate())), new Date()],
                label: 'Today'
            }, {
                value: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date()],
                label: 'Since Yesterday'
            },
            {
                value: [new Date(new Date().setDate(new Date().getDate()+1)), new Date(new Date().setDate(new Date().getDate() + 1))],
                label: 'Tomorrow'
            },
            {
                value: [new Date(new Date().setDate(new Date().getDate() - 2)), new Date(new Date().setDate(new Date().getDate() - 1))],
                label: 'Last 1 - 2 Days'
            },
            {
                value: [new Date(new Date().setDate(new Date().getDate() - 2)), new Date()],
                label: 'Last 2 Days'
            },
            {
                value: [new Date(new Date().setDate(new Date().getDate() - 3)), new Date()],
                label: 'Last 3 Days'
            },
            {
                value: [new Date(new Date().setDate(new Date().getDate() - 5)), new Date()],
                label: 'Last 5 Days'
            },
            {
                value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
                label: 'Last 7 Days'
            },
            {
                value: [new Date(new Date().setDate(new Date().getDate() - 30)), new Date()],
                label: 'Last 30 Days'
            }]
        });
    }

    static getModifiedRangeBasedOnTime(range: moment.Moment[], timeRange: string): moment.Moment[] {
        if (!!timeRange && timeRange !== '00:00-24:00') {
            let timeRangeArray = timeRange.split('-');
            let startVariance = timeRangeArray[0].split(':');
            let endVariance = timeRangeArray[1].split(':');
            return [range[0].startOf('day').add(startVariance[0], 'hours').add(startVariance[1], 'minutes'),
            range[1].startOf('day').add(endVariance[0], 'hours').add(endVariance[1], 'minutes')];
        } else {
            return [range[0].startOf('day'), range[1].endOf('day')];
        }
    }

    static getDatepickerConfig(): BsDatepickerConfig {
        return Object.assign(new BsDatepickerConfig(), {
            containerClass: 'theme-' + NgxBootstrapDatePickerConfigService.getTheme(),
            customTodayClass: 'datePicker-highlight-current-day',
            showWeekNumbers: false,
        });
    }

    static getTheme(): string {
        return ThemeHelper.getTheme();
    }

    static getDatepickerLocale(): BsLocaleService {
        let localeService = new BsLocaleService();
        localeService.use(abp.localization.currentLanguage.name);
        return localeService;
    }

    static registerNgxBootstrapDatePickerLocales(): Promise<boolean> {
        if (abp.localization.currentLanguage.name === 'en') {
            return Promise.resolve(true);
        }

        let supportedLocale = new NgxBootstrapLocaleMappingService().map(abp.localization.currentLanguage.name).toLowerCase();
        let moduleLocaleName = new NgxBootstrapLocaleMappingService().getModuleName(abp.localization.currentLanguage.name);

        return new Promise<boolean>((resolve, reject) => {
            import(`ngx-bootstrap/chronos/esm5/i18n/${supportedLocale}.js`)
                .then(module => {
                    defineLocale(abp.localization.currentLanguage.name.toLowerCase(), module[`${moduleLocaleName}Locale`]);
                    resolve(true);
                }, reject);
        });
    }
}
