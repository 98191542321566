/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../node_modules/primeng/components/multiselect/multiselect.ngfactory";
import * as i3 from "primeng/components/multiselect/multiselect";
import * as i4 from "@angular/forms";
import * as i5 from "primeng/components/common/shared";
import * as i6 from "../../../../../node_modules/primeng/components/common/shared.ngfactory";
import * as i7 from "./territory-configuration-multiselect.component";
import * as i8 from "../../../shared/services/appconfigration.service";
var styles_TerritoryConfigurationMultiSelectComponent = [];
var RenderType_TerritoryConfigurationMultiSelectComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TerritoryConfigurationMultiSelectComponent, data: {} });
export { RenderType_TerritoryConfigurationMultiSelectComponent as RenderType_TerritoryConfigurationMultiSelectComponent };
function View_TerritoryConfigurationMultiSelectComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-multiselect-token"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "ui-multiselect-token-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_TerritoryConfigurationMultiSelectComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TerritoryConfigurationMultiSelectComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.index < 5); _ck(_v, 2, 0, currVal_0); }, null); }
function View_TerritoryConfigurationMultiSelectComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-multiselect-token"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "ui-multiselect-token-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["Plus ", " more"]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.$implicit.length - 5); _ck(_v, 2, 0, currVal_0); }); }
function View_TerritoryConfigurationMultiSelectComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TerritoryConfigurationMultiSelectComponent_3)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TerritoryConfigurationMultiSelectComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.length > 5); _ck(_v, 4, 0, currVal_1); }, null); }
function View_TerritoryConfigurationMultiSelectComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "ui-multiselected-empty-token ui-corner-all"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 1, 0, currVal_0); }); }
function View_TerritoryConfigurationMultiSelectComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TerritoryConfigurationMultiSelectComponent_2)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TerritoryConfigurationMultiSelectComponent_6)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (!!_v.context.$implicit && (_v.context.$implicit.length > 0)); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_v.context.$implicit || (_v.context.$implicit.length === 0)); _ck(_v, 3, 0, currVal_1); }, null); }
function View_TerritoryConfigurationMultiSelectComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["Loading ", " items..."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 1, 0, currVal_0); }); }
export function View_TerritoryConfigurationMultiSelectComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "p-multiSelect", [], [[2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onPanelShow"], [null, "onPanelHide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedItems = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onPanelShow" === en)) {
        var pd_1 = (_co.onPanelShow() !== false);
        ad = (pd_1 && ad);
    } if (("onPanelHide" === en)) {
        var pd_2 = (_co.onPanelHide() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MultiSelect_0, i2.RenderType_MultiSelect)), i0.ɵdid(1, 13877248, null, 3, i3.MultiSelect, [i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { defaultLabel: [0, "defaultLabel"], inputId: [1, "inputId"], name: [2, "name"], dropdownIcon: [3, "dropdownIcon"], optionLabel: [4, "optionLabel"], virtualScroll: [5, "virtualScroll"], itemSize: [6, "itemSize"], options: [7, "options"] }, { onPanelShow: "onPanelShow", onPanelHide: "onPanelHide" }), i0.ɵqud(603979776, 1, { footerFacet: 0 }), i0.ɵqud(603979776, 2, { headerFacet: 0 }), i0.ɵqud(603979776, 3, { templates: 1 }), i0.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.MultiSelect]), i0.ɵdid(6, 671744, null, 0, i4.NgModel, [[8, null], [8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i0.ɵdid(8, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i0.ɵand(0, null, null, 1, null, View_TerritoryConfigurationMultiSelectComponent_1)), i0.ɵdid(10, 16384, [[3, 4]], 0, i5.PrimeTemplate, [i0.TemplateRef], { name: [0, "name"] }, null), (_l()(), i0.ɵeld(11, 0, null, 0, 3, "p-header", [], [[2, "multiselect-loading", null]], null, null, i6.View_Header_0, i6.RenderType_Header)), i0.ɵdid(12, 49152, [[2, 4]], 0, i5.Header, [], null, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_TerritoryConfigurationMultiSelectComponent_7)), i0.ɵdid(14, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(15, 0, null, 1, 3, "p-footer", [], null, null, null, i6.View_Footer_0, i6.RenderType_Footer)), i0.ɵdid(16, 49152, [[1, 4]], 0, i5.Footer, [], null, null), (_l()(), i0.ɵeld(17, 0, null, 0, 1, "a", [["href", "javascript:void()"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.selectedItems = []) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Clear selection"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.placeholder; var currVal_10 = _co.name; var currVal_11 = _co.name; var currVal_12 = "pi pi-caret-down"; var currVal_13 = "name"; var currVal_14 = true; var currVal_15 = 30; var currVal_16 = _co.options; _ck(_v, 1, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); var currVal_17 = _co.name; var currVal_18 = _co.selectedItems; _ck(_v, 6, 0, currVal_17, currVal_18); var currVal_19 = "selectedItems"; _ck(_v, 10, 0, currVal_19); var currVal_21 = !_co.options.length; _ck(_v, 14, 0, currVal_21); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).filled; var currVal_1 = i0.ɵnov(_v, 1).focus; var currVal_2 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 8).ngClassValid; var currVal_7 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_20 = !_co.options.length; _ck(_v, 11, 0, currVal_20); }); }
export function View_TerritoryConfigurationMultiSelectComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "territoryConfigurationMultiselect", [], null, null, null, View_TerritoryConfigurationMultiSelectComponent_0, RenderType_TerritoryConfigurationMultiSelectComponent)), i0.ɵdid(1, 114688, null, 0, i7.TerritoryConfigurationMultiSelectComponent, [i0.Injector, i8.AppConfigurationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TerritoryConfigurationMultiSelectComponentNgFactory = i0.ɵccf("territoryConfigurationMultiselect", i7.TerritoryConfigurationMultiSelectComponent, View_TerritoryConfigurationMultiSelectComponent_Host_0, { selectedItems: "selectedItems", name: "name" }, { selectedItemsChange: "selectedItemsChange" }, []);
export { TerritoryConfigurationMultiSelectComponentNgFactory as TerritoryConfigurationMultiSelectComponentNgFactory };
