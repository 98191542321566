<div>
        <div class="text-right">
            <div  class="same-line font-weight-bolder">
                <h6 class="font-weight-bolder"> 
                    {{ 'Legend' }}
                </h6>
            </div>
            <div  class="pl-2 same-line kt-pricing-1__btn btn-group" role="group" id="Rating">
                <button type="button" class="btn btn-status btn-success btn-sm">Total</button>
                <button type="button" class="btn btn-status btn-warning btn-sm">At Risk</button>
                <button type="button" class="btn btn-status btn-danger btn-sm">Breached</button>
            </div>
        </div>
    </div>