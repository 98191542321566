var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { AppConsts } from '@shared/AppConsts';
var Theme7LayoutComponent = /** @class */ (function (_super) {
    __extends(Theme7LayoutComponent, _super);
    function Theme7LayoutComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.menuCanvasOptions = {
            baseClass: 'kt-aside',
            overlay: true,
            closeBy: 'kt_aside_close_btn',
            toggleBy: {
                target: 'kt_aside_mobile_toggler',
                state: 'kt-header-mobile__toolbar-toggler--active'
            }
        };
        _this.remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
        return _this;
    }
    Theme7LayoutComponent.prototype.ngOnInit = function () {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    };
    return Theme7LayoutComponent;
}(ThemesLayoutBaseComponent));
export { Theme7LayoutComponent };
