import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'sla-status-legend',
    templateUrl: './sla-status-legend.component.html',
    styleUrls: ['./sla-status-legend.component.less']
})
export class SLAStatusLegendComponent implements OnInit {
    constructor() {}
    ngOnInit() {}
}
