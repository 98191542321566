<div class="form-group">
    <label for="small-input">{{l(labelResourceKey)}}</label>
    <div class="form-control" *ngIf="bigInputSelected && !isMultipleShown" name="small-input"><a href="javascript:void(0);" (click)="show()">{{l('MultipleSelected', inputCount, l(labelResourceKey))}}</a> <a href="javascript:void(0);" class="float-right" (click)="clearBigInputSelected()">{{l("Reset")}}</a></div>
    <input type="text" [hidden]="bigInputSelected || isMultipleShown" class="form-control" #smallInput 
    name="small-input" (input)="onInputTextChange($event)" [ngModel]="input">
    <a href="javascript:void(0);" *ngIf="!bigInputSelected" class="float-right" (click)="show()">{{l('SelectMultiple', l(labelResourceKey))}}</a>
    <span class="help-block text-danger" *ngIf="!!invalidNumbers && invalidNumbers">{{l('InvalidNumbers')}}</span>
</div>


<div bsModal #modal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modal" (onShown)="shown()" (onHide)="hidden()" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{l('SelectMultipleModalHeader', l(labelResourceKey))}}
                </h4>
                <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form novalidate (ngSubmit)="ok()" autocomplete="off">
                <div class="modal-body">
                    <div class="form-group">
                        <label for="big-input">{{l('SelectMultipleModalHeaderHint', l(labelResourceKey))}}</label>
                        <textarea autosize minRows="10" class="form-control" name="big-input" [(ngModel)]="input"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">{{"OK" | localize}}</button>
                    <button type="button" class="btn btn-secondary" (click)="close()">{{"Cancel" | localize}}</button>
                </div>
            </form>
        </div>
    </div>
</div>
