import { Injectable } from '@angular/core';
import { WidgetViewDefinition, WidgetFilterViewDefinition } from './definitions';
import { DashboardCustomizationConst } from './DashboardCustomizationConsts';
import { WidgetIncomeStatisticsComponent } from './widgets/widget-income-statistics/widget-income-statistics.component';
import { WidgetRecentTenantsComponent } from './widgets/widget-recent-tenants/widget-recent-tenants.component';
import { WidgetEditionStatisticsComponent } from './widgets/widget-edition-statistics/widget-edition-statistics.component';
import { WidgetSubscriptionExpiringTenantsComponent } from './widgets/widget-subscription-expiring-tenants/widget-subscription-expiring-tenants.component';
import { WidgetHostTopStatsComponent } from './widgets/widget-host-top-stats/widget-host-top-stats.component';
import { FilterDateRangePickerComponent } from './filters/filter-date-range-picker/filter-date-range-picker.component';
import { WidgetEdByStateSummaryComponent } from './widgets/widget-ed-by-state-summary/widget-ed-by-state-summary.component';
import { WidgetAllCarriesSummaryComponent } from './widgets/widget-all-carriers-summary/widget-all-carriers-summary.component';
import { WidgetOrdersSummaryComponent } from './widgets/widget-orders-summary/widget-orders-summary.component';
import { WidgetConsignmentStatsComponent } from './widgets/widget-consignment-stats/widget-consignment-stats.component';

@Injectable({
  providedIn: 'root'
})
export class DashboardViewConfigurationService {

  public WidgetViewDefinitions: WidgetViewDefinition[] = [];
  public widgetFilterDefinitions: WidgetFilterViewDefinition[] = [];

  constructor(
  ) {
    this.initializeConfiguration();
  }
  private initializeConfiguration() {
    let filterDateRangePicker = new WidgetFilterViewDefinition(
      DashboardCustomizationConst.filters.filterDateRangePicker,
      FilterDateRangePickerComponent
    );
    //add your filters here
    this.widgetFilterDefinitions.push(filterDateRangePicker);

    let ordersSummary = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.ordersSummary,
      WidgetOrdersSummaryComponent,
      5,
      16
    );
    let edByStateSummary = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.edByStateSummary,
      WidgetEdByStateSummaryComponent,
      13,
      11
    );
    let allCarriesSummary = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.allCarriesSummary,
      WidgetAllCarriesSummaryComponent,
      8,
      28
    );

    let consignmentStats = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.tenant.consignmentStats,
      WidgetConsignmentStatsComponent,
      3,
      5
    );


    let incomeStatistics = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.host.incomeStatistics,
      WidgetIncomeStatisticsComponent,
    );

    let editionStatistics = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.host.editionStatistics,
      WidgetEditionStatisticsComponent,
    );

    let recentTenants = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.host.recentTenants,
      WidgetRecentTenantsComponent,
    );

    let subscriptionExpiringTenants = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.host.subscriptionExpiringTenants,
      WidgetSubscriptionExpiringTenantsComponent
    );

    let hostTopStats = new WidgetViewDefinition(
      DashboardCustomizationConst.widgets.host.topStats,
      WidgetHostTopStatsComponent,
    );
    //add your host side widgets here
    this.WidgetViewDefinitions.push(ordersSummary);
    this.WidgetViewDefinitions.push(edByStateSummary);
    this.WidgetViewDefinitions.push(allCarriesSummary);
    this.WidgetViewDefinitions.push(consignmentStats);
    this.WidgetViewDefinitions.push(incomeStatistics);
    this.WidgetViewDefinitions.push(editionStatistics);
    this.WidgetViewDefinitions.push(recentTenants);
    this.WidgetViewDefinitions.push(subscriptionExpiringTenants);
    this.WidgetViewDefinitions.push(hostTopStats);
  }
}
