var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DashboardChartBase } from '../widgets/dashboard-chart-base';
var OrdersSummaryChart = /** @class */ (function (_super) {
    __extends(OrdersSummaryChart, _super);
    function OrdersSummaryChart(_dashboardService) {
        var _this = _super.call(this) || this;
        _this._dashboardService = _dashboardService;
        _this.data = [];
        _this.tileTitle = 'Order Summary';
        _this.customColors = [
            {
                name: 'FULFILLED',
                value: '#255b25'
            },
            {
                name: 'PRE MANIFESTED',
                value: '#e2878f'
            }
        ];
        _this.title = _this.tileTitle;
        return _this;
    }
    OrdersSummaryChart.prototype.init = function (data) {
        this.title = this.tileTitle + ' (' + (data.mainTitle || 'BAU') + ')';
        if (!!data && !!data.ordersSummaryDatas) {
            this.data = data.ordersSummaryDatas.map(function (x) {
                return {
                    name: x.groupLabel,
                    series: [
                        {
                            name: 'PRE MANIFESTED',
                            filterName: 'preManifested',
                            value: x.preManifested,
                            extra: {
                                id: x.groupId
                            },
                            data: x.preManifested
                        },
                        {
                            name: 'FULFILLED',
                            filterName: 'fulfilled',
                            value: x.fulfilled,
                            extra: {
                                id: x.groupId
                            },
                            data: x.fulfilled
                        }
                    ]
                };
            });
        }
        this.hideLoading();
    };
    OrdersSummaryChart.prototype.reload = function () {
        var _this = this;
        this.showLoading();
        this._dashboardService.getOrdersSummary().subscribe(function (result) {
            _this.init(result);
        });
    };
    return OrdersSummaryChart;
}(DashboardChartBase));
export { OrdersSummaryChart };
