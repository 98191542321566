var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { TenantLoginInfoDto, EditionPaymentType, SubscriptionStartType } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
var ThemesLayoutBaseComponent = /** @class */ (function (_super) {
    __extends(ThemesLayoutBaseComponent, _super);
    function ThemesLayoutBaseComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.tenant = new TenantLoginInfoDto();
        _this.subscriptionStartType = SubscriptionStartType;
        _this.editionPaymentType = EditionPaymentType;
        _this.installationMode = true;
        _this.defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + _this.currentTheme.baseSettings.menu.asideSkin + '.svg';
        _this.userMenuToggleOptions = {
            target: 'body',
            targetState: 'kt-header__topbar--mobile-on',
            togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active'
        };
        return _this;
    }
    ThemesLayoutBaseComponent.prototype.subscriptionStatusBarVisible = function () {
        return this.appSession.tenantId > 0 && (this.appSession.tenant.isInTrialPeriod || this.subscriptionIsExpiringSoon());
    };
    ThemesLayoutBaseComponent.prototype.subscriptionIsExpiringSoon = function () {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this.appSession.tenant.subscriptionEndDateUtc);
        }
        return false;
    };
    ThemesLayoutBaseComponent.prototype.getSubscriptionExpiringDayCount = function () {
        if (!this.appSession.tenant.subscriptionEndDateUtc) {
            return 0;
        }
        return Math.round(moment.utc(this.appSession.tenant.subscriptionEndDateUtc).diff(moment().utc(), 'days', true));
    };
    ThemesLayoutBaseComponent.prototype.getTrialSubscriptionNotification = function () {
        return this.l('TrialSubscriptionNotification', "<strong>" + this.appSession.tenant.edition.displayName + "</strong>", "<a href=\"/account/buy?editionPaymentType=" + this.editionPaymentType.BuyNow + "&editionId=" + this.appSession.tenant.edition.id + "&tenantId=" + this.appSession.tenant.id + "\">" + this.l('ClickHere') + "</a>");
    };
    ThemesLayoutBaseComponent.prototype.getExpireNotification = function (localizationKey) {
        return this.l(localizationKey, this.getSubscriptionExpiringDayCount());
    };
    return ThemesLayoutBaseComponent;
}(AppComponentBase));
export { ThemesLayoutBaseComponent };
