/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./session-timeout-modal-component.ngfactory";
import * as i2 from "./session-timeout-modal-component";
import * as i3 from "../auth/app-auth.service";
import * as i4 from "../../../../shared/service-proxies/service-proxies";
import * as i5 from "../../../../shared/utils/local-storage.service";
import * as i6 from "./session-timeout.component";
var styles_SessionTimeoutComponent = [];
var RenderType_SessionTimeoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SessionTimeoutComponent, data: {} });
export { RenderType_SessionTimeoutComponent as RenderType_SessionTimeoutComponent };
export function View_SessionTimeoutComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { sessionTimeOutModal: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "session-timeout-modal", [], null, null, null, i1.View_SessionTimeoutModalComponent_0, i1.RenderType_SessionTimeoutModalComponent)), i0.ɵdid(2, 180224, [[1, 4]], 0, i2.SessionTimeoutModalComponent, [i0.Injector, i3.AppAuthService, i4.SessionServiceProxy, i5.LocalStorageService], null, null)], null, null); }
export function View_SessionTimeoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "session-timeout", [], null, null, null, View_SessionTimeoutComponent_0, RenderType_SessionTimeoutComponent)), i0.ɵdid(1, 4374528, null, 0, i6.SessionTimeoutComponent, [i0.Injector, i5.LocalStorageService], null, null)], null, null); }
var SessionTimeoutComponentNgFactory = i0.ɵccf("session-timeout", i6.SessionTimeoutComponent, View_SessionTimeoutComponent_Host_0, {}, {}, []);
export { SessionTimeoutComponentNgFactory as SessionTimeoutComponentNgFactory };
