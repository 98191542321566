import { Component, OnInit, Injector } from '@angular/core';
import { OrdersDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardOrderService } from '@app/shared/common/dashboard-service/dashboard-order.service';
import { OrdersSummaryChart } from '../../models/orders-summary-chart';
import { WidgetComponentBase } from '../widget-component-base';

@Component({
    selector: 'app-widget-orders-summary',
    templateUrl: './widget-orders-summary.component.html',
    styleUrls: ['./widget-orders-summary.component.less']
})
export class WidgetOrdersSummaryComponent extends WidgetComponentBase implements OnInit {
    ordersSummaryBarChart: OrdersSummaryChart;

    constructor(
        injector: Injector,
        private dataservice: OrdersDashboardServiceProxy,
        private dashboardOrderService: DashboardOrderService
    ) {
        super(injector);
        this.ordersSummaryBarChart = new OrdersSummaryChart(this.dataservice);
    }

    ngOnInit() {
        this.ordersSummaryBarChart.reload();
    }

    onSelect(data: any) {
        this.dashboardOrderService.navigate({ xFilter: data.extra.id, yFilter: data.filterName, zFilter: undefined });
    }
}
