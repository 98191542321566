import { NgModule, ModuleWithProviders } from '@angular/core';
import { DashboardConsignmentService } from './dashboard-consignment.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: []
})
export class DashboardConsignmentModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: DashboardConsignmentModule,
            providers: [DashboardConsignmentService]
        };
    }
}
