/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./stacked-footer.component";
var styles_StackedFooterComponent = [];
var RenderType_StackedFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StackedFooterComponent, data: {} });
export { RenderType_StackedFooterComponent as RenderType_StackedFooterComponent };
function View_StackedFooterComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "logo"], ["height", "28"], ["src", "/assets/common/images/app-logo-on-dark-sm.svg"]], null, null, null, null, null))], null, null); }
function View_StackedFooterComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["alt", "logo"], ["height", "38"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((((_co.remoteServiceBaseUrl + "/TenantCustomization/GetTenantLogo?skin=") + _co.currentTheme.baseSettings.menu.asideSkin) + "&tenantId=") + _co.appSession.tenant.id) + "&id=") + _co.appSession.tenant.logoId); _ck(_v, 0, 0, currVal_0); }); }
function View_StackedFooterComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appSession.tenant.edition.displayName; _ck(_v, 1, 0, currVal_0); }); }
export function View_StackedFooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "kt-footer  kt-footer--extended  kt-grid__item"], ["id", "kt_footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "kt-footer__bottom"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 14, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 13, "div", [["class", "kt-footer__wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 11, "div", [["class", "kt-footer__logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 5, "a", [["class", "kt-brand__logo-wrapper"], ["routerLink", "/"], ["target", "_blank"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StackedFooterComponent_1)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StackedFooterComponent_2)), i0.ɵdid(10, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(11, 0, null, null, 4, "div", [["class", "kt-footer__copyright"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" DelOps "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StackedFooterComponent_3)), i0.ɵdid(14, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(15, null, [" | v", " [", "] "])), (_l()(), i0.ɵeld(16, 0, null, null, 0, "div", [["class", "kt-footer__menu"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "_blank"; var currVal_4 = "/"; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = (!_co.appSession.tenant || !_co.appSession.tenant.logoId); _ck(_v, 8, 0, currVal_5); var currVal_6 = (_co.appSession.tenant && _co.appSession.tenant.logoId); _ck(_v, 10, 0, currVal_6); var currVal_7 = ((_co.appSession.tenant && _co.appSession.tenant.edition) && _co.appSession.tenant.edition.displayName); _ck(_v, 14, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.containerClass; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 6).target; var currVal_2 = i0.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_8 = _co.appSession.application.version; var currVal_9 = _co.releaseDate; _ck(_v, 15, 0, currVal_8, currVal_9); }); }
export function View_StackedFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "stacked-footer-bar", [], null, null, null, View_StackedFooterComponent_0, RenderType_StackedFooterComponent)), i0.ɵdid(1, 114688, null, 0, i3.StackedFooterComponent, [i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StackedFooterComponentNgFactory = i0.ɵccf("stacked-footer-bar", i3.StackedFooterComponent, View_StackedFooterComponent_Host_0, {}, {}, []);
export { StackedFooterComponentNgFactory as StackedFooterComponentNgFactory };
