import { ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
var ButtonBusyDirective = /** @class */ (function () {
    function ButtonBusyDirective(_element, _appLocalizationService) {
        this._element = _element;
        this._appLocalizationService = _appLocalizationService;
    }
    Object.defineProperty(ButtonBusyDirective.prototype, "buttonBusy", {
        set: function (buttonBusy) {
            if (typeof buttonBusy === 'boolean') {
                this.refreshState(buttonBusy);
            }
            else {
                this.refreshState(buttonBusy.isBusy, buttonBusy.isButtonDisabled);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ButtonBusyDirective.prototype, "busyText", {
        set: function (busyText) {
            this._busyText = busyText;
        },
        enumerable: true,
        configurable: true
    });
    ButtonBusyDirective.prototype.ngOnInit = function () {
        this._button = this._element.nativeElement;
    };
    ButtonBusyDirective.prototype.ngAfterViewInit = function () {
        this._originalButtonInnerHtml = this._button.innerHTML;
    };
    ButtonBusyDirective.prototype.refreshState = function (isBusy, isButtonDisabled) {
        if (isButtonDisabled === void 0) { isButtonDisabled = false; }
        if (!this._button) {
            return;
        }
        if (isBusy) {
            // disable button
            this._button.disabled = true;
            this._button.innerHTML = '<i class="fa fa-spin fa-spinner"></i>' +
                '<span>' + (this._busyText ? this._busyText : this._appLocalizationService.l('ProcessingWithThreeDot')) + '</span>';
            this._button.setAttribute('_disabledBefore', true);
        }
        else {
            if (!this._button.getAttribute('_disabledBefore')) {
                return;
            }
            // enable button (if not disabled by parent)
            this._button.disabled = isButtonDisabled;
            this._button.innerHTML = this._originalButtonInnerHtml;
        }
    };
    return ButtonBusyDirective;
}());
export { ButtonBusyDirective };
