import { Observable } from '@node_modules/rxjs/internal/Observable';
import { NameValueOfString, AppConfigurationLookupServiceProxy, NameValueOfClientGroupDto } from '../../../shared/service-proxies/service-proxies';
import { Injectable } from '@angular/core';
import { NameValueOfInt32 } from '@shared/service-proxies/service-proxies';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class AppConfigurationService {

    private depotLocations$ = new ReplaySubject<NameValueOfString[]>(1);
    private brands$ = new ReplaySubject<NameValueOfString[]>(1);
    private consignmentStatuss$ = new ReplaySubject<NameValueOfString[]>(1);
    private manifestTemplates$ = new ReplaySubject<NameValueOfString[]>(1);
    private carriers$ = new ReplaySubject<NameValueOfInt32[]>(1);
    private eventGroups$ = new ReplaySubject<NameValueOfInt32[]>(1);
    private eventDetails$ = new ReplaySubject<NameValueOfString[]>(1);
    private couriers$ = new ReplaySubject<NameValueOfInt32[]>(1);
    private clientGroups$ = new ReplaySubject<NameValueOfClientGroupDto[]>(1);
    private territoryConfigurations$ = new ReplaySubject<NameValueOfInt32[]>(1);
    private clientVisibleConsignmentStatuss$ = new ReplaySubject<NameValueOfString[]>(1);
    private manifestJob$ = new ReplaySubject<NameValueOfString[]>(1);
    private eventDetailZendeskTickets$ = new ReplaySubject<NameValueOfString[]>(1);

    constructor(
        private _appConfigurationLookupServiceProxy: AppConfigurationLookupServiceProxy
    ) {
        this.refreshCacheObjects();
        this.refreshTerritoryConfiguration();
    }
    refreshTerritoryConfiguration() {
       this._appConfigurationLookupServiceProxy.getTerritoryConfigurations('').subscribe(res => {
             this.territoryConfigurations$.next(res);
       });
    }

    refreshCacheObjects() {
        this._appConfigurationLookupServiceProxy
            .getAutoCompleteData()
            .subscribe(res => {
                this.depotLocations$.next(res.depotLocations);
                this.brands$.next(res.brands);
                this.consignmentStatuss$.next(res.consignmentStatuss);
                this.manifestTemplates$.next(res.manifestTemplates);
                this.carriers$.next(res.carriers);
                this.eventGroups$.next(res.eventGroups);
                this.eventDetails$.next(res.eventDetails);
                this.couriers$.next(res.couriers);
                this.clientGroups$.next(res.clientGroups);
                this.clientVisibleConsignmentStatuss$.next(res.clientVisibleConsignmentStatuss),
                this.manifestJob$.next(res.manifestJobs);
                this.eventDetailZendeskTickets$.next(res.eventDetailZendeskTickets);
            });
    }


    getDepotLocations(): Observable<NameValueOfString[]> {
        return this.depotLocations$.asObservable();
    }
    getBrands(): Observable<NameValueOfString[]> {
        return this.brands$.asObservable();
    }
    getConsignmentStatuss(): Observable<NameValueOfString[]> {
        return this.consignmentStatuss$.asObservable();
    }
    getClientVisibleConsignmentStatuss(): Observable<NameValueOfString[]> {
        return this.clientVisibleConsignmentStatuss$.asObservable();
    }
    getManifestTemplates(): Observable<NameValueOfString[]> {
        return this.manifestTemplates$.asObservable();
    }
    getEventGroups(): Observable<NameValueOfInt32[]> {
        return this.eventGroups$.asObservable();
    }
    getEventDetails(): Observable<NameValueOfString[]> {
        return this.eventDetails$.asObservable();
    }
    getCouriers(): Observable<NameValueOfInt32[]> {
        return this.couriers$.asObservable();
    }
    getCarriers(): Observable<NameValueOfInt32[]> {
        return this.carriers$.asObservable();
    }

    getClientGroups(): Observable<NameValueOfClientGroupDto[]> {
        return this.clientGroups$.asObservable();
    }

    getTerritoryConfigurations(): Observable<NameValueOfInt32[]> {
        return this.territoryConfigurations$.asObservable();
    }
    getManifestJobs(): Observable<NameValueOfString[]> {
        return this.manifestJob$.asObservable();
    }

    getEventDetailWithZendeskType(): Observable<NameValueOfString[]> {
        return this.eventDetailZendeskTickets$.asObservable();
    }
}
