var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, Injector, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SettingScopes, ProfileServiceProxy, SendVerificationSmsInputDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SmsVerificationModalComponent } from './sms-verification-modal.component';
import { finalize } from 'rxjs/operators';
var MySettingsModalComponent = /** @class */ (function (_super) {
    __extends(MySettingsModalComponent, _super);
    function MySettingsModalComponent(injector, _profileService) {
        var _this = _super.call(this, injector) || this;
        _this._profileService = _profileService;
        _this.modalSave = new EventEmitter();
        _this.active = false;
        _this.saving = false;
        _this.isGoogleAuthenticatorEnabled = false;
        _this.showTimezoneSelection = abp.clock.provider.supportsMultipleTimezone;
        _this.defaultTimezoneScope = SettingScopes.User;
        _this._initialTimezone = undefined;
        _this.isMultiTenancyEnabled = _this.multiTenancy.isEnabled;
        _this.isTwoFactorLoginEnabledForApplication = false;
        return _this;
    }
    MySettingsModalComponent.prototype.ngOnInit = function () {
        this.isTwoFactorLoginEnabledForApplication =
            abp.setting.getBoolean('Abp.Zero.UserManagement.TwoFactorLogin.IsEnabled');
    };
    MySettingsModalComponent.prototype.show = function () {
        var _this = this;
        this.active = true;
        this._profileService.getCurrentUserProfileForEdit().subscribe(function (result) {
            _this.smsEnabled = _this.setting.getBoolean('App.UserManagement.SmsVerificationEnabled');
            _this.user = result;
            _this._initialTimezone = result.timezone;
            _this.canChangeUserName = _this.user.userName !== AppConsts.userManagement.defaultAdminUserName;
            _this.modal.show();
            _this.isGoogleAuthenticatorEnabled = result.isGoogleAuthenticatorEnabled;
            _this.isPhoneNumberConfirmed = result.isPhoneNumberConfirmed;
            _this.savedPhoneNumber = result.phoneNumber;
        });
    };
    MySettingsModalComponent.prototype.updateQrCodeSetupImageUrl = function () {
        var _this = this;
        this._profileService.updateGoogleAuthenticatorKey().subscribe(function (result) {
            _this.user.qrCodeSetupImageUrl = result.qrCodeSetupImageUrl;
            _this.isGoogleAuthenticatorEnabled = true;
        });
    };
    MySettingsModalComponent.prototype.disableGoogleAuthenticator = function () {
        var _this = this;
        this._profileService.disableGoogleAuthenticator().subscribe(function () {
            _this.isGoogleAuthenticatorEnabled = false;
        });
    };
    MySettingsModalComponent.prototype.smsVerify = function () {
        var _this = this;
        var input = new SendVerificationSmsInputDto();
        input.phoneNumber = this.user.phoneNumber;
        this._profileService.sendVerificationSms(input)
            .subscribe(function () {
            _this.smsVerificationModal.show();
        });
    };
    MySettingsModalComponent.prototype.changePhoneNumberToVerified = function () {
        this.isPhoneNumberConfirmed = true;
        this.savedPhoneNumber = this.user.phoneNumber;
    };
    MySettingsModalComponent.prototype.onShown = function () {
        document.getElementById('Name').focus();
    };
    MySettingsModalComponent.prototype.close = function () {
        this.active = false;
        this.modal.hide();
    };
    MySettingsModalComponent.prototype.save = function () {
        var _this = this;
        this.saving = true;
        this._profileService.updateCurrentUserProfile(this.user)
            .pipe(finalize(function () { _this.saving = false; }))
            .subscribe(function () {
            _this.appSession.user.name = _this.user.name;
            _this.appSession.user.surname = _this.user.surname;
            _this.appSession.user.userName = _this.user.userName;
            _this.appSession.user.emailAddress = _this.user.emailAddress;
            _this.notify.success(_this.l('SavedSuccessfully'));
            _this.close();
            _this.modalSave.emit(null);
            if (abp.clock.provider.supportsMultipleTimezone && _this._initialTimezone !== _this.user.timezone) {
                _this.message.info(_this.l('TimeZoneSettingChangedRefreshPageNotification')).then(function () {
                    window.location.reload();
                });
            }
        });
    };
    return MySettingsModalComponent;
}(AppComponentBase));
export { MySettingsModalComponent };
