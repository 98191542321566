var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, AfterViewInit, OnDestroy } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SessionTimeoutModalComponent } from './session-timeout-modal-component';
import { timer, fromEvent, Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { LocalStorageService } from '@shared/utils/local-storage.service';
var SessionTimeoutComponent = /** @class */ (function (_super) {
    __extends(SessionTimeoutComponent, _super);
    function SessionTimeoutComponent(injector, _localStorageService) {
        var _this = _super.call(this, injector) || this;
        _this._localStorageService = _localStorageService;
        _this.destroy$ = new Subject();
        _this.timeOutSecond = parseInt(_this.s('App.UserManagement.SessionTimeOut.TimeOutSecond')); // show inactivity modal when TimeOutSecond passed
        _this.lastActivityTimeStorageKey = 'Abp.SessionTimeOut.UserLastActivityTime';
        _this.IsUserActive = true;
        _this.notifierIsOpened = false;
        _this.subscriptions = [];
        return _this;
    }
    SessionTimeoutComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.bindActions();
        this.writeToStorage(); // initialize store
        this.subscriptions.push(timer(1000, 1000).subscribe(function () {
            _this.control();
        }));
    };
    SessionTimeoutComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    SessionTimeoutComponent.prototype.bindActions = function () {
        var _this = this;
        this.subscriptions.push(fromEvent(window, 'mousemove')
            .pipe(takeUntil(this.destroy$), debounceTime(350))
            .subscribe(function () {
            _this.setUserActivity();
        }));
        this.subscriptions.push(fromEvent(window, 'mousedown')
            .pipe(takeUntil(this.destroy$), debounceTime(350))
            .subscribe(function () {
            _this.setUserActivity();
        }));
        this.subscriptions.push(fromEvent(window, 'click')
            .pipe(takeUntil(this.destroy$), debounceTime(350))
            .subscribe(function () {
            _this.setUserActivity();
        }));
        this.subscriptions.push(fromEvent(window, 'scroll')
            .pipe(takeUntil(this.destroy$), debounceTime(350))
            .subscribe(function () {
            _this.setUserActivity();
        }));
        this.subscriptions.push(fromEvent(window, 'keypress')
            .pipe(takeUntil(this.destroy$), debounceTime(350))
            .subscribe(function () {
            _this.setUserActivity();
        }));
    };
    SessionTimeoutComponent.prototype.setUserActivity = function () {
        this.IsUserActive = true;
    };
    SessionTimeoutComponent.prototype.control = function () {
        this.writeToStorage();
        this.controlStorage();
    };
    SessionTimeoutComponent.prototype.writeToStorage = function () {
        if (this.IsUserActive) {
            if (localStorage) {
                this._localStorageService.setItem(this.lastActivityTimeStorageKey, Date.now().toString());
            }
            else {
                abp.utils.setCookieValue(this.lastActivityTimeStorageKey, Date.now().toString());
            }
        }
        this.IsUserActive = false;
    };
    SessionTimeoutComponent.prototype.controlStorage = function () {
        var _this = this;
        if (localStorage) {
            this._localStorageService.getItem(this.lastActivityTimeStorageKey, function (err, data) {
                if (data) {
                    var lastActivityTime = parseInt(data);
                    _this.controlValue(lastActivityTime);
                }
            });
        }
        else {
            var lastActivityTime = parseInt(abp.utils.getCookieValue(this.lastActivityTimeStorageKey));
            this.controlValue(lastActivityTime);
        }
    };
    SessionTimeoutComponent.prototype.controlValue = function (lastActivityTime) {
        if (Date.now() - lastActivityTime <= this.timeOutSecond * 1000) {
            if (this.notifierIsOpened) {
                this.sessionTimeOutModal.stop();
                this.notifierIsOpened = false;
            }
            return;
        }
        if (!this.notifierIsOpened) {
            this.sessionTimeOutModal.start();
            this.notifierIsOpened = true;
        }
    };
    return SessionTimeoutComponent;
}(AppComponentBase));
export { SessionTimeoutComponent };
