var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { LayoutRefService } from '@metronic/app/core/_base/layout/services/layout-ref.service';
import { AppConsts } from '@shared/AppConsts';
var Theme12LayoutComponent = /** @class */ (function (_super) {
    __extends(Theme12LayoutComponent, _super);
    function Theme12LayoutComponent(injector, layoutRefService) {
        var _this = _super.call(this, injector) || this;
        _this.layoutRefService = layoutRefService;
        _this.menuCanvasOptions = {
            baseClass: 'kt-aside',
            overlay: true,
            closeBy: 'kt_aside_close_btn',
            toggleBy: [{
                    target: 'kt_aside_mobile_toggler',
                    state: 'kt-header-mobile__toolbar-toggler--active'
                }]
        };
        _this.remoteServiceBaseUrl = AppConsts.remoteServiceBaseUrl;
        return _this;
    }
    Theme12LayoutComponent.prototype.ngOnInit = function () {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    };
    Theme12LayoutComponent.prototype.ngAfterViewInit = function () {
        this.layoutRefService.addElement('header', this.ktHeader.nativeElement);
        this.initAsideToggler();
        this.initResizeHandler();
    };
    Theme12LayoutComponent.prototype.initAsideToggler = function () {
        this.toggle = new KTToggle('kt_aside_toggler', {
            target: 'body',
            targetState: 'kt-aside--minimize',
            togglerState: 'kt-aside__brand-aside-toggler--active'
        });
    };
    //Resolves https://github.com/aspnetzero/aspnet-zero-core/issues/2832.
    Theme12LayoutComponent.prototype.initResizeHandler = function () {
        //copy of offcanvas.js window resize code part with 100ms delayed. Workaround
        var element = KTUtil.get('kt_aside');
        KTUtil.addResizeHandler(function () {
            setTimeout(function () {
                if (parseInt(KTUtil.css(element, 'left')) >= 0 || parseInt(KTUtil.css(element, 'right')) >= 0 || KTUtil.css(element, 'position') !== 'fixed') {
                    KTUtil.css(element, 'opacity', '1');
                }
            }, 100);
        });
    };
    return Theme12LayoutComponent;
}(ThemesLayoutBaseComponent));
export { Theme12LayoutComponent };
