var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, EventEmitter, Injector } from '@angular/core';
import { NameValueOfString, HeatStatus } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
var HeatStatusMultiSelectComponent = /** @class */ (function (_super) {
    __extends(HeatStatusMultiSelectComponent, _super);
    function HeatStatusMultiSelectComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this._exclude = [];
        _this.name = 'HeatStatusFilter';
        _this.selectedItemsChange = new EventEmitter();
        _this.heatStatusValues = HeatStatus;
        _this.options = new Array();
        _this._selectedItems = [];
        _this.placeholder = _this.l('HeatStatus');
        return _this;
    }
    Object.defineProperty(HeatStatusMultiSelectComponent.prototype, "selectedItems", {
        get: function () {
            return this._selectedItems;
        },
        set: function (file) {
            this._selectedItems = file || [];
            this.selectedItemsChange.emit(this._selectedItems);
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(HeatStatusMultiSelectComponent.prototype, "exclude", {
        set: function (exclude) {
            this._exclude = exclude;
            this.setHeatStatus();
        },
        enumerable: true,
        configurable: true
    });
    HeatStatusMultiSelectComponent.prototype.ngOnInit = function () {
        this.setHeatStatus();
    };
    HeatStatusMultiSelectComponent.prototype.heatStatusKeys = function () {
        var keys = Object.keys(this.heatStatusValues);
        return keys.slice(keys.length / 2);
    };
    HeatStatusMultiSelectComponent.prototype.setHeatStatus = function () {
        var _this = this;
        this.options = [];
        this.heatStatusKeys().forEach(function (e) {
            if (!_this._exclude.includes(e)) {
                _this.options.push(new NameValueOfString({ name: _this.l(e), value: _this.heatStatusValues[e] }));
            }
        });
    };
    HeatStatusMultiSelectComponent.prototype.onPanelHide = function () {
        document.getElementById(this.name).focus();
    };
    return HeatStatusMultiSelectComponent;
}(AppComponentBase));
export { HeatStatusMultiSelectComponent };
