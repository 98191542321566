import { Component, Input, Output, EventEmitter, Injector, ViewEncapsulation } from '@angular/core';
import { NameValueOfInt32, AppConfigurationLookupServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: "manifestFileAutoComplete",
    encapsulation: ViewEncapsulation.None,
    templateUrl: "./manifestFiles-auto-complete.component.html"
})
export class ManifestFileAutoCompleteComponent extends AppComponentBase {
    filteredManifestFiles: NameValueOfInt32[] = [];
    manifestFiles: NameValueOfInt32[] = new Array<NameValueOfInt32>();
    searchString: string = "";
    @Input() manifestFile: NameValueOfInt32;
    @Input() dropDownActive: Boolean = false;
    @Input() resultCount: number = 25;
    @Input() multiple: Boolean = false;
    @Input() styleClasses: String = 'width-percent-100';
    @Output() manifestFileChange = new EventEmitter<NameValueOfInt32>();
    constructor(
        injector: Injector,
        private _appConfigurationLookupServiceProxy: AppConfigurationLookupServiceProxy
    ) {
        super(injector);
        this.getManifestFiles(this.searchString, this.resultCount).subscribe(
            manifestFiles => {
                this.manifestFiles = manifestFiles;
            }
        );
    }

    filterManifestFiles(event): void {
        this.filteredManifestFiles = this.manifestFiles.filter(
            (nv: NameValueOfInt32) =>
                nv.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1
        );
        if (this.filteredManifestFiles.length === 0 || event.query.length % 3 === 0)  {
            this.getManifestFiles(
                event.query.toLowerCase(),
                this.resultCount
            ).subscribe(manifestFiles => {
                this.manifestFiles = manifestFiles;
            });
        }
    }

    getManifestFiles(searchString: string, resultCount: number) {
        return this._appConfigurationLookupServiceProxy.getManifestFiles(
            searchString,
            resultCount
        );
    }
}
