var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, Injector } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ProfileServiceProxy, AppConfigurationLookupServiceProxy, NameValueOfString } from '@shared/service-proxies/service-proxies';
import { AppPreBootstrap } from 'AppPreBootstrap';
import { DepotToggleService } from '@app/main/operations/shared/depotToggle/depotToggle.service';
var ChangeDepotModalComponent = /** @class */ (function (_super) {
    __extends(ChangeDepotModalComponent, _super);
    function ChangeDepotModalComponent(injector, _depotService, _appConfigurationLookupServiceProxy, _profileServiceProxy) {
        var _this = _super.call(this, injector) || this;
        _this._depotService = _depotService;
        _this._appConfigurationLookupServiceProxy = _appConfigurationLookupServiceProxy;
        _this._profileServiceProxy = _profileServiceProxy;
        _this.saving = false;
        _this.active = false;
        _this.currentDepot = abp.setting.get('UserSettings.DepotAssociation.Depot') || '';
        _this.chosenDepot = abp.setting.get('UserSettings.DepotAssociation.Depot') || '';
        _this.appPreBootstrap = AppPreBootstrap;
        return _this;
    }
    ChangeDepotModalComponent.prototype.show = function () {
        var _this = this;
        this.active = true;
        this.depots$ = this._appConfigurationLookupServiceProxy.getDepotLocations("");
        this.depots$.subscribe(function () { return _this.modal.show(); });
    };
    ChangeDepotModalComponent.prototype.onShown = function () {
        // this.changeDepotDropdown.nativeElement.focus();
    };
    ChangeDepotModalComponent.prototype.close = function () {
        this.active = false;
        this.saving = false;
        this.modal.hide();
    };
    ChangeDepotModalComponent.prototype.save = function () {
        var _this = this;
        if (this.chosenDepot !== this.currentDepot) {
            this.saving = true;
            this._profileServiceProxy.updateAssociatedDepot(this.chosenDepot)
                .subscribe(function () {
                _this.appPreBootstrap.getUserConfiguration(function () {
                    _this._depotService.updateCurrentDepot(abp.setting.get('UserSettings.DepotAssociation.Depot'));
                    _this.currentDepot = abp.setting.get('UserSettings.DepotAssociation.Depot') || '';
                    _this.close();
                });
            });
        }
        else {
            this.close();
        }
    };
    return ChangeDepotModalComponent;
}(AppComponentBase));
export { ChangeDepotModalComponent };
