<div class="kt-footer  kt-footer--extended  kt-grid__item" id="kt_footer">
    <div class="kt-footer__bottom">
        <div [class]="containerClass">
            <div class="kt-footer__wrapper">
                <div class="kt-footer__logo">
                    <a routerLink="/" target="_blank" class="kt-brand__logo-wrapper">
                        <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" src="/assets/common/images/app-logo-on-dark-sm.svg" alt="logo" height="28" />
                        <img *ngIf="appSession.tenant && appSession.tenant.logoId" [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + currentTheme.baseSettings.menu.asideSkin + '&tenantId=' + appSession.tenant.id + '&id=' + appSession.tenant.logoId" alt="logo" height="38" />
                    </a>
                    <div class="kt-footer__copyright">
                        DelOps <span *ngIf="appSession.tenant && appSession.tenant.edition && appSession.tenant.edition.displayName"> {{appSession.tenant.edition.displayName}}</span> | v{{appSession.application.version}} [{{releaseDate}}]
                    </div>
                </div>
                <div class="kt-footer__menu">
                </div>
            </div>
        </div>
    </div>
</div>
