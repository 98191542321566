var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DatePipe } from "@angular/common";
import { ControlWidget } from "ngx-schema-form";
var MetronicsDateComponent = /** @class */ (function (_super) {
    __extends(MetronicsDateComponent, _super);
    function MetronicsDateComponent(datepipe) {
        var _this = _super.call(this) || this;
        _this.datepipe = datepipe;
        return _this;
    }
    MetronicsDateComponent.prototype.ngAfterViewInit = function () {
        _super.prototype.ngAfterViewInit.call(this);
        if (!!this.control.value) {
            this.value = new Date(this.control.value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
        }
    };
    MetronicsDateComponent.prototype.setValue = function (value) {
        if (!!value) {
            this.control.setValue(this.datepipe.transform(value, "dd/MM/yyyy"));
        }
        else {
            this.control.setValue('');
            this.control.setErrors(null);
        }
    };
    return MetronicsDateComponent;
}(ControlWidget));
export { MetronicsDateComponent };
