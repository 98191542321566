var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector } from '@angular/core';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
var DailySalesLineChart = /** @class */ (function (_super) {
    __extends(DailySalesLineChart, _super);
    function DailySalesLineChart(_dashboardService) {
        var _this = _super.call(this) || this;
        _this._dashboardService = _dashboardService;
        _this.scheme = {
            name: 'green',
            selectable: true,
            group: 'Ordinal',
            domain: [
                '#34bfa3'
            ]
        };
        return _this;
    }
    DailySalesLineChart.prototype.init = function (data) {
        this.chartData = [];
        for (var i = 0; i < data.length; i++) {
            this.chartData.push({
                name: i + 1,
                value: data[i]
            });
        }
    };
    DailySalesLineChart.prototype.reload = function () {
        var _this = this;
        this.showLoading();
        this._dashboardService
            .getDailySales()
            .subscribe(function (result) {
            _this.init(result.dailySales);
            _this.hideLoading();
        });
    };
    return DailySalesLineChart;
}(DashboardChartBase));
var WidgetDailySalesComponent = /** @class */ (function (_super) {
    __extends(WidgetDailySalesComponent, _super);
    function WidgetDailySalesComponent(injector, _tenantdashboardService) {
        var _this = _super.call(this, injector) || this;
        _this._tenantdashboardService = _tenantdashboardService;
        _this.dailySalesLineChart = new DailySalesLineChart(_this._tenantdashboardService);
        return _this;
    }
    WidgetDailySalesComponent.prototype.ngOnInit = function () {
        this.dailySalesLineChart.reload();
    };
    return WidgetDailySalesComponent;
}(WidgetComponentBase));
export { WidgetDailySalesComponent };
