import { Component, OnInit, Input, Output, EventEmitter, Injector, ViewEncapsulation } from '@angular/core';
import { NameValueOfInt32, AppConfigurationLookupServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'clientGroupsMultiselect',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './clientGroup-multiselect.component.html'
})

export class ClientGroupsMultiSelectComponent extends AppComponentBase implements OnInit {
    @Input()
    set selectedItems(items: NameValueOfInt32[]) {
        this._selectedItems = items || [];
        this.selectedItemsChange.emit(this._selectedItems);
    };
    get selectedItems(): NameValueOfInt32[] {
        return this._selectedItems;
    }

    @Input() name = 'ClientGroupFilter';
    @Output() selectedItemsChange: EventEmitter<NameValueOfInt32[]> = new EventEmitter<NameValueOfInt32[]>();

    placeholder = this.l('ClientGroup');

    options: NameValueOfInt32[] = new Array<NameValueOfInt32>();
    filteredclientGroups: NameValueOfInt32[] = [];
    searchString: string = "";

    private _selectedItems: NameValueOfInt32[] = [];

    constructor(injector: Injector, private _appConfigurationLookupServiceProxy: AppConfigurationLookupServiceProxy) {
       super(injector)
    }

    ngOnInit() {
        this.getClientGroups(this.searchString);
    }

    getClientGroups(searchString: string) {
        this._appConfigurationLookupServiceProxy.getClientGroups(searchString)
            .subscribe(clientGroups => {
                this.options = clientGroups.map(e => <NameValueOfInt32>{name: e.name, value: e.value.id});
            });
    }

    onPanelHide() {
        document.getElementById(this.name).focus();
    }
}