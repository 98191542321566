var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { Injector, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppNavigationService } from './app-navigation.service';
import * as objectPath from 'object-path';
import { filter } from 'rxjs/operators';
var TopBarMenuComponent = /** @class */ (function (_super) {
    __extends(TopBarMenuComponent, _super);
    function TopBarMenuComponent(injector, router, permission, _appNavigationService, render) {
        var _this = _super.call(this, injector) || this;
        _this.permission = permission;
        _this._appNavigationService = _appNavigationService;
        _this.render = render;
        _this.menu = null;
        _this.currentRouteUrl = '';
        _this.menuOptions = {
            submenu: {
                desktop: 'dropdown',
                tablet: 'accordion',
                mobile: 'accordion'
            },
            accordion: {
                slideSpeed: 200,
                expandAll: false // allow having multiple expanded accordions in the menu
            }
        };
        _this.offcanvasOptions = {
            overlay: true,
            baseClass: 'kt-header-menu-wrapper',
            closeBy: 'kt_header_menu_mobile_close_btn',
            toggleBy: {
                target: 'kt_header_mobile_toggler',
                state: 'kt-header-mobile__toolbar-toggler--active'
            }
        };
        return _this;
    }
    TopBarMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.menu = this._appNavigationService.getMenu();
        this.currentRouteUrl = this.router.url;
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this.currentRouteUrl = _this.router.url;
        });
    };
    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     * @param e Event
     */
    TopBarMenuComponent.prototype.mouseEnter = function (e) {
        // check if the left aside menu is fixed
        if (!document.body.classList.contains('kt-menu__item--hover')) {
            this.render.addClass(document.body, 'kt-menu__item--hover');
        }
    };
    /**
     * Mouse Leave event
     * @param event: MouseEvent
     */
    TopBarMenuComponent.prototype.mouseLeave = function (event) {
        this.render.removeClass(event.target, 'kt-menu__item--hover');
    };
    TopBarMenuComponent.prototype.showMenuItem = function (menuItem) {
        return this._appNavigationService.showMenuItem(menuItem);
    };
    TopBarMenuComponent.prototype.getItemCssClasses = function (item, parentItem, depth) {
        var isRootLevel = item && !parentItem;
        var cssClasses = 'kt-menu__item kt-menu__item--rel';
        if (objectPath.get(item, 'items.length') || this.isRootTabMenuItemWithoutChildren(item, isRootLevel)) {
            cssClasses += ' kt-menu__item--submenu';
        }
        if (objectPath.get(item, 'icon-only')) {
            cssClasses += ' kt-menu__item--icon-only';
        }
        if (this.isMenuItemIsActive(item)) {
            cssClasses += ' kt-menu__item--active';
        }
        if (this.isTabMenuUsed && isRootLevel) {
            cssClasses += ' kt-menu__item--tabs';
        }
        if (this.isTabMenuUsed && !isRootLevel && item.items.length) {
            cssClasses += ' kt-menu__item--submenu kt-menu__item--rel';
            if (depth && depth === 1) {
                cssClasses += ' kt-menu__item--submenu-tabs kt-menu__item--open-dropdown';
            }
        }
        else if (!this.isTabMenuUsed && item.items.length) {
            if (depth && depth >= 1) {
                cssClasses += ' kt-menu__item--submenu';
            }
            else {
                cssClasses += ' kt-menu__item--rel';
            }
        }
        return cssClasses;
    };
    TopBarMenuComponent.prototype.getAnchorItemCssClasses = function (item, parentItem) {
        var isRootLevel = item && !parentItem;
        var cssClasses = 'kt-menu__link';
        if ((this.isTabMenuUsed && isRootLevel) || item.items.length) {
            cssClasses += ' kt-menu__toggle';
        }
        return cssClasses;
    };
    TopBarMenuComponent.prototype.getSubmenuCssClasses = function (item, parentItem, depth) {
        var cssClasses = 'kt-menu__submenu kt-menu__submenu--classic';
        if (this.isTabMenuUsed) {
            if (depth === 0) {
                cssClasses += ' kt-menu__submenu--tabs';
            }
            cssClasses += ' kt-menu__submenu--' + (depth >= 2 ? 'right' : 'left');
        }
        else {
            cssClasses += ' kt-menu__submenu--' + (depth >= 1 ? 'right' : 'left');
        }
        return cssClasses;
    };
    TopBarMenuComponent.prototype.isRootTabMenuItemWithoutChildren = function (item, isRootLevel) {
        return this.isTabMenuUsed && isRootLevel && !item.items.length;
    };
    TopBarMenuComponent.prototype.isMenuItemIsActive = function (item) {
        if (item.items.length) {
            return this.isMenuRootItemIsActive(item);
        }
        if (!item.route) {
            return false;
        }
        return this.currentRouteUrl.replace(/\/$/, '') === item.route.replace(/\/$/, '');
    };
    TopBarMenuComponent.prototype.isMenuRootItemIsActive = function (item) {
        if (item.items) {
            for (var _i = 0, _a = item.items; _i < _a.length; _i++) {
                var subItem = _a[_i];
                if (this.isMenuItemIsActive(subItem)) {
                    return true;
                }
            }
        }
        return false;
    };
    TopBarMenuComponent.prototype.getItemAttrSubmenuToggle = function (menuItem, parentItem, depth) {
        var isRootLevel = menuItem && !parentItem;
        if (isRootLevel && this.isTabMenuUsed) {
            return 'tab';
        }
        else {
            if (depth && depth >= 1) {
                return 'hover';
            }
            else {
                return 'click';
            }
        }
    };
    TopBarMenuComponent.prototype.isMobileDevice = function () {
        return KTUtil.isMobileDevice();
    };
    return TopBarMenuComponent;
}(AppComponentBase));
export { TopBarMenuComponent };
