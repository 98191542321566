var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ErrorHandler, Injector } from '@angular/core';
import { ApplicationInsightsService } from './application-insights.service';
var ErrorHandlerService = /** @class */ (function (_super) {
    __extends(ErrorHandlerService, _super);
    function ErrorHandlerService(_injector) {
        var _this = _super.call(this) || this;
        _this._injector = _injector;
        _this.UNKNOWN_ERROR = 'unknown_error';
        return _this;
    }
    ErrorHandlerService.prototype.handleError = function (error) {
        this.applicationInsightsService = this._injector.get(ApplicationInsightsService);
        this.applicationInsightsService.trackException({ id: this.UNKNOWN_ERROR, error: error }); // Manually log exception
        abp.log.error(error);
    };
    return ErrorHandlerService;
}(ErrorHandler));
export { ErrorHandlerService };
