import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var OnDemandPreloadOptions = /** @class */ (function () {
    function OnDemandPreloadOptions(routePath, preload) {
        if (preload === void 0) { preload = true; }
        this.routePath = routePath;
        this.preload = preload;
    }
    return OnDemandPreloadOptions;
}());
export { OnDemandPreloadOptions };
var OnDemandPreloadService = /** @class */ (function () {
    function OnDemandPreloadService() {
        this.subject = new Subject();
        this.state = this.subject.asObservable();
    }
    OnDemandPreloadService.prototype.startPreload = function (routePath) {
        var message = new OnDemandPreloadOptions(routePath, true);
        this.subject.next(message);
    };
    OnDemandPreloadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OnDemandPreloadService_Factory() { return new OnDemandPreloadService(); }, token: OnDemandPreloadService, providedIn: "root" });
    return OnDemandPreloadService;
}());
export { OnDemandPreloadService };
