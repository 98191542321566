var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnChanges, AfterViewInit } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { WidgetComponentBase } from '../widget-component-base';
import { ModalDirective, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ConsignmentSearchComponent } from '@app/shared/common/consignment-search/consignment-search.component';
import { ConsignmentsServiceProxy } from '../../../../../../shared/service-proxies/service-proxies';
import * as moment from 'moment';
import compare from 'just-compare';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { DatePickerComponent } from '@app/shared/common/date-picker/date-picker.component';
var ConsignmentStats = /** @class */ (function (_super) {
    __extends(ConsignmentStats, _super);
    function ConsignmentStats(_consignmentsService, consignmentSearchOutput) {
        if (consignmentSearchOutput === void 0) { consignmentSearchOutput = null; }
        var _this = _super.call(this) || this;
        _this._consignmentsService = _consignmentsService;
        _this.consignmentSearchOutput = consignmentSearchOutput;
        _this.result = {};
        return _this;
    }
    ConsignmentStats.prototype.reload = function () {
        var _this = this;
        this.showLoading();
        if (!!this.consignmentSearchOutput) {
            var searchInput_1 = this.consignmentSearchOutput.getAllConsignmentsInput;
            if (!!searchInput_1.selectedRangeLabel) {
                var selectedDateRange = [];
                var selected = NgxBootstrapDatePickerConfigService.getDaterangepickerConfig().ranges.filter(function (range) { return compare(range.label, searchInput_1.selectedRangeLabel); });
                if (!!selected.length && selected[0].value) {
                    selectedDateRange = [selected[0].value[0], selected[0].value[1]];
                    searchInput_1.maxCreatedDateFilter = null;
                    searchInput_1.minCreatedDateFilter = null;
                    searchInput_1.maxSlaDateFilter = null;
                    searchInput_1.minSlaDateFilter = null;
                    searchInput_1.maxOrderDateFilter = null;
                    searchInput_1.minOrderDateFilter = null;
                    searchInput_1.maxShipDateFilter = null;
                    searchInput_1.minShipDateFilter = null;
                    searchInput_1.maxPreferredShipDateFilter = null;
                    searchInput_1.minPreferredShipDateFilter = null;
                    if (!selectedDateRange.length) {
                        return;
                    }
                    var m_selectedDateRange = [moment(selectedDateRange[0]), moment(selectedDateRange[1])];
                    m_selectedDateRange = NgxBootstrapDatePickerConfigService.getModifiedRangeBasedOnTime(m_selectedDateRange, this.consignmentSearchOutput.getAllConsignmentsInput.selectedTimeRange);
                    var maxDate = m_selectedDateRange[1];
                    var minDate = m_selectedDateRange[0];
                    switch (searchInput_1.selectedDateFilter) {
                        case 'CreatedDate': {
                            searchInput_1.maxCreatedDateFilter = maxDate;
                            searchInput_1.minCreatedDateFilter = minDate;
                            break;
                        }
                        case 'SlaDate': {
                            searchInput_1.maxSlaDateFilter = maxDate;
                            searchInput_1.minSlaDateFilter = minDate;
                            break;
                        }
                        case 'OrderDate': {
                            searchInput_1.maxOrderDateFilter = maxDate;
                            searchInput_1.minOrderDateFilter = minDate;
                            break;
                        }
                        case 'ShipDate': {
                            searchInput_1.maxShipDateFilter = maxDate;
                            searchInput_1.minShipDateFilter = minDate;
                            break;
                        }
                        case 'PreferredShipDate': {
                            searchInput_1.maxPreferredShipDateFilter = maxDate;
                            searchInput_1.minPreferredShipDateFilter = minDate;
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                }
            }
            this._consignmentsService
                .getConsignmentStatistics(searchInput_1)
                .subscribe(function (result) {
                _this.result = result;
                _this.hideLoading();
            });
        }
    };
    return ConsignmentStats;
}(DashboardChartBase));
var ModalContentComponent = /** @class */ (function (_super) {
    __extends(ModalContentComponent, _super);
    function ModalContentComponent(bsModalRef, injector) {
        var _this = _super.call(this, injector) || this;
        _this.bsModalRef = bsModalRef;
        _this.selectedDateFilter = 'CreatedDate';
        _this.selectedRangeLabel = 'Since Yesterday';
        _this.selectedTimeRange = null;
        return _this;
    }
    ModalContentComponent.prototype.ngAfterViewInit = function () {
        if (!!this.searchParams) {
            this.consignmentSearch.searchParams = this.searchParams;
            this.selectedDateFilter = this.searchParams.selectedDateFilter;
            if (!!this.searchParams.selectedRangeLabel) {
                this.appDatePicker.selectedRangeLabel = this.searchParams.selectedRangeLabel;
            }
            else {
                if (!!this.searchParams.selectedDateRange) {
                    this.appDatePicker.selectedDateRange = this.searchParams.selectedDateRange;
                }
            }
            if (!!this.searchParams.selectedTimeRange) {
                this.appDatePicker.selectedTimeRange = this.searchParams.selectedTimeRange;
            }
        }
        else {
            this.appDatePicker.selectedRangeLabel = this.selectedRangeLabel;
        }
    };
    ModalContentComponent.prototype.receiveDatePickerDates = function (event) {
        this.selectedDateRange = event.selectedDateRange;
        this.selectedRangeLabel = event.selectedRangeLabel;
        this.selectedTimeRange = event.selectedTimeRange;
        this.updateSelectedDate(this.selectedDateRange);
    };
    ModalContentComponent.prototype.setSelectedDateRange = function (selectedDateFilter) {
        this.selectedDateFilter = selectedDateFilter;
        this.updateSelectedDate(this.selectedDateRange);
    };
    ModalContentComponent.prototype.updateSelectedDate = function (selectedDateRange) {
        this.consignmentSearch.selectedDateFilter = this.selectedDateFilter;
        this.consignmentSearch.selectedRangeLabel = this.selectedRangeLabel;
        this.consignmentSearch.selectedTimeRange = this.selectedTimeRange;
        this.consignmentSearch.maxCreatedDateFilter = null;
        this.consignmentSearch.minCreatedDateFilter = null;
        this.consignmentSearch.maxSlaDateFilter = null;
        this.consignmentSearch.minSlaDateFilter = null;
        this.consignmentSearch.maxOrderDateFilter = null;
        this.consignmentSearch.minOrderDateFilter = null;
        this.consignmentSearch.maxShipDateFilter = null;
        this.consignmentSearch.minShipDateFilter = null;
        this.consignmentSearch.maxPreferredShipDateFilter = null;
        this.consignmentSearch.minPreferredShipDateFilter = null;
        if (!!this.selectedDateRange) {
            switch (this.selectedDateFilter) {
                case 'CreatedDate': {
                    this.consignmentSearch.maxCreatedDateFilter = selectedDateRange[1];
                    this.consignmentSearch.minCreatedDateFilter = selectedDateRange[0];
                    break;
                }
                case 'SlaDate': {
                    this.consignmentSearch.maxSlaDateFilter = selectedDateRange[1];
                    this.consignmentSearch.minSlaDateFilter = selectedDateRange[0];
                    break;
                }
                case 'OrderDate': {
                    this.consignmentSearch.maxOrderDateFilter = selectedDateRange[1];
                    this.consignmentSearch.minOrderDateFilter = selectedDateRange[0];
                    break;
                }
                case 'ShipDate': {
                    this.consignmentSearch.maxShipDateFilter = selectedDateRange[1];
                    this.consignmentSearch.minShipDateFilter = selectedDateRange[0];
                    break;
                }
                case 'PreferredShipDate': {
                    this.consignmentSearch.maxPreferredShipDateFilter = selectedDateRange[1];
                    this.consignmentSearch.minPreferredShipDateFilter = selectedDateRange[0];
                    break;
                }
                default: {
                    break;
                }
            }
        }
    };
    return ModalContentComponent;
}(AppComponentBase));
export { ModalContentComponent };
var WidgetConsignmentStatsComponent = /** @class */ (function (_super) {
    __extends(WidgetConsignmentStatsComponent, _super);
    function WidgetConsignmentStatsComponent(injector, _consignmentsService, modalService) {
        var _this = _super.call(this, injector) || this;
        _this._consignmentsService = _consignmentsService;
        _this.modalService = modalService;
        _this.editMode = false;
        return _this;
    }
    WidgetConsignmentStatsComponent.prototype.redirectToConsignments = function () {
        if (!!this.widget.settings.Data) {
            var params = this.widget.settings.Data.params;
            this.router.navigate(['/app/main/shipments/consignments'], {
                queryParams: params,
            });
        }
    };
    WidgetConsignmentStatsComponent.prototype.ngOnChanges = function () {
        if (!!this.widget && !!this.widget.settings) {
            this.consignmentStats = new ConsignmentStats(this._consignmentsService, this.widget.settings.Data);
            this.logEvent('consignment-stats_view', this.widget.settings);
            this.consignmentStats.reload();
        }
        else {
            this.widget.settings = {};
            this.widget.settings.Title = 'Set Title';
            this.openModalWithComponent();
        }
    };
    WidgetConsignmentStatsComponent.prototype.openModalWithComponent = function () {
        var _this = this;
        if (!!this.widget.settings.Data) {
            var initialState = { searchParams: this.widget.settings.Data.params };
            this.bsModalRef = this.modalService.show(ModalContentComponent, { class: 'modal-xl', initialState: initialState });
        }
        else {
            this.bsModalRef = this.modalService.show(ModalContentComponent, Object.assign({}, { class: 'modal-xl' }));
        }
        this.bsModalRef.content.consignmentSearch.onSave.subscribe(function (value) {
            _this.logEvent('consignment-stats_change', value);
            _this.saveWidgetData(value);
            _this.bsModalRef.hide();
        });
    };
    return WidgetConsignmentStatsComponent;
}(WidgetComponentBase));
export { WidgetConsignmentStatsComponent };
