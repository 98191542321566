import { AccordionConfig } from 'ngx-bootstrap/accordion';


export class NgxBootstrapAccordionConfigService {

    static getAccordionConfig(): AccordionConfig {
        return Object.assign(new AccordionConfig(), {
        });
    }
}
