var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AppComponentBase } from '@shared/common/app-component-base';
import { OnDestroy, Injector, EventEmitter } from '@angular/core';
import { timer } from 'rxjs';
import { Page } from '@shared/service-proxies/service-proxies';
var WidgetComponentBase = /** @class */ (function (_super) {
    __extends(WidgetComponentBase, _super);
    function WidgetComponentBase(injector) {
        var _this = _super.call(this, injector) || this;
        _this.delay = 300;
        _this.editModeEnabled = false;
        _this.onSaveWidgetSettings = new EventEmitter();
        _this.subEditModeEnabled();
        return _this;
    }
    /**
     * Run methods delayed. If runDelay called multiple time before its delay, only run last called.
     * @param method Method to call
     */
    WidgetComponentBase.prototype.runDelayed = function (method) {
        if (this.timer && !this.timer.closed) {
            this.timer.unsubscribe();
        }
        this.timer = timer(this.delay).subscribe(function () {
            method();
        });
    };
    WidgetComponentBase.prototype.saveWidgetData = function (data) {
        if (!this.widget.settings) {
            this.widget.settings = {};
        }
        else {
            this.widget.settings.Data = data;
        }
        this.onSaveWidgetSettings.emit({
            settings: this.widget.settings,
            pageId: this.page.id,
            widgetId: this.widget.id
        });
    };
    WidgetComponentBase.prototype.saveWidgetTitle = function (title) {
        if (!this.widget.settings) {
            this.widget.settings = {};
            this.widget.settings.Title = 'Title';
        }
        else {
            this.widget.settings.Title = title;
        }
        this.onSaveWidgetSettings.emit({
            settings: this.widget.settings,
            pageId: this.page.id,
            widgetId: this.widget.id
        });
    };
    WidgetComponentBase.prototype.subEditModeEnabled = function () {
        var _this = this;
        abp.event.on('app.customizableDashboard.onEditModeEnabled', function (editModeEnabled) { _this.editModeEnabled = editModeEnabled; });
    };
    WidgetComponentBase.prototype.unSubEditModeEnabled = function () {
        var _this = this;
        abp.event.off('app.customizableDashboard.onEditModeEnabled', function (editModeEnabled) { _this.editModeEnabled = editModeEnabled; });
    };
    WidgetComponentBase.prototype.ngOnDestroy = function () {
        if (this.timer && !this.timer.closed) {
            this.timer.unsubscribe();
        }
        this.unSubEditModeEnabled();
    };
    return WidgetComponentBase;
}(AppComponentBase));
export { WidgetComponentBase };
