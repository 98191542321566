/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i3 from "ngx-spinner";
import * as i4 from "./root.component";
var styles_RootComponent = [];
var RenderType_RootComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RootComponent, data: {} });
export { RenderType_RootComponent as RenderType_RootComponent };
export function View_RootComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "ngx-spinner", [["color", "#fefefe"], ["size", "medium"], ["type", "eden-box"]], null, null, null, i2.View_NgxSpinnerComponent_0, i2.RenderType_NgxSpinnerComponent)), i0.ɵdid(3, 770048, null, 0, i3.NgxSpinnerComponent, [i3.NgxSpinnerService, i0.ChangeDetectorRef], { size: [0, "size"], color: [1, "color"], type: [2, "type"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "medium"; var currVal_1 = "#fefefe"; var currVal_2 = "eden-box"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_RootComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_RootComponent_0, RenderType_RootComponent)), i0.ɵdid(1, 49152, null, 0, i4.RootComponent, [], null, null)], null, null); }
var RootComponentNgFactory = i0.ɵccf("app-root", i4.RootComponent, View_RootComponent_Host_0, {}, {}, []);
export { RootComponentNgFactory as RootComponentNgFactory };
