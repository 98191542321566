import { Component, Injector, OnInit } from '@angular/core';
import { DashboardConsignmentService } from '@app/shared/common/dashboard-service/dashboard-consignment.service';
import { ConsignmentStatusDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { EdByStateSummaryTable } from '../../models/ed-by-state-summary-table';
import { IDashboardFilters } from '../../models/IDashboardFilters';
import { WidgetComponentBase } from '../widget-component-base';

@Component({
    selector: 'app-widget-ed-by-state',
    templateUrl: './widget-ed-by-state-summary.component.html',
    styleUrls: ['./widget-ed-by-state-summary.component.less']
})
export class WidgetEdByStateSummaryComponent extends WidgetComponentBase implements OnInit {
    edByStateSummaryTable: EdByStateSummaryTable;
    constructor(
        injector: Injector,
        private dataService: ConsignmentStatusDashboardServiceProxy,
        private dashboardConsignmentService: DashboardConsignmentService
    ) {
        super(injector);
        this.edByStateSummaryTable = new EdByStateSummaryTable(this.dataService);
    }

    ngOnInit() {
        this.edByStateSummaryTable.reload();
    }

    fieldClick(filter: IDashboardFilters) {
        this.dashboardConsignmentService.navigate(filter);
    }
}
