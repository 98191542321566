var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, EventEmitter, Injector } from '@angular/core';
import { GetAllConsignmentsInput, ExceptionType, HeatStatus, NameValueOfString } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { AppComponentBase } from '@shared/common/app-component-base';
import { convertToParamMap } from '@angular/router';
var ConsignmentSearchComponent = /** @class */ (function (_super) {
    __extends(ConsignmentSearchComponent, _super);
    function ConsignmentSearchComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.searchLabel = 'Search';
        _this.onSave = new EventEmitter();
        _this.onReset = new EventEmitter();
        _this.advancedFiltersAreShown = true;
        _this.filterText = '';
        _this.barcodeFilter = '';
        _this.consignmentNumberFilter = '';
        _this.externalVendorOrderNumbersFilter = '';
        _this.clientGroupFilter = [];
        _this.consignmentStatusFilter = [];
        _this.packageStatusFilter = [];
        _this.brandFilter = [];
        _this.customerNameFilter = '';
        _this.territoryFilter = [];
        _this.carrierCodeFilter = [];
        _this.typeFilter = [];
        _this.statusFilter = '';
        _this.exitHubFilter = [];
        _this.deliveryHubFilter = [];
        _this.deliveryWithFilter = [];
        _this.orderNumberFilter = '';
        _this.clientIdFilter = [];
        _this.clientFileNameFilter = [];
        _this.exceptionFilter = [];
        _this.priorityFilter = [];
        _this.heatStatusFilter = [];
        _this.regionTypeFilter = [];
        _this.regionValueFilter = [];
        _this.palletConnoteFilter = '';
        _this.palletisedFilter = [new NameValueOfString({ name: 'Non Palletised', value: 'false' })];
        _this.exceptionTypes = ExceptionType;
        _this.heatStatus = HeatStatus;
        return _this;
    }
    Object.defineProperty(ConsignmentSearchComponent.prototype, "searchParams", {
        set: function (value) {
            if (!!value) {
                var paramMaps = convertToParamMap(value);
                this.setFilterValuesFromParamMap(paramMaps);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConsignmentSearchComponent.prototype, "destinationPostcodeFilter", {
        get: function () {
            return this._destinationPostcodeFilter ? this._destinationPostcodeFilter.join(',') : null;
        },
        set: function (postcodes) {
            if (typeof postcodes === "number") {
                this._destinationPostcodeFilter = [postcodes];
            }
            else
                this._destinationPostcodeFilter = postcodes ? postcodes.split(',').map(function (pc) { return parseInt(pc); }) : null;
        },
        enumerable: true,
        configurable: true
    });
    ConsignmentSearchComponent.prototype.ngOnInit = function () {
    };
    ConsignmentSearchComponent.prototype.searchConsignments = function () {
        this.onSave.emit({
            params: this.getParamsFromAllFilters(),
            getAllConsignmentsInput: this.GetAllConsignmentsInput()
        });
    };
    ConsignmentSearchComponent.prototype.GetAllConsignmentsInput = function () {
        return new GetAllConsignmentsInput({
            consignmentNumbersFilter: !!this.consignmentNumberFilter ? this.consignmentNumberFilter.toString().split(',') : null,
            consignmentIdsFilter: !!this.consignmentIdFilter ? this.consignmentIdFilter : null,
            externalVendorOrderNumbersFilter: !!this.externalVendorOrderNumbersFilter ? this.externalVendorOrderNumbersFilter.toString().split(',') : null,
            barcodesFilter: !!this.barcodeFilter ? this.barcodeFilter.toString().split(',') : null,
            maxShipDateFilter: this.maxShipDateFilter,
            minShipDateFilter: this.minShipDateFilter,
            customerNameFilter: this.customerNameFilter,
            statusFilter: this.statusFilter,
            orderNumberFilter: !!this.orderNumberFilter ? this.orderNumberFilter.toString().split(',') : null,
            clientFileNameFilter: this.clientFileNameFilter && this.clientFileNameFilter.map(function (el) { return el.name; }) || null,
            maxOrderDateFilter: this.maxOrderDateFilter,
            minOrderDateFilter: this.minOrderDateFilter,
            maxPreferredShipDateFilter: this.maxPreferredShipDateFilter,
            minPreferredShipDateFilter: this.minPreferredShipDateFilter,
            maxCreatedDateFilter: this.maxCreatedDateFilter,
            minCreatedDateFilter: this.minCreatedDateFilter,
            maxSlaDateFilter: this.maxSlaDateFilter,
            minSlaDateFilter: this.minSlaDateFilter,
            minRescheduledCountFilter: this.minRescheduledCountFilter,
            maxRescheduledCountFilter: this.maxRescheduledCountFilter,
            carrierCodeFilter: this.carrierCodeFilter && this.carrierCodeFilter.map(function (el) { return el.name.toString(); }) || null,
            consignmentStatusFilter: this.consignmentStatusFilter && this.consignmentStatusFilter.map(function (el) { return el.value + ''; }) || null,
            typeFilter: this.typeFilter && this.typeFilter.map(function (el) { return el.value; }) || null,
            brandFilter: this.brandFilter && this.brandFilter.map(function (el) { return el.value; }) || null,
            exitHubFilter: this.exitHubFilter && this.exitHubFilter.map(function (el) { return el.value; }) || null,
            deliveryHubFilter: this.deliveryHubFilter && this.deliveryHubFilter.map(function (el) { return el.value; }) || null,
            deliveryWithFilter: this.deliveryWithFilter && this.deliveryWithFilter.map(function (el) { return el.value; }) || null,
            clientIdFilter: this.clientIdFilter && this.clientIdFilter.map(function (el) { return el.value; }) || null,
            clientGroupFilter: this.clientGroupFilter && this.clientGroupFilter.map(function (el) { return el.value; }) || null,
            packageStatusFilter: this.packageStatusFilter && this.packageStatusFilter.map(function (el) { return el.name; }) || null,
            territoryFilter: this.territoryFilter && this.territoryFilter.map(function (el) { return el.name; }) || null,
            loadIdFilter: this.fieldName === 'loadIdFilter' ? this.getIdList(this.fieldValue) : null,
            palletIdFilter: this.fieldName === 'palletIdFilter' ? this.getIdList(this.fieldValue) : null,
            palletConnoteFilter: this.fieldName === 'palletConnoteFilter' ? this.getIdStringList(this.fieldValue) : null,
            isPalletised: this.getIsPalletisedValue(this.palletisedFilter),
            actionedFilter: this.actionedFilter,
            removedFromDashboardFilter: this.removedFromDashboardFilter,
            batchIdFilter: this.fieldName === 'batchIdFilter' ? this.getIdList(this.fieldValue) : null,
            dashboardViewFilter: this.dashboardViewFilter,
            xBorderIdFilter: this.fieldName === 'xBorderIdFilter' ? this.getIdList(this.fieldValue) : null,
            returnToSupplierIdFilter: this.fieldName === 'returnToSupplierIdFilter' ? this.getIdList(this.fieldValue) : null,
            xFilter: !!this.xFilter ? this.xFilter : null,
            yFilter: !!this.yFilter ? this.yFilter : null,
            zFilter: !!this.zFilter ? this.zFilter : null,
            priorityFilter: this.priorityFilter && this.priorityFilter.map(function (el) { return el.value; }) || null,
            heatStatusFilter: this.heatStatusFilter && this.heatStatusFilter.map(function (el) { return el.value; }) || null,
            regionTypeFilter: this.regionTypeFilter && this.regionTypeFilter.map(function (el) { return el.value; }) || null,
            regionValueFilter: this.regionValueFilter && this.regionValueFilter.map(function (el) { return el.value; }) || null,
            zendeskQueryType: this.exceptionFilter && this.exceptionFilter.map(function (el) { return el.value; }) || null,
            destinationPostcodeFilter: this._destinationPostcodeFilter || null,
            selectedDateFilter: this.selectedDateFilter,
            selectedRangeLabel: this.selectedRangeLabel,
            selectedTimeRange: this.selectedTimeRange,
            sorting: null,
            skipCount: 0,
            maxResultCount: 100
        });
    };
    ConsignmentSearchComponent.prototype.getIsPalletisedValue = function (palletisedFilter) {
        if (!!palletisedFilter && palletisedFilter.length === 1) {
            return palletisedFilter.filter(function (el) { return el.value.toLowerCase() === 'true'; }).length > 0 ? true : false;
        }
        else
            return null; // length > 1  >> both selected >> means no filter needed
    };
    ConsignmentSearchComponent.prototype.setFilterValuesFromQueryString = function () {
        this.setFilterValuesFromParamMap(this.params);
    };
    ConsignmentSearchComponent.prototype.setFilterValuesFromParamMap = function (params) {
        this.exceptionFilter = params.get('exceptionFilter') ? this.getFilterValue(params.get('exceptionFilter')) : [];
        this.fieldName = this.getFilterValue(params.get('fieldName'));
        this.actionedFilter = this.getFilterValue(params.get('actionedFilter'));
        this.dashboardViewFilter = this.getFilterValue(params.get('dashboardViewFilter'));
        this.fieldValue = this.getFilterValue(params.get('fieldValue'));
        this.removedFromDashboardFilter = this.getFilterValue(params.get('removedFromDashboardFilter'));
        this.consignmentNumberFilter = this.getFilterValue(params.get('consignmentNumberFilter'));
        this.externalVendorOrderNumbersFilter = this.getFilterValue(params.get('externalVendorOrderNumbersFilter'));
        this.barcodeFilter = this.getFilterValue(params.get('barcodeFilter'));
        this.brandFilter = params.get('brandFilter') ? this.getFilterValue(params.get('brandFilter')) : [];
        this.customerNameFilter = this.getFilterValue(params.get('customerNameFilter'));
        this.carrierCodeFilter = params.get('carrierCodeFilter') ? this.getFilterValue(params.get('carrierCodeFilter')) : [];
        this.consignmentStatusFilter = params.get('consignmentStatusFilter') ? this.getFilterValue(params.get('consignmentStatusFilter')) : [];
        this.typeFilter = params.get('typeFilter') ? this.getFilterValue(params.get('typeFilter')) : [];
        this.statusFilter = this.getFilterValue(params.get('statusFilter'));
        this.exitHubFilter = params.get('exitHubFilter') ? this.getFilterValue(params.get('exitHubFilter')) : [];
        this.deliveryHubFilter = params.get('deliveryHubFilter') ? this.getFilterValue(params.get('deliveryHubFilter')) : [];
        this.deliveryWithFilter = params.get('deliveryWithFilter') ? this.getFilterValue(params.get('deliveryWithFilter')) : [];
        this.maxLocationIdFilter = this.getFilterValue(params.get('maxLocationIdFilter'));
        this.minLocationIdFilter = this.getFilterValue(params.get('minLocationIdFilter'));
        this.orderNumberFilter = this.getFilterValue(params.get('orderNumberFilter'));
        this.clientIdFilter = params.get('clientIdFilter') ? this.getFilterValue(params.get('clientIdFilter')) : [];
        this.priorityFilter = params.get('priorityFilter') ? this.getFilterValue(params.get('priorityFilter')) : [];
        this.heatStatusFilter = params.get('heatStatusFilter') ? this.getFilterValue(this.params.get('heatStatusFilter')) : [];
        this.regionTypeFilter = params.get('regionTypeFilter') ? this.getFilterValue(params.get('regionTypeFilter')) : [];
        this.regionValueFilter = params.get('regionValueFilter') ? this.getFilterValue(params.get('regionValueFilter')) : [];
        this.clientFileNameFilter = params.get('clientFileNameFilter') ? this.getFilterValue(params.get('clientFileNameFilter')) : [];
        this.clientGroupFilter = params.get('clientGroupFilter') ? this.getFilterValue(params.get('clientGroupFilter')) : [];
        this.palletisedFilter = params.get('palletisedFilter') === 'true' ? [new NameValueOfString({ name: 'Non Palletised', value: 'true' })] : [new NameValueOfString({ name: 'Non Palletised', value: 'false' })];
        this.packageStatusFilter = this.getFilterValue(params.get('packageStatusFilter'));
        this.territoryFilter = params.get('territoryFilter') ? this.getFilterValue(params.get('territoryFilter')) : [];
        this.courierFilter = this.getFilterValue(params.get('courierFilter'));
        this.xFilter = this.getFilterValue(params.get('xFilter'));
        this.yFilter = this.getFilterValue(params.get('yFilter'));
        this.zFilter = this.getFilterValue(params.get('zFilter'));
        this.maxOrderDateFilter = this.getFilterValue(params.get('maxOrderDateFilter')) ? moment(this.getFilterValue(params.get('maxOrderDateFilter'))) : null;
        this.minOrderDateFilter = this.getFilterValue(params.get('minOrderDateFilter')) ? moment(this.getFilterValue(params.get('minOrderDateFilter'))) : null;
        this.maxShipDateFilter = this.getFilterValue(params.get('maxShipDateFilter')) ? moment(this.getFilterValue(params.get('maxShipDateFilter'))) : null;
        this.minShipDateFilter = this.getFilterValue(params.get('minShipDateFilter')) ? moment(this.getFilterValue(params.get('minShipDateFilter'))) : null;
        this.maxPreferredShipDateFilter = this.getFilterValue(params.get('maxPreferredShipDateFilter')) ? moment(this.getFilterValue(params.get('maxPreferredShipDateFilter'))) : null;
        this.minPreferredShipDateFilter = this.getFilterValue(params.get('minPreferredShipDateFilter')) ? moment(this.getFilterValue(params.get('maxShiminPreferredShipDateFilterpDateFilter'))) : null;
        this.maxCreatedDateFilter = this.getFilterValue(params.get('maxCreatedDateFilter')) ? moment(this.getFilterValue(params.get('maxCreatedDateFilter'))) : null;
        this.minCreatedDateFilter = this.getFilterValue(params.get('minCreatedDateFilter')) ? moment(this.getFilterValue(params.get('minCreatedDateFilter'))) : null;
        this.maxSlaDateFilter = this.getFilterValue(params.get('maxSlaDateFilter')) ? moment(this.getFilterValue(params.get('maxSlaDateFilter'))) : null;
        this.minSlaDateFilter = this.getFilterValue(params.get('minSlaDateFilter')) ? moment(this.getFilterValue(params.get('minSlaDateFilter'))) : null;
        this.minRescheduledCountFilter = this.getFilterValue(params.get('minRescheduledCountFilter'));
        this.maxRescheduledCountFilter = this.getFilterValue(params.get('maxRescheduledCountFilter'));
        this.destinationPostcodeFilter = this.getFilterValue(params.get('destinationPostcodeFilter'));
        this.selectedDateFilter = this.getFilterValue(params.get('selectedDateFilter'));
        this.selectedRangeLabel = this.getFilterValue(params.get('selectedRangeLabel'));
        this.selectedTimeRange = this.getFilterValue(params.get('selectedTimeRange'));
    };
    ConsignmentSearchComponent.prototype.resetFilters = function () {
        this.exceptionFilter = [];
        this.fieldName = undefined;
        this.actionedFilter = undefined;
        this.dashboardViewFilter = undefined;
        this.fieldValue = undefined;
        this.removedFromDashboardFilter = undefined;
        this.consignmentNumberFilter = undefined;
        this.externalVendorOrderNumbersFilter = undefined;
        this.barcodeFilter = undefined;
        this.maxShipDateFilter = undefined;
        this.minShipDateFilter = undefined;
        this.brandFilter = [];
        this.customerNameFilter = undefined;
        this.carrierCodeFilter = [];
        this.consignmentStatusFilter = [];
        this.typeFilter = [];
        this.statusFilter = undefined;
        this.exitHubFilter = [];
        this.deliveryHubFilter = [];
        this.deliveryWithFilter = [];
        this.maxLocationIdFilter = undefined;
        this.minLocationIdFilter = undefined;
        this.orderNumberFilter = undefined;
        this.clientIdFilter = [];
        this.priorityFilter = [];
        this.heatStatusFilter = [];
        this.regionTypeFilter = [];
        this.regionValueFilter = [];
        this.clientFileNameFilter = [];
        this.maxOrderDateFilter = undefined;
        this.minOrderDateFilter = undefined;
        this.clientGroupFilter = [];
        this.packageStatusFilter = undefined;
        this.territoryFilter = [];
        this.courierFilter = undefined;
        this.xFilter = undefined;
        this.yFilter = undefined;
        this.zFilter = undefined;
        this.maxPreferredShipDateFilter = undefined;
        this.minPreferredShipDateFilter = undefined;
        this.maxCreatedDateFilter = undefined;
        this.minCreatedDateFilter = undefined;
        this.maxSlaDateFilter = undefined;
        this.minSlaDateFilter = undefined;
        this.minRescheduledCountFilter = undefined;
        this.maxRescheduledCountFilter = undefined;
        this.destinationPostcodeFilter = undefined;
        this.selectedRangeLabel = undefined;
        this.selectedTimeRange = undefined;
        this.selectedDateFilter = undefined;
        this.palletisedFilter = [];
        this.palletConnoteFilter = undefined;
        this.onReset.emit();
        this.searchConsignments();
    };
    ConsignmentSearchComponent.prototype.getParamsFromAllFilters = function () {
        return this.getParamsFromFilters([{ name: 'exceptionFilter', value: this.exceptionFilter },
            { name: 'fieldValue', value: this.fieldValue },
            { name: 'fieldName', value: this.fieldName },
            { name: 'actionedFilter', value: this.actionedFilter ? true : undefined },
            { name: 'removedFromDashboardFilter', value: this.removedFromDashboardFilter ? true : undefined },
            { name: 'barcodeFilter', value: this.barcodeFilter },
            { name: 'maxShipDateFilter', value: !!this.selectedRangeLabel ? null : this.maxShipDateFilter },
            { name: 'minShipDateFilter', value: !!this.selectedRangeLabel ? null : this.minShipDateFilter },
            { name: 'brandFilter', value: this.brandFilter },
            { name: 'customerNameFilter', value: this.customerNameFilter },
            { name: 'carrierCodeFilter', value: this.carrierCodeFilter },
            { name: 'consignmentStatusFilter', value: this.consignmentStatusFilter },
            { name: 'consignmentNumberFilter', value: this.consignmentNumberFilter },
            { name: 'externalVendorOrderNumbersFilter', value: this.externalVendorOrderNumbersFilter },
            { name: 'typeFilter', value: this.typeFilter },
            { name: 'statusFilter', value: this.statusFilter },
            { name: 'exitHubFilter', value: this.exitHubFilter },
            { name: 'deliveryHubFilter', value: this.deliveryHubFilter },
            { name: 'deliveryWithFilter', value: this.deliveryWithFilter },
            { name: 'maxLocationIdFilter', value: this.maxLocationIdFilter },
            { name: 'minLocationIdFilter', value: this.minLocationIdFilter },
            { name: 'orderNumberFilter', value: this.orderNumberFilter },
            { name: 'clientIdFilter', value: this.clientIdFilter },
            { name: 'priorityFilter', value: this.priorityFilter },
            { name: 'heatStatusFilter', value: this.heatStatusFilter },
            { name: 'regionTypeFilter', value: this.regionTypeFilter },
            { name: 'regionValueFilter', value: this.regionValueFilter },
            { name: 'clientFileNameFilter', value: this.clientFileNameFilter },
            { name: 'maxOrderDateFilter', value: !!this.selectedRangeLabel ? null : this.maxOrderDateFilter },
            { name: 'minOrderDateFilter', value: !!this.selectedRangeLabel ? null : this.minOrderDateFilter },
            { name: 'clientGroupFilter', value: this.clientGroupFilter },
            { name: 'palletisedFilter', value: this.palletisedFilter },
            { name: 'packageStatusFilter', value: this.packageStatusFilter },
            { name: 'territoryFilter', value: this.territoryFilter },
            { name: 'courierFilter', value: this.courierFilter },
            { name: 'xFilter', value: this.xFilter },
            { name: 'yFilter', value: this.yFilter },
            { name: 'zFilter', value: this.zFilter },
            { name: 'dashboardViewFilter', value: this.dashboardViewFilter ? true : undefined },
            { name: 'maxPreferredShipDateFilter', value: !!this.selectedRangeLabel ? null : this.maxPreferredShipDateFilter },
            { name: 'minPreferredShipDateFilter', value: !!this.selectedRangeLabel ? null : this.minPreferredShipDateFilter },
            { name: 'maxCreatedDateFilter', value: !!this.selectedRangeLabel ? null : this.maxCreatedDateFilter },
            { name: 'minCreatedDateFilter', value: !!this.selectedRangeLabel ? null : this.minCreatedDateFilter },
            { name: 'maxSlaDateFilter', value: !!this.selectedRangeLabel ? null : this.maxSlaDateFilter },
            { name: 'minSlaDateFilter', value: !!this.selectedRangeLabel ? null : this.minSlaDateFilter },
            { name: 'minRescheduledCountFilter', value: this.minRescheduledCountFilter },
            { name: 'maxRescheduledCountFilter', value: this.maxRescheduledCountFilter },
            { name: 'destinationPostcodeFilter', value: this.destinationPostcodeFilter },
            { name: 'selectedDateFilter', value: this.selectedDateFilter },
            { name: 'selectedRangeLabel', value: this.selectedRangeLabel },
            { name: 'selectedTimeRange', value: this.selectedTimeRange }
        ]);
    };
    ConsignmentSearchComponent.prototype.setFieldFilters = function (fieldValue) {
        this.fieldValue = fieldValue;
    };
    ConsignmentSearchComponent.prototype.getIdList = function (idString) {
        if (!!idString) {
            return idString.toString().split(',').map(function (e) { return +e; });
        }
        else {
            return null;
        }
    };
    ConsignmentSearchComponent.prototype.getIdStringList = function (idString) {
        if (!!idString) {
            return idString.toString().split(',').map(function (e) { return e; });
        }
        else {
            return null;
        }
    };
    ConsignmentSearchComponent.prototype.onConsignmentsChange = function (consignmentIds) {
        this.consignmentNumberFilter = consignmentIds;
    };
    ConsignmentSearchComponent.prototype.onExternalVendorOrderNumbersFilterChange = function (externalVendorOrderNumbers) {
        this.externalVendorOrderNumbersFilter = externalVendorOrderNumbers;
    };
    ConsignmentSearchComponent.prototype.onBarcodesChange = function (barcodes) {
        this.barcodeFilter = barcodes;
    };
    ConsignmentSearchComponent.prototype.onOrderNumbersChange = function (orderNumbers) {
        this.orderNumberFilter = orderNumbers;
    };
    return ConsignmentSearchComponent;
}(AppComponentBase));
export { ConsignmentSearchComponent };
