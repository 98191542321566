var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { SubscriptionStartType } from '@shared/service-proxies/service-proxies';
import { ChatSignalrService } from 'app/shared/layout/chat/chat-signalr.service';
import * as moment from 'moment';
import { AppComponentBase } from 'shared/common/app-component-base';
import { SignalRHelper } from 'shared/helpers/SignalRHelper';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { NotificationSettingsModalComponent } from '@app/shared/layout/notifications/notification-settings-modal.component';
import { UserNotificationHelper } from '@app/shared/layout/notifications/UserNotificationHelper';
import { ChangeDepotModalComponent } from './shared/layout/profile/change-depot-modal.component';
import { VersionCheckService } from './shared/services/version-check-service';
var AppComponent = /** @class */ (function (_super) {
    __extends(AppComponent, _super);
    function AppComponent(injector, _chatSignalrService, _userNotificationHelper, _versionCheckService) {
        var _this = _super.call(this, injector) || this;
        _this._chatSignalrService = _chatSignalrService;
        _this._userNotificationHelper = _userNotificationHelper;
        _this._versionCheckService = _versionCheckService;
        _this.subscriptionStartType = SubscriptionStartType;
        _this.installationMode = true;
        _this.isQuickThemeSelectEnabled = _this.setting.getBoolean('App.UserManagement.IsQuickThemeSelectEnabled');
        _this.IsSessionTimeOutEnabled = _this.setting.getBoolean('App.UserManagement.SessionTimeOut.IsEnabled') && _this.appSession.userId != null;
        return _this;
    }
    AppComponent.prototype.ngOnInit = function () {
        this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        this.theme = abp.setting.get('App.UiManagement.Theme').toLocaleLowerCase();
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.registerModalOpenEvents();
        this._versionCheckService.initVersionCheck(AppConsts.appBaseUrl + '/version.json');
        if (this.appSession.application) {
            SignalRHelper.initSignalR(function () { abp.signalr.connect(); });
        }
    };
    AppComponent.prototype.subscriptionStatusBarVisible = function () {
        return this.appSession.tenantId > 0 &&
            (this.appSession.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon());
    };
    AppComponent.prototype.subscriptionIsExpiringSoon = function () {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this.appSession.tenant.subscriptionEndDateUtc);
        }
        return false;
    };
    AppComponent.prototype.registerModalOpenEvents = function () {
        var _this = this;
        abp.event.on('app.show.loginAttemptsModal', function () {
            _this.loginAttemptsModal.show();
        });
        abp.event.on('app.show.linkedAccountsModal', function () {
            _this.linkedAccountsModal.show();
        });
        abp.event.on('app.show.changePasswordModal', function () {
            _this.changePasswordModal.show();
        });
        abp.event.on('app.show.changeDepotModal', function () {
            _this.changeDepotModal.show();
        });
        abp.event.on('app.show.changeProfilePictureModal', function () {
            _this.changeProfilePictureModal.show();
        });
        abp.event.on('app.show.mySettingsModal', function () {
            _this.mySettingsModal.show();
        });
    };
    AppComponent.prototype.getRecentlyLinkedUsers = function () {
        abp.event.trigger('app.getRecentlyLinkedUsers');
    };
    AppComponent.prototype.onMySettingsModalSaved = function () {
        abp.event.trigger('app.onMySettingsModalSaved');
    };
    return AppComponent;
}(AppComponentBase));
export { AppComponent };
