var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, Injector } from '@angular/core';
import { DashboardChartBase } from '../dashboard-chart-base';
import { TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { WidgetComponentBase } from '../widget-component-base';
var GeneralStatsPieChart = /** @class */ (function (_super) {
    __extends(GeneralStatsPieChart, _super);
    function GeneralStatsPieChart(_dashboardService) {
        var _this = _super.call(this) || this;
        _this._dashboardService = _dashboardService;
        _this.data = [];
        return _this;
    }
    GeneralStatsPieChart.prototype.init = function (transactionPercent, newVisitPercent, bouncePercent) {
        this.data = [
            {
                'name': 'Operations',
                'value': transactionPercent
            }, {
                'name': 'New Visits',
                'value': newVisitPercent
            }, {
                'name': 'Bounce',
                'value': bouncePercent
            }
        ];
        this.hideLoading();
    };
    GeneralStatsPieChart.prototype.reload = function () {
        var _this = this;
        this.showLoading();
        this._dashboardService
            .getGeneralStats()
            .subscribe(function (result) {
            _this.init(result.transactionPercent, result.newVisitPercent, result.bouncePercent);
        });
    };
    return GeneralStatsPieChart;
}(DashboardChartBase));
var WidgetGeneralStatsComponent = /** @class */ (function (_super) {
    __extends(WidgetGeneralStatsComponent, _super);
    function WidgetGeneralStatsComponent(injector, _dashboardService) {
        var _this = _super.call(this, injector) || this;
        _this._dashboardService = _dashboardService;
        _this.generalStatsPieChart = new GeneralStatsPieChart(_this._dashboardService);
        return _this;
    }
    WidgetGeneralStatsComponent.prototype.ngOnInit = function () {
        this.generalStatsPieChart.reload();
    };
    return WidgetGeneralStatsComponent;
}(WidgetComponentBase));
export { WidgetGeneralStatsComponent };
