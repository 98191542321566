import { PriorityTypesAutoCompleteComponent } from './../shipments/consignments/prioritytypes-auto-complete';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BrandAutoCompleteComponent } from '../appConfiguration/commonLookup/brand-auto-complete.component';
import { FileRecordStatusAutoCompleteComponent } from '../appConfiguration/commonLookup/file-record-status-auto-complete';
import { ConsignmentStatusAutoCompleteComponent } from '../appConfiguration/commonLookup/consignmentStatus-auto-complete.component';
import { ClientGroupAutoCompleteComponent } from '../appConfiguration/clientGroups/clientGroup-auto-complete.component';
import { EventGroupAutoCompleteComponent } from '../appConfiguration/commonLookup/eventGroup-auto-complete.component';
import { EventGroupExceptionTypeAutoCompleteComponent } from '../appConfiguration/commonLookup/event-group-exception-type-auto-complete';
import { TerritoryConfigurationAutoCompleteComponent } from '../appConfiguration/commonLookup/territory-configuration-auto-complete.component';
import { CarrierAutoCompleteComponent } from '../appConfiguration/commonLookup/carrier-auto-complete.component';
import { DeliveryAutoCompleteComponent } from '../appConfiguration/commonLookup/deliveryTypes-auto-complete';
import { EventDetailAutoCompleteComponent } from '../appConfiguration/commonLookup/eventDetail-auto-complete.component';
import { ClientAutoCompleteComponent } from '../appConfiguration/clients/client-auto-complete.component';
import { ManifestFileAutoCompleteComponent } from '../manifests/manifestFiles/manifestFiles-auto-complete.component';
import { DepotLocationAutoCompleteComponent } from '../appConfiguration/commonLookup/depotlocation-auto-complete.component';
import { ClientCodeAutoCompleteComponent } from '../shipments/consignments/clientcode-auto-complete.component';
import { DepotToggleComponent } from '../operations/shared/depotToggle/depotToggle.component';
import { CarrierCodeAutoCompleteComponent } from '../appConfiguration/commonLookup/carrier-code-auto-complete.component';
import { EventDetailZendeskAutoCompleteComponent } from '../appConfiguration/commonLookup/event-detail-zendesk-auto-complete';
import { ClientGroupTypeAutoCompleteComponent } from '../appConfiguration/commonLookup/client-group-type-auto-complete.component';
import { RemoveTransactionAutoCompleteComponent } from '../appConfiguration/commonLookup/removeTransaction-auto-complete.component';
import { ClientsMultiSelect } from '../appConfiguration/clients/client-multiselect.component';
import { ClientGroupsMultiSelectComponent } from '../appConfiguration/clientGroups/clientGroup-multiselect.component';
import { ClientCodesMultiSelectComponent } from '../shipments/consignments/clientCode-multiselect.component';
import { DeliveryTypesMultiSelectComponent } from '../appConfiguration/commonLookup/deliveryTypes-multiselect.component';
import { ConsignmentStatusMultiSelectComponent } from '../appConfiguration/commonLookup/consignmentStatus-multiselect.component';
import { RemoveTransactionMultiSelectComponent } from '../appConfiguration/commonLookup/removeTransaction-multiselect.component';
import { EventGroupExceptionTypeMultiselectComponent } from '../appConfiguration/commonLookup/event-group-exception-type-multiselect.component';
import { EventDetailZendeskMultiSelectComponent } from '../appConfiguration/commonLookup/event-detail-zendesk-multiselect.component';
import { TerritoryConfigurationMultiSelectComponent } from '../appConfiguration/commonLookup/territory-configuration-multiselect.component';
import { DepotLocationMultiSelectComponent } from '../appConfiguration/commonLookup/depotlocation-multiselect.component';
import { CarrierMultiSelectComponent } from '../appConfiguration/commonLookup/carrier-multiselect.component';
import { CarrierCodeMultiSelectComponent } from '../appConfiguration/commonLookup/carrierCode-multiselect.component';
import { BrandMultiSelectComponent } from '../appConfiguration/commonLookup/brand-multiselect.component';
import { PriorityTypesMultiSelectComponent } from '../shipments/consignments/priorityTypes-multiselect.component';
import { MultiSelectModule } from 'primeng/primeng';
import { CourierMultiSelectComponent } from '../appConfiguration/commonLookup/courier-multiselect.component';
import { RegionTypesMultiSelectComponent } from '../shipments/consignments/regionTypes-multiselect.component';
import { RegionValuesMultiSelectComponent } from '../shipments/consignments/regionValues-multiselect.component';
import { HeatStatusMultiSelectComponent } from '../shipments/orders/heatStatus-multiselect.component';
import { StoreAutoCompleteComponent } from '../appConfiguration/commonLookup/store-auto-complete.component';
import { DeliveryWithMultiselectComponent } from '../appConfiguration/commonLookup/delivery-with-multiselect.component';
import { PalletisedFilterMultiSelectComponent } from '../appConfiguration/commonLookup/palletised-multiselect.component';


@NgModule({
    declarations: [
        ManifestFileAutoCompleteComponent,
        DepotLocationAutoCompleteComponent,
        DepotLocationMultiSelectComponent,
        DeliveryWithMultiselectComponent,
        ClientAutoCompleteComponent,
        ClientsMultiSelect,
        FileRecordStatusAutoCompleteComponent,
        BrandAutoCompleteComponent,
        BrandMultiSelectComponent,
        PriorityTypesAutoCompleteComponent,
        PriorityTypesMultiSelectComponent,
        RegionTypesMultiSelectComponent,
        RegionValuesMultiSelectComponent,
        ConsignmentStatusAutoCompleteComponent,
        ConsignmentStatusMultiSelectComponent,
        ClientGroupAutoCompleteComponent,
        ClientGroupsMultiSelectComponent,
        EventGroupAutoCompleteComponent,
        EventGroupExceptionTypeAutoCompleteComponent,
        EventGroupExceptionTypeMultiselectComponent,
        TerritoryConfigurationAutoCompleteComponent,
        TerritoryConfigurationMultiSelectComponent,
        CarrierAutoCompleteComponent,
        CarrierMultiSelectComponent,
        CarrierCodeAutoCompleteComponent,
        CarrierCodeMultiSelectComponent,
        CourierMultiSelectComponent,
        CarrierMultiSelectComponent,
        ClientCodeAutoCompleteComponent,
        ClientCodesMultiSelectComponent,
        DeliveryAutoCompleteComponent,
        DeliveryTypesMultiSelectComponent,
        ClientGroupTypeAutoCompleteComponent,
        EventDetailAutoCompleteComponent,
        EventDetailZendeskAutoCompleteComponent,
        EventDetailZendeskMultiSelectComponent,
        RemoveTransactionAutoCompleteComponent,
        RemoveTransactionMultiSelectComponent,
        DepotToggleComponent,
        HeatStatusMultiSelectComponent,
        StoreAutoCompleteComponent,
        PalletisedFilterMultiSelectComponent
    ],
    imports: [CommonModule, FormsModule, AutoCompleteModule, MultiSelectModule],
    exports: [
        ManifestFileAutoCompleteComponent,
        DepotLocationAutoCompleteComponent,
        DepotLocationMultiSelectComponent,
        DeliveryWithMultiselectComponent,
        PriorityTypesAutoCompleteComponent,
        PriorityTypesMultiSelectComponent,
        RegionTypesMultiSelectComponent,
        RegionValuesMultiSelectComponent,
        ClientAutoCompleteComponent,
        ClientsMultiSelect,
        BrandAutoCompleteComponent,
        BrandMultiSelectComponent,
        FileRecordStatusAutoCompleteComponent,
        ConsignmentStatusAutoCompleteComponent,
        ConsignmentStatusMultiSelectComponent,
        ClientGroupAutoCompleteComponent,
        ClientGroupsMultiSelectComponent,
        EventGroupAutoCompleteComponent,
        EventGroupExceptionTypeAutoCompleteComponent,
        EventGroupExceptionTypeMultiselectComponent,
        TerritoryConfigurationAutoCompleteComponent,
        TerritoryConfigurationMultiSelectComponent,
        CarrierAutoCompleteComponent,
        CarrierMultiSelectComponent,
        CarrierCodeAutoCompleteComponent,
        CarrierCodeMultiSelectComponent,
        CourierMultiSelectComponent,
        ClientCodeAutoCompleteComponent,
        ClientCodesMultiSelectComponent,
        DeliveryAutoCompleteComponent,
        DeliveryTypesMultiSelectComponent,
        ClientGroupTypeAutoCompleteComponent,
        EventDetailAutoCompleteComponent,
        EventDetailZendeskAutoCompleteComponent,
        EventDetailZendeskMultiSelectComponent,
        RemoveTransactionAutoCompleteComponent,
        RemoveTransactionMultiSelectComponent,
        DepotToggleComponent,
        HeatStatusMultiSelectComponent,
        StoreAutoCompleteComponent,
        PalletisedFilterMultiSelectComponent
    ]
})
export class MainSharedModule {}
